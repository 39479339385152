<script setup>
import UIkit from 'uikit';
</script>

<script>
export default {
  data() {
    return {
      alreadyConfirmed: false,
      learnMoreToggle: false
    };
  },
  mounted() {
    this.lookForCookie();
  },
  methods: {
    getCookie(cookieName) {
      let name = cookieName + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    lookForCookie() {
      let cookieName = this.getCookie("lowenCookieConsent");
      if (cookieName !== "") {
        this.alreadyConfirmed = true;
      }
    },
    setCookie() {
      var expires = "";
      var date = new Date();
      date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
      document.cookie = "lowenCookieConsent=" + (1 || "") + expires + "; path=/" + ";SameSite=Strict";
    },
    confirmCookie() {
      this.setCookie();
      this.alreadyConfirmed = true;
    }
  },
}
</script>

<template id="CookieTemplate">
  <div v-if="!alreadyConfirmed" class="uk-padding-remove">
    <div class="uk-animation-fade uk-width-1-1 uk-width-1-2@s uk-width-1-3@m uk-padding-remove">
    <div class="uk-card uk-card-secondary uk-card-body" id="cookieConsentContainer">
      <div class="uk-card-title">
        Cookies In Use
      </div>
      <div class="uk-text-default">
        <p>We use cookies to enhance your user experience and to analyze performance and traffic on our website. By
          using our website, you agree to our use of cookies in accordance with our cookie policy. <a
              v-on:click="learnMoreToggle = true" target="_blank">Learn More</a></p>
      </div>
      <div class="uk-button uk-button-default epd-button">
        <a class="uk-text-large" v-on:click="confirmCookie()">Accept</a>
      </div>
      <div v-if="learnMoreToggle" class="uk-margin-top">
        <h3 class="uk-h3">Privacy Policy</h3>
        <div class="uk-text-default">We will never sell, loan, rent or distribute any personal information to third
          parties under any circumstance, including the contact information of those who subscribe to our mailing list.
          We may notify you by email about special offers, events and products we think you would want to hear about.
        </div>
      </div>
    </div>
  </div>
  </div>
</template>