﻿<script setup>
import {authHeader} from '../../helpers/authheader';
import TreeItem from "./TreeItem.vue";
import UIkit from 'uikit';
import {useAuthStore} from "../../stores/auth.store";
import Loading from "./Loading.vue";

// import MASV from "https://massive.app/lib/masv-uploader-beta.min.js"
</script>

<script>
export default {
  components: {
    "tree-item": TreeItem,
  },
  props: {
    selectedfolder: ""
  },
  inject: ["closeAndRefresh"],
  data() {
    return {
      showMessage: false,
      uploadMessage: "",
      showProgress: false,
      progressMessage: "",
      isComplete: false,
      isError: false,
      isLoading: false,
      folderList: {},
      newFolderName: "",
      Images: [],
      MASVToken: {},
      MASVUploader: {},
      baseUrl: process.env.APIURL + "/Library"
    };
  },
  mounted() {
    // log("UploadFileComponent is mounted");
    let _this = this;
    UIkit.util.on("#UploadFileModal", "beforeshow", function () {
      _this.isComplete = false;
      _this.progressMessage = "";
      _this.showProgress = false;
      _this.showMessage = false;
    });

    UIkit.util.on("#UploadFileModal", "hidden", function () {
      log("running hidden.......");
      _this.closeAndRefresh('UploadFileModal');
    });
  },
  beforeUnmount() {
    // this.resetComponent();
  },
  methods: {
    resetComponent() {
      console.log("Resetting upload modal...")
      this.showMessage = false,
          this.uploadMessage = "",
          this.showProgress = false,
          this.progressMessage = "",
          this.isComplete = false,
          this.isError = false,
          this.isLoading = false,
          this.folderList = {},
          this.newFolderName = "",
          this.Images = [],
          this.MASVToken = {},
          this.MASVUploader = {},
          this.baseUrl = process.env.APIURL + "/Library"
    },
    async getToken() {
      log("getToken");
      const { user } = useAuthStore();
      const userId = user.Id;
      const url = this.baseUrl + "/new_token";
      let response = await fetch(url,
          {
            method: "GET",
            headers: authHeader(url),
          }
      );
      let tokens = await response.json();
      // log("tokens", tokens);
      return {
        packageID: tokens.id,
        packageToken: tokens.access_token
      }
    },
    upload(e) {
      // log("upload folder:", this.selectedfolder);
      let _this = this;
      let files = e.target.files;
      _.forEach(files, function (f, k) {
        // log(f);
        _this.Images[k] = f;
      });
      // log(this.Images);
    },
    uploadFile() {
      log("uploadFile");
      // log("selectedFolder ", this.selectedfolder);
      if (this.checkFileNames()) {
        let _this = this;
        this.showProgress = true;
        _.forEach(_this.Images, function (f, k) {
          // log("file info to use in progress: f", f);
          _this.progressMessage = "Uploading file... " + f.name;
          _this.MASVUpload(f);
        });
      }
    },
    async MASVUpload(uploadFile) {
      // log("MASVUpload");
      let selectedFolder = this.selectedfolder;
      let newFolderName = this.newFolderName;
      const { user } = useAuthStore();
      const userId = user.Id;
      const url = this.baseUrl + "/handle_masv";
      const filesArray = [{
        file: uploadFile,
        path: "",
        id: 0
      }];
      // log("filesArray", filesArray);
      this.MASVToken = await this.getToken();
      // log("masvtoken",this.MASVToken);
      var packageID = this.MASVToken.packageID;
      var packageToken = this.MASVToken.packageToken;
      let ready = true;
      if (packageID === null || packageID === undefined) {
        console.log("No MASV");
        ready = false;
        this.progressMessage = "Error! File uploading service is unavailable. Please try again in a few minutes.";
        this.isError = true;
        this.isComplete = true;
      }
      if (ready) {
        const MASVuploader = new MASV.Uploader(
            packageID,
            packageToken,
            "https://api.massive.app"
        );
        let listener = {
          onComplete: async () => {
            try {
              log("onComplete");
              // log("onComplete newFolder", newFolderName);
              // log("onComplete selectedFolder", selectedFolder);
              const formData = {
                "userId": userId,
                "packageid": packageID,
                "NewFolderName": newFolderName,
                "SelectedFolder": selectedFolder
              };
              // const formData = new FormData();
              // formData.append('packageid', packageID);
              // formData.append('NewFolderName', newFolderName);
              // formData.append('SelectedFolder', selectedFolder);
              this.progressMessage = "Moving files into folder..."
              // log("MASVUpload onComplete url ", url);
              // log("MASVUpload onComplete formData ", formData);
              const result = await fetch(url, {
                method: 'POST',
                headers: authHeader(url),
                body: JSON.stringify(formData)
              });
              const uploadData = await result.json();
              // log("onComplete uploadData", uploadData);
              if (uploadData.Status === "ok") {
                this.progressMessage = "All Done! You can close this dialog."
                this.isComplete = true;
              }
              if (uploadData.Status === "error") {
                this.progressMessage = "Error! " + uploadData.Message;
                this.isError = true;
                this.isComplete = true;
              }
            } catch (e) {
              // log("onComplete exception", uploadData);
              this.uploadMessage = "There was an error uploading the file. " + e;
              this.isError = true;
              this.isComplete = false;
            }
          },
          onError: e => {
            // log("listener on Error", e);
            this.uploadMessage = "There was an error uploading the file." + e;
            this.isError = true;
            this.showProgress = false;
            this.progressMessage = "";
            this.isComplete = false;

          },
          onFileComplete: f => {
            // log("listener onFileComplete", f.file.name);
            // log("did I run onComplete yet?NO!!");
            // this.uploadMessage = "Completed File... " + f.file.name;
            // _this.showMessage = true;
            this.uploadMessage = "Completed File... " + f.file.name;
            this.showMessage = true;
            // _this.isComplete = true;
          },
          onProgress: (transferred, total, speedStat) => {
            // log("listener onProgress", speedStat);
            const progress = Math.floor(transferred / total * 100);
            // log("listener progress math:", progress);
            this.progressMessage = "Upload progress: " + progress;
            this.showProgress = true;
          },
          onRetry: e => {
            // log("listener onRetry", e);
            this.progressMessage = "Error! Processing a retry...";
            this.showProgress = true;
          }
        };
        // log("here we go groot");
        MASVuploader.setListener(listener);
        MASVuploader.upload(...filesArray);
      }
    },
    selectFolder(folderName) {
      this.selectedFolder = folderName;
      // log("selectedFolder", this.selectedFolder);
    },
    cancelUpload() {
      this.isComplete = true;
      this.isError = true;
      this.progressMessage = "Upload Cancelled";
      console.log("cancelUpload was clicked");
      this.closeAndRefresh();
    },
    checkFileNames() {
      console.log("checkFileNames");
      let returnValue = true;
      let _this = this;
      _.forEach(_this.Images, function (f, k) {
        let chars = ['#', '+'];
        let doesInclude = chars.some(char => f.name.includes(char));
        console.log("checking, ", doesInclude)
        if (doesInclude) {
          console.log("found");
          returnValue = false;
          _this.isError = true;
          _this.uploadMessage = "'#' sign and '+' sign is not allowed in files names."
          _this.showMessage = true;
        }
      });
      return returnValue;
    }
  }
};
</script>

<template id="UploadFileTemplate">
  <div class="uk-modal-dialog">
    <div class="uk-modal-body">
      <h3 class="uk-modal-title">Upload File</h3>
      <hr/>
      <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}"
           class="uk-alert" v-html="uploadMessage">
      </div>
      <div v-if="showProgress" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}"
           class="uk-alert" v-html="progressMessage">
      </div>
      <Loading v-if="isLoading"/>

      <section v-if="!isLoading">
        <div class="uk-margin">
          <input type="text" name="newFolder" id="newFolder" class="uk-form-large uk-width-1-1"
                 v-model.trim="newFolderName" placeholder="New folder name">
        </div>
        <div class="uk-margin-small">
          <div uk-form-custom="target: true">
            <input type="file" id="UploadToLibrary" v-bind:onchange="upload" class="uk-form-input"
                   accept=".ai,.pdf,.tiff,.tif" multiple="multiple"/>
            <input class="uk-input uk-form-large" type="text" placeholder="Select file">
            <p class="uk-text-small">
              The Supported file formats are .ai, .pdf, .tiff, .tif. Recommended
              maximum file size is 150MB
            </p>
          </div>
        </div>
        <div class="uk-margin" uk-grid>
          <div>
            <button v-if="!isComplete" v-bind:onclick="uploadFile" class="uk-button uk-button-primary">Go</button>
            <button v-if="isComplete" v-on:click="resetComponent" class="uk-button uk-button-primary uk-modal-close">
              Close
            </button>
          </div>
          <div>
            <input v-if="!isComplete" v-on:click="cancelUpload" type="button" name="Cancel" value="Cancel"
                   class="uk-modal-close uk-button uk-button-secondary"/>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>