<script setup>
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import EditPayment from "./Components/EditPayment";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
</script>
<script type="module">
export default {
  data() {
    return {
      paymentsList: {},
      cckey: '',
      baseUrl: process.env.APIURL + "/Account"
    };
  },
  components: {
    "edit-payment-app": EditPayment,
  },
  mounted() {
    log("PaymentApp is mounted");
    UIkit.use(Icons);
    this.getPayments();
  },
  computed: {},
  methods: {

    async addPayment() {
      log("addPayment");
      this.cckey = "addPayment";
      UIkit.modal("#EditPaymentModal").show();
    },
    async getPayments() {
      log("getPayments")
      const url = this.baseUrl + "/GetPaymentOptions";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      this.paymentsList = await response.json();
      log("Payments: ", this.paymentsList);
    },
    editPayment(cckey) {
      log("editPayment", cckey);
      this.cckey = cckey;
      UIkit.modal("#EditPaymentModal").show();
    }
  }
};
</script>

<template>
  <div class="uk-container uk-container-expand">
    <section class="uk-section uk-margin-top" uk-grid>
      <div class="uk-width-1-1">
        <router-link v-bind:to="{name: 'accountDetails'}" class="uk-button uk-button-secondary"><span
            uk-icon="arrow-left"></span> Back to Account
        </router-link>
      </div>

      <div class="uk-width-4-5">
        <h2>Payment Methods</h2>
      </div>
      <div class="uk-width-1-5">
        <button type="button" class="uk-button uk-button-primary" v-on:click="addPayment">Add Payment</button>
      </div>
    </section>
    <section class="uk-section">
      <div v-if="paymentsList.length > 0" class="uk-flex uk-flex-row uk-flex-middle uk-flex-wrap uk-flex-around">
        <div v-for="(pm, pmKey) in paymentsList"
             class="uk-card uk-card-default uk-card-hover uk-margin uk-flex-none">
          <div class="uk-card-body uk-padding-large">
            <h3 class="uk-card-title" v-html="pm.Name"></h3>
            <hr/>
            <div>
              <dl>
                <dt>Payment Type</dt>
                <dd>{{ pm.Type }}</dd>
                <dt>Card (last-4)</dt>
                <dd>{{ pm.CardNo }}</dd>
                <dt>Expires</dt>
                <dd>{{ pm.MonthExpire }}/{{ pm.YearExpire }}</dd>
              </dl>
            </div>
            <div class="uk-card-footer">
              <button class="uk-button uk-button-primary" v-on:click="editPayment(pm.CCkey)">Edit</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div id="EditPaymentModal" uk-modal="bg-close: false">
    <edit-payment-app v-bind:cckey="cckey" v-bind:ccList="this.paymentsList"></edit-payment-app>
  </div>
</template>
