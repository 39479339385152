<script>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store';
import {authHeader} from '../helpers/authheader';
import {utilsMixin} from '../helpers/utils';
import PantoneChartService from "./Components/PantoneChartService.vue";
import ColorMatchService from "./Components/ColorMatchService.vue";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import TheMiscellaneous from './TheMiscellaneous.vue';

export default {
  mixins: [utilsMixin],
  data() {
    return {
      cartItemCount: "",
      cartItems: "",
      franchise: process.env.Franchise,
      serviceItems: "",
      componentStatus: "uk-animation-slide-top",
      hideButtons: true,
      isServiceLoading: false,
      noOrders: false,
      cartMessage: "",
      serviceMessage: "",
      showConfirm: false,
      showMessage: false,
      showServiceMessage: false,
      showTable: false,
      isError: false,
      isLoading: true,
      minCharge: false,
      minChargeAmount: 0,
      baseUrl: process.env.APIURL + "/Store"
    };
  },
  components: {
    "color-matching": ColorMatchService,
    "pantone-chart": PantoneChartService,
    // "misc-page": TheMiscellaneous,
  },
  mounted() {
    // console.log("CartApp is mounted");
    UIkit.use(Icons);
    this.isLoading = true;
    this.getServiceItems();
    this.getCart();
  },
  computed: {
    cartProducts: function () {
      let products = this.cartItems.products;
      // log("Products: ", products);
      // log("cart items: ", this.cartItems);
      let minCharge = null;
      let fixed = {};
      // fix 90OC nonsense for now
      // FRN need to move this to the end and populate it so it does not break.
      // FRN probably a computed row at the end of the table.
      let price = 0;
      _.forEach(products, function (item, k) {
        if (item.ItemNo === "90OC") {
          //item.MaterialText = "$150 Minimum Purchase Charge";
          // log("90OC found");
          minCharge = k;
          // log("minCharge is: ", minCharge);
          price = item.Price;
        }
      });
      // needed to add minCharge === 0 otherwise this code would not run if the minCharge index was 0.
      if (minCharge || minCharge === 0) {
        // log("Product before splice: ", products);
        products.splice(minCharge, 1);
        // log("Products after splice: ", products);
        this.minCharge = true;
        this.minChargeAmount = price;
      }
      if (this.minCharge === true) {
        this.minCharge = this.isFranchiseEnabled('portalPricing');
      }
      // log("cartProducts is: ", products);
      return products;
    },
    cartServices() {
      // log("cartServices", this.cartItems.services);
      const services = this.cartItems.services;
      return services;
    },
    // showPrice() {
    //   return this.showPortalPrice();
    // },
    totalOrder() {
      let returnValue = 0;
      let products = 0;
      let services = 0;
      let minCharge = this.minChargeAmount;
      _.forEach(this.cartItems.products, function (item, k) {
        products = products + item.Price;
      });
      _.forEach(this.cartItems.services, function (item, k) {
        services = services + item.Price;
      });
      // log("products", products);
      returnValue = products + services + minCharge;
      return toCurrency(returnValue);
      //return returnValue;
    },
    hasCorex() {
      let products = JSON.stringify(this.cartItems.products);
      // log(products);
      let hasCorex = false;
      if (products) {
        // log("looking for corex");
        const reg = new RegExp("Corex");
        // log(products.match(reg));
        const index = products.match(reg);
        // log(index);
        if (index !== null) {
          hasCorex = true;
        }
      }
      return hasCorex;
    }
  },
  methods: {
    AllowEdit(item) {
      //log("AllowEdit", item);
      let returnValue = true;
      if (item.product_type === "rigid" || item.product_type === 'misc' || item.product_type === 'Rolled') {
        returnValue = false;
      }
      if (item.ItemNo === "TS-SVC-1" || item.ItemNo === "TS-PMSC" || item.ItemNo == "TEST-ROLLED") {
        returnValue = false;
      }
      return returnValue;
    },
    async DecreaseQuantity(cartItem) {
      if (Number(cartItem.Quantity) > 1) {
        const url = this.baseUrl + "/UpdateQuantity";
        const authStore = useAuthStore();
        const user = authStore.user;
        const call = {
          userId: user.Id,
          cartId: cartItem.CartID,
          qty: Number(cartItem.Quantity - 1),
          itemSeq: Number(cartItem.ItemSeqNo)
        }
        // log("call is: ", call);
        const response = await fetch(url, {
          method: "POST",
          headers: authHeader(url),
          body: JSON.stringify(call)
        });
        const returnedData = await response.json();
        if (returnedData.Status === "ok") {
          this.getCart();
        }
      }
    },
    async IncreaseQuantity(cartItem) {
      const url = this.baseUrl + "/UpdateQuantity";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
        cartId: cartItem.CartID,
        qty: Number(cartItem.Quantity + 1),
        itemSeq: Number(cartItem.ItemSeqNo)
      }
      // log("call is: ", call);
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const returnedData = await response.json();
      if (returnedData.Status === "ok") {
        this.getCart();
      }
    },
    hasUrlType(item) {
      let returnValue = 'base64';
      const imageUrl = item.ImageUrl;
      // log("hasUrlType", imageUrl);
      if (imageUrl.includes("\/public\/")) {
        // if (item.product_type === "misc") {
        returnValue = 'public';
        // }
      }
      return returnValue;
    },
    hasBase64(item) {
      let returnValue = true;
      if (item.includes("Unable to create b64 string.")) {
        returnValue = false;
      }
      return returnValue;
    },
    ShowFinish(item) {
      // log("ShowFinish", item);
      let returnValue = false;
      if (item.FinishText) {
        if (item.FinishText.length > 0) {
          returnValue = true;
        }
      }
      if (item.Filename === "PantoneChart") {
        returnValue = true;
      }
      return returnValue;
    },
    ShowFilename(item) {
      let returnValue = false;
      if (item.ItemNo === "TS-SVC-1") {
        // log("ShowFilename", item.Filename);
        returnValue = true;
      }
      return returnValue;
    },
    ShowContourCut(item) {
      let returnValue = true;
      // Always indicate if contour cutting is true or not.
      // To prevent confusion on order followup when CAD CUT tickets.
      /*if (item.ItemNo === "TS-WINDOWFILM") {
        returnValue = false;
      }
      if (item.product_type === "adhesive") {
        returnValue = true;
      }*/
      return returnValue;
    },
    ShowRigid(item) {
      let returnValue = false;
      if (item.product_type === "rigid") {
        returnValue = true;
      }
      return returnValue;
    },
    ShowSize(item) {
      let returnValue = true;
      if (item.ItemNo === "90OC" || item.product_type === "misc") {
        returnValue = false;
      }
      if (item.ImageUrl.includes("PatnLibrary")) {
        returnValue = false;
      }
      return returnValue;
    },
    ShowRollSize(item) {
      let returnValue = false;
      if (item.product_type === "Rolled") {
        returnValue = true;
      }
      return returnValue;
    },
    ShowSquareFt(item) {
      let returnValue = ""
      if (item.SquareFeet > 0) {
        returnValue = "(" + item.SquareFeet + " Square Ft.)";
        if (item.product_type === "rigid") {
          returnValue = "(" + item.SquareFeet + " Sheets)";
        }
      }
      return returnValue;
    },
    ShowOverlap(item) {
      let returnValue = false;
      if (item.Overlap) {
        if (item.Overlap > 0) {
          returnValue = true;
        }
      }
      return returnValue;
    },
    FinishText(item) {
      let returnValue = item.FinishText;
      if (item.FinishText) {
        if (item.ItemNo === "TS-PMSC") {
          returnValue = item.LaminateType;
        }
      }
      if (item.Filename === "PantoneChart") {
        returnValue = item.LaminateType;
      }
      //log("FinishText return ", returnValue);
      return returnValue;
    },
    ServiceImage(serv) {
      let returnValue = "";
      if (serv.ImageUrl) {
        returnValue = serv.ImageUrl;
      }
      if (serv.ItemNo === "TS-SVC-1") {
        returnValue = "/public/images/ColorMatching.jpg";
      }
      return returnValue;
    },
    ContourCut(item) {
      let returnValue = "No contour cutting";
      if (item.ContourCutFile.length > 0) {
        // backward compat. No longer allow cut file uploads. Require them to be embedded.
        returnValue = "Has contour cut file: " + item.ContourCutFile;
        if (item.ContourCutFile === "embedded") {
          returnValue = "Has embedded cut file";
        }
      }
      //log("ContourCut return ", returnValue);
      return returnValue;
    },
    clearCart() {
      UIkit.modal("#ClearCartConfirm").show();
    },
    async clearCartConfirm() {
      // log("clearCartConfirm");
      UIkit.modal("#ClearCartConfirm").hide();
      const url = this.baseUrl + "/ClearCart";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const returnedData = await response.json();
      if (returnedData.Status === "ok") {
        this.getCart();
        // reload the page to update cart counter in TheMenu.  Maybe better to use pinia and emit an event?
        this.$router.go();
      }
    },
    async confirmDeleteItem(cartID) {
      // log("confirmDeleteItem");
      const url = this.baseUrl + "/DeleteCartItem";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
        cartId: cartID
      }
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const returnedData = await response.json();
      if (returnedData.Status === "ok") {
        this.getCart();
        this.showConfirm = false;
        this.hideButtons = false;
      }
    },
    escapeDeleteItem() {
      this.showConfirm = false;
      this.hideButtons = false;
    },
    deleteItem(key) {
      // log("deleteItem");
      this.showConfirm = key;
      this.hideButtons = key;
    },
    editCartItem(cartID) {
      // log("TheCart editCartItem", cartID);
      router.push({ name: "portal", params: { editID: cartID } });
      // router.push({path: `/portal/?editid=" + cartID`});
      // window.location = "/portal/?editid=" + cartID;
    },
    editCartService() {
      // log("editCartService");
    },
    showServiceModal(modal) {
      // log("showServiceModal", modal);
      let id = modal.replace(" ", "");
      id = "#" + id + "Modal";
      // log(id);
      UIkit.modal(id).show();
    },
    showColorsForSet() {
      // log("showColorsForSet");
    },
    /*getSvgDataURL(svgString) {
        const svgData = `data:image/svg+xml;base64,${btoa(svgString)}`;
        return svgData;
    },*/
    getCart: async function () {
      // log("getCart");
      this.isLoading = true;
      const url = this.baseUrl + "/GetCart";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const returnedData = await response.json();
      // log("CART returned: ", returnedData.Data);
      if (returnedData.Status === "ok") {
        this.isLoading = false;
        this.showMessage = false;
        this.cartMessage = "";
        this.showConfirm = false;
        this.hideButtons = false;
        this.showTable = true;
        this.cartItems = returnedData.Data;
        // console.log("CartItems returned", this.cartItems);
      }

      if (returnedData.Status === "nodata") {
        this.isLoading = false;
        this.showTable = false;
        this.showMessage = true;
        this.cartMessage = "<h3 class='uk-h1'>" + returnedData.Message + "</h3>";
        // clears the total after clearing the cart
        this.cartItems = [];
      }

      if (returnedData.Status === "exception") {
        this.isLoading = false;
        this.showTable = false;
        this.showMessage = true;
        this.isError = true;
        // log("exception tracking", returnedData);
        this.cartMessage = "<p>" + returnedData.Message + "</p>"
      }
    },
    async getServiceItems() {
      // log("getServiceItems");
      const url = this.baseUrl + "/GetServiceItems";
      let response = await fetch(url, {
        method: "GET",
        headers: authHeader(url),
      });
      let returnedData = await response.json();
      // log("SERVICE returned: ", returnedData);
      if (returnedData.Status === "ok") {

        this.serviceItems = this.filterServiceItems(returnedData.Data);
      }
      if (returnedData.Status === "exception") {
        this.showMessage = true;
        this.isError = true;
        this.cartMessage = "<p>" + returnedData.Message + "</p>"
      }
    },
    filterServiceItems(sItems) {
      let serviceItems = [];
      for (let i = 0; i < sItems.length; i++) {
        let item = sItems[i]
        if (this.isFranchiseEnabled('colorMatching')) {
          if (item.Tag.toLowerCase() === 'colormatching') {
            serviceItems.push(sItems[i]);
          }
        }
        if (this.isFranchiseEnabled('pantoneChart')) {
          if (item.Tag.toLowerCase() === 'pantonechart') {
            serviceItems.push(sItems[i]);
          }
        }
      }
      return serviceItems;
    }
  },
};
</script>
<template>
  <div class="uk-container uk-container-xlarge uk-margin-large-top">
    <!-- <section v-if="!isLoading" class="uk-modal-body uk-padding-large uk-text-center">
        <span class="uk-position-center"></span><i class="fa-solid fa-circle-pause fa-beat-fade fa-5x"></i>
    </section> -->

    <section class="uk-section uk-margin-top uk-padding-remove">
      <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-primary': !isError}"
           class="uk-alert" v-html="cartMessage"></div>
      <div class="uk-overflow-auto">
        <div id="ClearCartConfirm" uk-modal="bg-close: false">
          <div class="uk-modal-dialog uk-modal-body uk-width-1-3@m uk-margin-auto-vertical">
            <p class="uk-h4">This will delete all items from your cart.<br/>Are you sure?</p>
            <div class="uk-margin">
              <button class="uk-button uk-button-danger uk-margin-right uk-modal-close"
                      v-on:click.prevent="clearCartConfirm">
                Yes
              </button>
              <button class="uk-button uk-button-secondary uk-modal-close">Cancel</button>
            </div>
          </div>
        </div>
        <div class="uk-overflow-auto">
          <table v-if="showTable" id="CartTable"
                 class="uk-table uk-table-justify uk-table-middle uk-table-divider uk-table-hover">
            <thead>
            <tr>
              <th class="uk-width-1-5">
                <span class="uk-h3">Image</span>
              </th>
              <th class="uk-width-1-5">
                <span class="uk-h3">Product</span>
              </th>
              <th class="uk-width-1-5 uk-text-center">
                <span class="uk-h3">Quantity</span>
              </th>
              <th class="uk-width-1-5 uk-text-center">
                <div v-if="showPrice">
                  <span class="uk-h3">Price</span>
                </div>
              </th>
              <th class="uk-width-1-5 uk-child-width-1-2">
                <button v-on:click="clearCart" class="uk-button uk-button-danger uk-button-small uk-margin-right">Clear
                  Cart
                </button>
              </th>
            </tr>
            </thead>
            <tbody>
            <!-- Products -->
            <tr v-for="(c, k) in cartProducts" class="">
              <td class="uk-cover-container uk-text-break">
                <div v-if="hasUrlType(c) == 'base64'">
                  <div v-if="hasBase64(c.base64String)">
                    <div v-bind:data-src="c.base64String"
                         :class="{'uk-background-secondary': c.ImageUrl.includes('PatnLibrary')}"
                         class="uk-height-medium uk-width-medium uk-background-cover" uk-img></div>
                  </div>
                  <div v-else>
                    <div class=" uk-text-center uk-text-lead uk-height-medium uk-width-medium uk-background-cover">
                      <h3>No Image</h3>
                      <p>File is missing or moved.</p>
                      <p>You need to delete and re-add this product with a current image.</p>
                    </div>
                  </div>
                </div>

                <!-- public images for accessories and service products-->
                <div v-if="hasUrlType(c) == 'public'" v-bind:data-src="c.ImageUrl"
                     class="uk-height-medium uk-width-medium uk-background-contain" uk-img></div>

                <div class="uk-overlay uk-text-small uk-padding-remove-horizontal uk-padding-small uk-text-center">
                  {{ c.Filename }}
                </div>
              </td>
              <td class="uk-text-break">
                <h4>{{ c.MaterialText !== "" ? c.MaterialText : c.Filename }}</h4>
                <ul class="uk-list uk-list-collapse">
                  <li v-if="ShowSize(c)">{{ c.InchWidth }}"w by {{ c.InchHeight }}"h
                    {{ ShowSquareFt(c) }}
                  </li>
                  <li v-if="ShowRollSize(c)">Length: {{ c.rollSize }}'</li>
                  <li v-if="ShowFinish(c)"><strong>Finish: </strong>{{ FinishText(c) }}</li>
                  <li v-if="ShowContourCut(c)">{{ ContourCut(c) }}</li>
                  <li v-if="ShowOverlap(c)"><strong>Overlap: </strong>{{ c.Overlap }} in</li>
                  <li v-if="ShowRigid(c)">
                    <ul class="uk-list uk-list-collapse">
                      <li><strong>Print Sides: </strong>{{ c.sides }}</li>
                      <li v-if="c.round_corners > 0"><strong>Round
                        Corners: </strong>{{ c.round_corners }}"
                      </li>
                      <li v-if="c.hole_placement"><strong>Drill Hole
                        Placement: </strong>{{ c.hole_placement }}
                      </li>
                      <li v-if="c.hole_edge_distance"><strong>Drill Hole Edge
                        Distance: </strong>{{ c.hole_edge_distance }}
                      </li>
                      <li v-if="c.hole_hole_distance"><strong>Drill Hole to Hole
                        Distance: </strong>{{ c.hole_hole_distance }}
                      </li>
                      <li>
                        <span v-if="c.grommets">Item Has Grommets</span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span class="uk-text-italic">{{ c.OrderNotes }}</span>
                  </li>
                </ul>
              </td>
              <td class="">
                <div class="uk-flex uk-flex-middle uk-flex-center">
                  <div class="uk-padding-small uk-text-large uk-margin-right">
                    {{ c.Quantity }}
                  </div>
                  <!-- don't think we need this line -->
                  <!-- <div v-if="c.product_type == 'adhesive'" class="uk-button-group"> -->
                  <div class="uk-flex uk-flex-stretch uk-flex-center uk-flex-column uk-flex-middle">
                    <button class="uk-button uk-button-small uk-button-primary uk-text-large uk-margin-bottom"
                            v-on:click="IncreaseQuantity(c)">+
                    </button>
                    <button class="uk-button uk-button-small uk-button-danger uk-text-large"
                            v-on:click="DecreaseQuantity(c)">-
                    </button>
                  </div>
                </div>
              </td>
              <td class="uk-text-center">
                <div v-if="showPrice">
                  {{ $toCurrency(c.Price) }}
                </div>
              </td>
              <td class="">
                <div v-if="showConfirm === k">
                  <div class="uk-alert uk-alert-danger uk-padding-small">
                    <div class="uk-text-center uk-margin-small-bottom">
                      Are you sure you want to delete this item?
                    </div>
                    <div class="uk-text-center" uk-grid>
                      <div class="uk-width-1-2">
                        <button v-on:click="confirmDeleteItem(c.CartID)"
                                class="uk-button uk-button-small uk-button-danger">Yes
                        </button>
                      </div>
                      <div class="uk-width-1-2">
                        <button v-on:click="escapeDeleteItem"
                                class="uk-button uk-button-small uk-button-primary">No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="hideButtons !== k" class="uk-child-width-1-2 uk-align-center">
                  <button v-if="AllowEdit(c)" v-on:click="editCartItem(c.CartID)"
                          class="uk-button uk-button-primary uk-button-small uk-margin-right uk-margin-bottom">Edit
                  </button>
                  <button v-on:click="deleteItem(k)"
                          class="uk-button uk-button-danger uk-button-small">Delete
                  </button>
                </div>
              </td>
            </tr>
            <!-- Services -->
            <tr v-for="(s, k) in cartServices">
              <td>
                <div v-bind:data-src="s.ImageUrl" class="uk-height-medium uk-background-contain"
                     uk-img></div>
              </td>
              <td>
                <h4 v-if="s.ItemDesc">{{ s.ItemDesc }}</h4>
                <!--                <h4 v-if="s.MaterialText">{{ s.MaterialText }}</h4>-->
                <p v-if="s.LaminateType" class="uk-margin-remove-bottom">
                  <strong>Finish: </strong>{{ s.LaminateType }}
                </p>
                <p v-if="ShowFilename(s)" class="uk-margin-remove-bottom"><strong>For
                  Filename: </strong>{{
                    s.Filename
                  }}
                </p>
                <ul v-if="s.Colors.length > 0" class="uk-margin-remove-top uk-list uk-list-collapse">
                  <li v-for="color in s.Colors">{{ color }}</li>
                </ul>
              </td>
              <td>
                {{ s.Quantity }}
              </td>
              <td>
                {{ $toCurrency(s.Price) }}
              </td>
              <td>
                <div v-if="showConfirm === k">
                  <div class="uk-alert uk-alert-danger uk-padding-small">
                    <div class="uk-text-center uk-margin-small-bottom">
                      Are you sure you want to delete this service?
                    </div>
                    <div class="uk-text-center" uk-grid>
                      <div class="uk-width-1-2">
                        <button v-on:click="confirmDeleteItem(s.CartID)"
                                class="uk-button uk-button-danger">Yes
                        </button>
                      </div>

                      <div class="uk-width-1-2">
                        <button v-on:click="escapeDeleteItem"
                                class="uk-button uk-button-primary">No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="hideButtons !== k" class="uk-flex uk-flex-between uk-flex-around uk-align-center">
                  <button v-if="AllowEdit(s)" class="uk-button uk-button-primary uk-button-small">
                    Edit
                  </button>
                  <button v-on:click="deleteItem(k)"
                          class="uk-button uk-button-danger uk-button-small">Delete
                  </button>
                </div>
              </td>
            </tr>
            <tr v-if="minCharge">
              <td></td>
              <td>
                <h3>$150 Minimum Purchase Charge.</h3>
              </td>
              <td></td>
              <td class="uk-text-center">{{ $toCurrency(minChargeAmount) }}</td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section> <!--Table-->
    <section v-if="showTable && serviceItems.length > 0" id="addServices"
             class="uk-section uk-height-large uk-padding-remove">
      <!--      <div class="uk-flex uk-flex-row uk-flex-middle uk-flex-wrap uk-flex-around">-->
      <div class="uk-panel uk-flex uk-flex-middle uk-height-1-1 uk-margin uk-padding-remove uk-flex-center">
        <div class="uk-panel uk-width-1-3@m uk-card-hover" v-for="(serv, k) in serviceItems">
          <div class="uk-panel uk-padding uk-height-1-1 uk-width-1-1">
            <div class="uk-panel uk-height-medium">
              <div class="card-content uk-margin-top">
                <div class="uk-text-center uk-flex uk-flex-center uk-height-small uk-margin-bottom">
                  <img class="uk-height-1-3 uk-margin-remove uk-padding-remove" v-bind:src="ServiceImage(serv)" uk-img>
                </div>
                <div class="uk-text-center uk-padding-remove">
                  <h4>{{ serv.Name }}</h4>
                </div>
                <p class="uk-text-center"><strong>Price: </strong>{{ $toCurrency(serv.Price) }} <span
                    v-if="serv.Name == 'Color Matching'">per file.</span></p>
              </div>
            </div>
            <div class="uk-text-center uk-flex uk-child-width-1-2 uk-padding uk-padding-remove-top">
              <button class="uk-button uk-button-primary uk-button-small uk-width-1-1 uk-margin-right"
                      v-on:click="showServiceModal(serv.Name)">
                Add
              </button>
              <button class="uk-button uk-button-default uk-button-small uk-width-1-1"
                      v-on:click="showServiceModal(serv.Name)">
                Details
              </button>
            </div>
          </div>
        </div>
        <!--        Order Step Stakes when corex-->
        <div v-if="hasCorex" class="uk-panel uk-width-1-3@m uk-card-hover uk-height-1-1">
          <div class="uk-panel uk-padding uk-height-1-1 uk-width-1-1">
            <div class="uk-panel uk-height-medium">
              <div class="card-content uk-margin-top">
                <div class="uk-text-center uk-flex uk-flex-center uk-height-small uk-margin-bottom">
                  <img class="uk-height-1-3 uk-margin-remove uk-padding-remove" src="/public/images/TS-12-685-25.jpg"
                       uk-img>
                </div>
              </div>
              <div class="uk-text-center uk-padding-remove">
                <h4>Order Step Stakes</h4>
              </div>
              <p class="uk-text-center"><strong>Step Stakes for Signage.</strong></p>
            </div>
            <div class="uk-text-center uk-flex uk-flex-center uk-child-width-1-2 uk-padding uk-padding-remove-top">
              <router-link :to="{name: 'misc'}" class="uk-button uk-button-primary uk-button-small">
                <span uk-icon="link">&nbsp;</span>Order
              </router-link>
            </div>
          </div>
        </div>
      </div>

    </section> <!--Services-->
    <section class="uk-margin-block">
      <div class="uk-text-right uk-padding">
        <p><span v-if="showPrice" class="uk-h3 uk-padding">{{ totalOrder }}</span>
          <router-link :to="{name: 'checkout'}"
                       class="uk-button uk-button-large uk-button-primary"
                       :class="{'project-button' : franchise == 'NGS'}">
            Checkout<span class="uk-margin-small-left" uk-icon="credit-card"></span></router-link>
        </p>
      </div>
    </section> <!--Total and Checkout-->
  </div> <!--Container-->
  <!--Modals-->
  <div id="ColorMatchingModal" uk-modal="bg-close: false">
    <color-matching></color-matching>
  </div>

  <div id="PantoneChartModal" uk-modal="bg-close: false">
    <pantone-chart></pantone-chart>
  </div>

  <!--  <div id="MiscModal" uk-modal="bg-close: false">
      <misc-page></misc-page>
    </div>-->
</template>
