import {defineStore} from 'pinia';
import {router} from '../helpers/router';

const baseUrl = process.env.APIURL + "/users";
const franchise = process.env.Franchise;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in.
        // in Pinia state is always initialized here. You could code values, get values from a fetch, or do this.
        user: JSON.parse(localStorage.getItem(franchise + 'User')),
        JwtToken: localStorage.getItem(franchise + "JwtToken"),
        returnUrl: null
    }),
    getters: {
        // I think refresh jwt goes here.
        refreshJwtToken() {
        }
    },
    actions: {
        setUser(user) {
            // log("authStore setUser: ", user);
            // update pinia state
            this.user = user;
            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem(franchise + 'User', JSON.stringify(user));
        },
        setJwtToken(token) {
            // log("authStore setJwtToken: ", token);
            // update pinia state
            this.JwtToken = token;
            localStorage.setItem(franchise + "JwtToken", token);
            // Wait delay time and get a new token
            // const _refresh = this.refreshToken;
        },
        logout() {
            // true is no user available. false is valid user.
            const userStatus = _.isNil(this.user);
            // log("logging out a valid user");
            if (!userStatus) {
                let url = baseUrl + '/logout';
                const call = {
                    "username": this.user.Username,
                };
                let _user = this.user;
                fetch(url, {
                    method: 'POST',
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.user.JwtToken}`,
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
                    },
                    body: JSON.stringify(call)
                }).then(response => {
                    return response.json();
                }).then(function (jsondata) {
                    // log("got logout response: ", jsondata);
                    // if ok or failed, clean the user from stores
                    if (jsondata.Status === "ok" || jsondata.Status === "failed") {
                        // log("cleaning up user", _user);
                        // log("cleaning up user");
                        if (_user) {
                            _user = null;
                        }
                        localStorage.removeItem(franchise + 'User');
                        router.push({name: 'home'});
                    }
                    // if an exception, just go home, but leave logged in locally??.
                    if (jsondata.Status === "exception") {
                        log("user logout exception");
                        router.push({name: 'home'});
                    }
                    // Handle a not authorized or 404. Clean the user and let them login again
                    if (jsondata.message === "User not found" || jsondata.Status === "unauthorized") {
                        log("user logout not found, unauthorized");
                        if (_user) {
                            _user = null;
                        }
                        localStorage.removeItem(franchise + 'User');
                        // router.push({name: 'home'});
                    }
                    window.location.reload();
                });
            } else {
                // There is no user in the authStore. Clear the localStorage
                localStorage.removeItem(franchise + 'User');
                localStorage.removeItem(franchise + 'JwtToken');
                // window.location.reload();
                router.push({name: 'home'});
            }
        },
        async refreshToken() {
            // log("authStore refreshToken running");
            let returnValue = false;
            var url = baseUrl + "/refresh-token";
            const call = {
                Token: this.user.RefreshToken
            }
            if (this.user) {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.JwtToken}`,
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
                    },
                    body: JSON.stringify(call)
                });
                if (response.status === 504) {
                    // FRN Server is down, should do something here.
                    // A simple logout will throw more 504's 
                    // return False is the same as doing a simple logout.
                    // Might need a authStore.cleanUser method to just clean local data.
                }
                const newTokens = await response.json();
                // log("got new tokens",newTokens);
                const userWithToken = newTokens.Data;
                if (newTokens.Status === "exception") {
                    // FRN need to do something better here.
                    returnValue = false;
                }
                if (newTokens.Status === "ok") {
                    const token = userWithToken.JwtToken;
                    if (token.length > 0) {
                        this.setJwtToken(token);
                        delete userWithToken.JwtToken;
                        const user = userWithToken;
                        this.setUser(user);
                        returnValue = true;
                    } else {
                        // FRN same error handling as above
                        returnValue = false;
                    }
                }
            }
            return returnValue;
        },
        error504(){
            localStorage.removeItem(franchise + 'User');
            localStorage.removeItem(franchise + 'JwtToken');
            router.push({name: 'home'});
        }
    }
});