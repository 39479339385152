<script setup>
import TreeItem from "./TreeItem.vue";
import UploadFile from "./UploadFile.vue";
import Loading from "./Loading.vue";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {router} from "../../helpers/router";
import {useAuthStore} from "../../stores/auth.store";
import {authHeader} from "../../helpers/authheader";
</script>

<script>
export default {
  components: {
    "upload-file": UploadFile,
    "tree-item": TreeItem,
  },
  props: ['caller'],
  provide: function () {
    return {
      // Here we make this functions available to imported components.
      // They must inject with an inject array.
      doFileAction: this.doFileAction,
      closeAndRefresh: this.closeAndRefresh,
    }
  },
  inject: ["addToProject"],
  data() {
    return {
      isLoading: true,
      isLibraryError: false,
      showLibraryMessage: true,
      filesPerPage: 50,
      libraryMessage: "Loading Library",
      imageList: [],
      folderList: [],
      showError: false,
      errorMsg: "",
      selectedImage: {},
      selectedFolder: "",
      currentFolder: "",
      newFolder: "",
      fileAction: "browse",
      baseUrl: process.env.APIURL + "/Library"
    };
  },
  mounted() {
    log("The ManageFilesComponent is mounted");
    const {user} = useAuthStore();
    UIkit.use(Icons);
    this.populateFolderList();
    this.selectedFolder = "/ImgLibrary/" + user.Id;
    // this.populateImageList();
  },
  computed: {
    showDeleteFolder() {
      let returnValue = false;
      if (_.size(this.imageList) === 0) {
        returnValue = true;
        const myPath = this.selectedFolder;
        _.forEach(this.folderList.nodes, function (tree) {
          if (tree.path === myPath) {
            const myNodes = tree.nodes;
            // log("xx", myNodes);
            if (_.size(myNodes) > 0) {
              returnValue = false;
            }
          }
        });
      }
      return returnValue;
    }
  },
  methods: {
    resetComponent() {
      // log("reset ManageFiles Component");
      this.isLoading = true;
      this.isLibraryError = false;
      this.showLibraryMessage = true;
      this.libraryMessage = "Loading Library";
      this.showError = false;
      this.errorMsg = "";
      this.imageList = [];
      this.folderList = [];
      this.selectedImage = {};
      this.selectedFolder = "";
      this.currentFolder = "";
      this.newFolder = "";
      this.fileAction = "browse";
    },
    closeAndRefresh() {
      // log("closeAndRefresh selected folder", this.selectedFolder);
      this.populateFolderList();
      this.populateImageList(this.selectedFolder);
    },
    doFileAction: _.debounce(function (folderName) {
      this.selectedFolder = folderName;
      switch (this.fileAction) {
        case "browse":
          // log("browsing files", folderName);
          this.populateImageList(folderName);
          break;
        case "delete":
          // log("deleting files");
          break;
        case "move":
          // log("moving files");
          this.moveFile(folderName);
          break;
        default:
          this.populateImageList(folderName);
          break;
      }
    }, 200),
    async moveFile(folderName) {
      log("moveFile to", folderName);
      const url = this.baseUrl + "/MoveFolder";
      const {user} = useAuthStore();
      var filename = this.selectedImage.Name;
      var folderFrom = this.currentFolder;
      const data = {
        "userId": user.Id,
        "filename": filename,
        "folderFrom": folderFrom,
        "folderTo": folderName,
        "FullUrl": this.selectedImage.FullUrl,
        "ImageUrl": this.selectedImage.ImageUrl
      };
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      let result = await response.json();
      if (result.Status === "ok") {
        // reset page, i think
        window.location.reload();
      }

      if (result.Status === "failed") {
        this.libraryMessage = result.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = true;
        UIkit.modal("#MoveFileModal").hide();
        this.fileAction = "browse";
      }

    },
    async createFolder() {
      log("createFolder");
      const url = this.baseUrl + "/CreateFolder";
      const {user} = useAuthStore();
      var folderFrom = this.currentFolder;
      var folderName = this.newFolder;
      const data = {
        "userId": user.Id,
        "folderFrom": folderFrom,
        "folderTo": folderName,
      };
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      let result = await response.json();
      if (result.Status === "ok") {
        // reset page, i think
        UIkit.modal("#NewFolderModal").hide();
        this.closeAndRefresh();
      }

    },
    createFolderList(treeNodes) {
      let folderList = treeNodes[0];
      // log("folderList is ", folderList);
      return folderList;
    },
    async populateFolderList() {
      // log("populateFolderList");
      this.fileAction = "browse";
      const {user} = useAuthStore();
      const authStore = useAuthStore();
      const userId = user.Id;
      const url = this.baseUrl + "/GetFolderStructure";
      const data = {
        userId: userId,
      };
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      const tmpFolder = await response.json();
      // log("tmpfolders", tmpFolder);
      if (tmpFolder.Status === "ok") {
        // this.folderList = tmpFolder.Data;
        this.folderList = this.createFolderList(tmpFolder.Data);
        this.isLoading = false;
        // log("folderList",this.folderList);
        // this.selectedFolder = "/ImgLibrary/" + user.Id;
        await this.populateImageList(this.selectedFolder);
      }
      // This is the no images status.
      if (tmpFolder.Status === "invalid") {
        this.libraryMessage = tmpFolder.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = false;
      }
      if (tmpFolder.Status === "exception") {
        this.libraryMessage = tmpFolder.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = true;
      }
      if (tmpFolder.Status === "unathorized") {
        this.libraryMessage = tmpFolder.Message;
        authStore.logout();
        // router.push({name: 'home', replace: true});
        // window.location.reload();
      }
      if (_.isNil(tmpFolder.Status)) {
        authStore.logout();
        // router.push({name: 'home', replace: true});
        // window.location.reload();
      }
      if (this.folderList.length > 0) {
        this.isLoading = false;
        this.showLibraryMessage = false;
        this.isLibraryError = false;
      }
    },
    async populateImageList(folderName) {
      // log("populateImageList", folderName);
      this.isLoading = true;
      this.fileAction = "browse";
      this.currentFolder = folderName;
      const url = this.baseUrl + "/GetDirectoryContents";
      const {user} = useAuthStore();
      const data = {
        "userId": user.Id,
        "directory": folderName,
        "pageSize": this.filesPerPage
      };
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      const tmpImages = await response.json();
      // log("populateImageList json", tmpImages);
      if (tmpImages.Status === "ok") {
        this.imageList = tmpImages.Data;
        this.isLoading = false;
        // log("imageList", this.imageList);
      }
      // This is no images
      if (tmpImages.Status === "invalid") {
        // log("No Images")
        this.isLoading = false;
        this.libraryMessage = tmpImages.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = false;
        this.imageList = [];
      }
      if (tmpImages.Status === "exception") {
        this.libraryMessage = tmpImages.Message;
        this.isLoading = false;
        this.showLibraryMessage = true;
        this.isLibraryError = true;
        this.imageList = [];
      }
      if (tmpImages.Status === "unathorized") {
        this.libraryMessage = tmpFolder.Message;
        this.isLoading = false;
        UIKit.modal("#ManageFilesApp").hide();
        authStore.logout();
        // router.push({name: 'home', replace: true});
        // window.location.reload();
      }
      // clear all errors when the list is populated
      if (this.imageList.length > 0) {
        this.isLoading = false;
        this.showLibraryMessage = false;
        this.isLibraryError = false;
      }
    },
    async populateFullImageList() {
      // log("populateFullImageList", this.selectedFolder);
      this.isLoading = true;
      this.fileAction = "browse";
      this.currentFolder = this.selectedFolder;
      const url = this.baseUrl + "/GetDirectoryContents";
      const {user} = useAuthStore();
      const data = {
        "userId": user.Id,
        "directory": this.selectedFolder,
        "pageSize": 0
      };
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      const tmpImages = await response.json();
      // log("populateImageList json", tmpImages);
      if (tmpImages.Status === "ok") {
        this.imageList = tmpImages.Data;
        this.isLoading = false;
        // log("imageList", this.imageList);
      }
      // This is no images
      if (tmpImages.Status === "invalid") {
        // log("No Images")
        this.isLoading = false;
        this.libraryMessage = tmpImages.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = false;
        this.imageList = [];
      }
      if (tmpImages.Status === "exception") {
        this.libraryMessage = tmpImages.Message;
        this.isLoading = false;
        this.showLibraryMessage = true;
        this.isLibraryError = true;
        this.imageList = [];
      }
      if (tmpImages.Status === "unathorized") {
        this.libraryMessage = tmpFolder.Message;
        this.isLoading = false;
        UIKit.modal("#ManageFilesApp").hide();
        authStore.logout();
        // router.push({name: 'home', replace: true});
        // window.location.reload();
      }
      // clear all errors when the list is populated
      if (this.imageList.length > 0) {
        this.isLoading = false;
        this.showLibraryMessage = false;
        this.isLibraryError = false;
      }
    },
    async getImageData(ImageURL) {
      const result = await this.GetImage(ImageURL);
      log("result", result);
      return result;
    },
    openImage() {
      //opens image in a lightbox
    },
    selectDeleteFile(imageD) {
      log("selectFile to be deleted id: ", imageD);
      this.fileAction = "delete";
      this.selectedImage = imageD;
      UIkit.modal("#DeleteFileModal").show();
    },
    selectMoveFile(imageD) {
      log("selectFileMove", imageD);
      this.fileAction = "move";
      this.selectedImage = imageD;
      UIkit.modal("#MoveFileModal").show();
    },
    selectedFolderPath(fullUrl) {
      // log("selectedFolderPath", fullUrl);
      const {user} = useAuthStore();
      let folderPath = fullUrl;
      if (!_.isNil(user)) {
        const libPath = "/ImgLibrary/" + user.Id;
        folderPath = fullUrl.replace(libPath, "");
      }
      // log("finalFolderPath", folderPath);
      return folderPath;
    },
    async deleteImage() {
      const url = this.baseUrl + "/DeleteItem";
      const {user} = useAuthStore();
      console.log("Selected image: ", this.selectedImage);
      const data = {
        "userId": user.Id,
        "FullUrl": this.selectedImage.FullUrl,
        "ImageUrl": this.selectedImage.ImageUrl
      };
      // console.log("ID after constructing data call: ", this.selectedImage.Id.toString());
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      let result = await response.json();
      // log("result from deleteImage is ", result);
      if (result.Status === "ok") {
        this.libraryMessage = result.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = false;
        await this.populateImageList(this.currentFolder);
      }

      if (result.Status === "failed") {
        this.libraryMessage = result.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = true;
      }
      UIkit.modal("#DeleteFileModal").hide();
      this.fileAction = "browse";
    },
    async deleteFolder() {
      // log("deleteFolder", this.currentFolder);
      const url = this.baseUrl + "/DeleteFolder";
      const {user} = useAuthStore();
      const data = {
        "userId": user.Id,
        "directory": this.currentFolder
      };
      // log("deleteFolder data", data);
      // log("deleteFolder url", url);
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      });
      let result = await response.json();
      // log("result from deleteFolder is ", result);
      if (result.Status === "ok") {
        this.libraryMessage = result.Message;
        this.showLibraryMessage = true;
        this.isLibraryError = false;
        // reset to toplevel
        this.selectedFolder = "/ImgLibrary/" + user.Id;
        this.closeAndRefresh()
        // await this.populateImageList(this.currentFolder);
      }

      if (result.Status === "exception") {
        this.libraryMessage = "Unable to Delete Folder. If you need this folder removed contact Lowen Tradesource for help.";
        this.showLibraryMessage = true;
        this.isLibraryError = true;
      }
      this.fileAction = "browse";
    },
    uploadFile() {
      this.fileAction = "upload";
      UIkit.modal("#UploadFileModal").show();
    }
  },
};
</script>

<!--  Manage Files -->
<template id="ManageFilesTemplate">
  <Loading v-if="isLoading"/>
  <section v-if="!isLoading" class="app-main uk-section uk-width-1-1">
    <div class="uk-container uk-container-expand">
      <div class="app-layout uk-align-center uk-flex uk-flex-between" uk-grid>
        <!-- Folders -->
        <div class="folder-control-list uk-width-1-4@m">
          <div v-if="folderList">
            <ul class="uk-nav uk-nav-default uk-nav-divider">
              <tree-item v-bind:selectedfolder="selectedFolder" v-bind:folders="folderList"></tree-item>
            </ul>
          </div>
          <div class="uk-padding uk-padding-remove-vertical uk-padding-remove-left">
            <div class="uk-margin-small uk-flex uk-flex-column uk-flex-between uk-child-width-1-1">
              <button class="uk-button uk-button-primary uk-margin-bottom" v-on:click="uploadFile"><span
                  uk-icon="upload"></span> Upload
              </button>
              <button class="uk-button uk-button-primary" uk-toggle="#NewFolderModal"><span
                  uk-icon="folder"></span> New
                Folder
              </button>
              <button v-if="imageList.length > 49" @click="populateFullImageList()"
                      class="uk-button uk-button-primary uk-margin-top"><span
                  uk-icon="">Load All Files</span>
              </button>
              <div v-if="imageList.length > 49" class="uk-card uk-alert-warning uk-padding-small uk-margin-small-top">
                <div>
                  <p>Click 'Load All Files' to see more than {{ filesPerPage }} files. This may make the interface very
                    slow.</p>
                  <p>We recommend using folders to organize your files.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Image Library -->
        <div class="image-library uk-width-3-4@m">
          <div v-if="showLibraryMessage" class="uk-card uk-card-default uk-card-body">
            <div v-bind:class="{ 'uk-alert-warning': isLibraryError, 'uk-alert-primary': !isLibraryError }"
                 class=""
                 v-html="libraryMessage" uk-alert></div>
            <div>
              <button v-if="showDeleteFolder" class="uk-button uk-button-danger uk-modal-close"
                      v-on:click="deleteFolder"><span uk-icon="minus-circle"></span>&nbsp;Delete Folder
              </button>
            </div>
          </div>
          <div class="the-image-wrapper" v-if="imageList.length > 0">
            <div class="uk-flex uk-flex-around" uk-grid>
              <!-- <div class="uk-flex uk-flex-row uk-flex-middle uk-flex-wrap uk-flex-around"> -->
              <div v-for="(ImageD, ImageK) in imageList"
                   class="uk-animation-slide-bottom-small uk-card uk-card-default uk-padding uk-width-medium uk-width-large@l uk-width-xlarge@xl">
                <div class="uk-card-body">
                  <div class="uk-text-center" style="word-break: break-word">{{ ImageD.Name }}</div>
                  <div v-if="caller === 'TheManageFiles'" uk-lightbox>
                    <a v-bind:href="ImageD.base64String" v-bind:data-caption="ImageD.Name"
                       v-bind:data-alt="ImageD.Name" data-type="image">
                      <div v-bind:data-src="ImageD.base64String"
                           class="uk-height-small uk-background-contain uk-margin-bottom"
                           uk-img></div>
                    </a>
                  </div>
                  <div v-if="caller === 'TheVuePortal'" v-bind:data-src="ImageD.base64String"
                       class="uk-background-contain uk-margin-small uk-modal-close uk-height-small"
                       v-on:click="addToProject(ImageD)"
                       uk-img></div>
<!--                  <div
                      class="uk-panel uk-text-center uk-padding-small uk-padding-remove-horizontal uk-margin-small-bottom uk-background-muted">
                    <span>{{ ImageD.InchWidth }}" W x {{ ImageD.InchHeight }}" H</span>
                  </div>-->
                  <div class="uk-flex uk-flex-center uk-flex-between uk-child-width-1-1 uk-margin-top">
                    <div class="uk-margin-right uk-widht-1-1">
                      <button class="uk-button uk-button-primary uk-width-1-1"
                              v-on:click="selectMoveFile(ImageD)">
                        <span uk-icon="move"></span> Move
                      </button>
                    </div>
                    <div class="">
                      <button class="uk-button uk-button-danger uk-width-1-1"
                              v-on:click="selectDeleteFile(ImageD)">
                        <span uk-icon="minus-circle"></span> Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- grid -->
  </section>

  <div id="DeleteFileModal" uk-modal="bg-close: false">
    <div class="uk-modal-dialog">
      <div class="uk-modal-body">
        <h3 class="uk-modal-title">Delete Image</h3>
        <hr/>
        <p>Delete file: {{ selectedImage.Name }} </p>
        <p class="uk-alert uk-alert-warning">Deleting files removes them from our servers. If you have used this
          image in a previously ordered project, you will not be able to reorder that project.</p>
        <div class="uk-margin">
          <input type="button" name="deleteFile" value="Delete"
                 class="uk-button uk-button-danger uk-margin-right uk-modal-close"
                 v-on:click="deleteImage"/>
          <input type="button" name="Cancel" value="Cancel"
                 class="uk-modal-close uk-button uk-button-secondary"/>
        </div>
      </div>
    </div>
  </div>

  <div id="NewFolderModal" uk-modal="bg-close: false">
    <div class="uk-modal-dialog">
      <div class="uk-modal-body">
        <h3 class="uk-modal-title">New Folder</h3>
        <hr/>
        <p>This will create a new folder under: <em>{{ selectedFolderPath(selectedFolder) }}</em>.</p>
        <div class="uk-margin">
          <div class="uk-margin-small">
            <label for="newFolder" class="uk-form-label uk-text-bold">Folder Name</label>
            <div class="uk-form-controls">
              <input type="text" name="newFolder" v-model.trim="newFolder" placeholder="Enter Name"
                     class="uk-input"/>
            </div>
          </div>
          <input type="button" name="createFolder" value="Create"
                 class="uk-button uk-button-danger uk-margin-right uk-modal-close"
                 v-on:click="createFolder()"/>
          <input type="button" name="Cancel" value="Cancel"
                 class="uk-modal-close uk-button uk-button-secondary"/>
        </div>
      </div>
    </div>
  </div>

  <div id="MoveFileModal" uk-modal="bg-close: false">
    <div class="uk-modal-dialog">
      <div class="uk-modal-body">
        <h3 class="uk-modal-title">Move File</h3>
        <hr/>
        <p class="uk-alert uk-alert-warning">Do not move a file if you have already added it to a project. The
          link to the project file will be broken.</p>
        <p>Selecting a folder will move the file to that folder.</p>
        <div v-if="folderList">
          <ul class="uk-nav uk-nav-default uk-nav-divider">
            <tree-item v-bind:folders="folderList"></tree-item>
          </ul>
        </div>
        <div class="uk-margin">
          <input type="button" name="Cancel" value="Cancel"
                 class="uk-modal-close uk-button uk-button-secondary"/>
        </div>
      </div>
    </div>
  </div>

  <div id="UploadFileModal" uk-modal="bg-close: false">
    <upload-file v-bind:selectedfolder="selectedFolder"></upload-file>
  </div>
</template>

<style>
.cog {
  animation: spin 5s linear infinite;
  color: rgb(33, 84, 173);
  top: 100px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    background-image: gradient;
  }
  50% {
    rotate: 180deg;
  }
  100% {
    rotate: 360deg;
  }
}
</style>