<script setup>
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import {router} from '../helpers/router';
import TheMenu from "./TheMenu.vue"
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
</script>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      productCall: {
        product_type: "misc",
        ItemNo: ""
      },
      productList: {},
      cartError: false,
      errorMessage: "",
      message: ""
    }
  },
  mounted() {
    log("TheMiscellaneous is mounted");
    UIkit.modal("#CartModal").hide();
    this.getProducts();
  },
  beforeUnmount() {
    UIkit.modal("#CartModal").hide();
  },
  computed: {},
  methods: {
    async getProducts() {
      log("getProducts");
      let configData = await this.fetchConfigData();
      let productList = JSON.parse(configData);
      let choiceData = productList.dtOptionChoices;
      // Need to remove product_type
      _.pullAllBy(choiceData, [{
        'OptionKey': 'product_type'
      }], 'OptionKey');
      this.productList = choiceData;
    },
    async fetchConfigData() {
      // log("fetchConfigData", this.activeImage);
      const url = this.baseUrl + "/Portal/GetProductOptions";
      const {user} = useAuthStore();
      const call = {
        userId: user.Id,
        product_type: "misc",
        ItemNo: ""
      };
      const responseData = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const configData = await responseData.json();
      return configData;
    },
    generateImageURL(choiceValue) {
      return "/public/images/" + choiceValue + ".jpg";
    },
    addMiscToCart(choiceValue) {
      log("addMIscToCart ", choiceValue);
      log("productlist ", this.productList);
      const url = this.baseUrl + "/Store/AddToCartMisc";
      const {user} = useAuthStore();
      const call = {
        userId: user.Id,
        ItemNo: choiceValue,
        ImageUrl: this.generateImageURL(choiceValue),
        Filename: choiceValue,
        ItemDesc: "Miscellaneous",
        MaterialText: "Step Stakes - " + choiceValue,
        SetId: 0,
        Quantity: 1,
        Material: "misc",
        product_type:"misc",
      };
      fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      }).then(response => {
        return response.json();
      }).then(jsondata => {
        if (jsondata.Status === "ok") {
          this.message = jsondata.Message;
          UIkit.modal("#CartModal").show();
        }
        if (jsondata.Status === "exception") {
          this.errorMessage = jsondata.Message;
          UIkit.modal("#CartModal").show();
        }
      });
    },
    handleContinue() {
      UIkit.modal("#CartModal").hide();
      router.push({name: 'portal'});
    },
    handleGoToCart() {
      UIkit.modal("#CartModal").hide();
      router.push({name: 'cart'});
    },
  }
}
</script>
<template>
  <div id="ProductsApp" class="uk-section">
    <div class="uk-container">
      <h1 class="uk-h3 uk-text-center">Step Stakes</h1>
      <!-- Select Options -->
      <div class="uk-grid-match uk-flex-center uk-child-width-1-2@s" uk-grid>
        <div v-for="option in productList" :key="option.OptionKey">
          <div
              class="uk-card uk-card-default uk-card-hover uk-card-small uk-grid-collapse uk-child-width-1-2@s uk-margin uk-padding"
              uk-grid>
            <div class="uk-card-media-left">
              <img style="max-height: 200px" class="uk-responsive-width"
                   v-bind:src="generateImageURL(option.ChoiceValue)"
                   v-bind:alt="option.ChoiceName"/>
            </div>
            <div>
              <div class="uk-card-body">
                <h3>{{ option.ChoiceName }}</h3>
                <p>{{ option.ChoiceLongDesc }}</p>
                <h4>${{ option.ChoicePrice }}</h4>
              </div>
              <div class="uk-text-right">
                <button class="uk-button uk-button-secondary" v-on:click="addMiscToCart(option.ChoiceValue)">Add To Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Continue to Cart Implementation -->
      <div id="CartModal" class="" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
          <div class="modal-content">
            <div class="uk-margin">
              <div class="uk-h3 uk-text-center" v-if="!cartError">
                {{ message }}
              </div>
              <div v-if="cartError">
                <p>There was an error adding this product.</p>
                <div class="uk-alert-warning">
                  {{ this.errorMessage }}
                </div>
                <p>If the problem persist please contact us for assistance.</p>
              </div>
              <div class=" uk-flex uk-flex-center" uk-grid>
                <div>
                  <button class="uk-button uk-button-secondary uk-modal-close" @click="handleContinue">Continue Creating
                  </button>
                </div>
                <div class="uk-text-right">
                  <button class="uk-button uk-button-primary uk-modal-close" @click="handleGoToCart">Go To Cart</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
