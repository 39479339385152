<script setup>

</script>

<style scoped>

.cog {
    animation: spin 5s linear infinite;
    color: rgb(33, 84, 173);
    top: 100px;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes spin {
    0% {
        background-image: gradient;
    }
    50% {
        rotate: 180deg;
    }
    100% {
        rotate: 360deg;
    }
}
</style>

<template>
    <div class="uk-flex uk-flex-column uk-width-auto  uk-position-center">
        <!-- L<span class="cog" uk-icon="icon: cog; ratio: 2"></span>ading -->
        <span class="cog uk-width-1-1" uk-icon="icon: cog; ratio: 7;"></span>
    </div>
</template>