<script setup>
import {utilsMixin} from '../../helpers/utils';
</script>
<script>
export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      lowenCertifiedURL: process.env.LowenCertifiedURL,
      lowenColorGraphicsURL: process.env.LowenColorGraphicsdURL,
      lowenCorpURL: process.env.LowenCorpURL,
      NGSCorpURL: process.env.NGSCorpURL,
      franchise: process.env.Franchise,
    };
  },
  mixins: [utilsMixin],
}
</script>
<template>
  <div id="Footer"
       class="uk-padding uk-padding-remove@m uk-margin-remove uk-flex uk-flex-around@m uk-align-middle" uk-grid>
    <div id="LowenCorpContactSocial" class="uk-padding-remove uk-width-1-4@m uk-text-center uk-text-left@m">
      <div>
        <router-link v-if="this.franchise === 'Decor'" :to="{ name: 'home'}">
          <img src="/public/logos/DecorIcon.png" alt="Decor Renaissance">
        </router-link>
      </div>
      <p>
        <span v-if="address1()" v-html="address1()"></span>
        <br>
        <span v-if="address2()">{{ address2() }}</span>
        <br>
        <span v-if="address3()">{{ address3() }}</span>
      </p>
    </div>
    <div id="ContactInformation" class="uk-padding uk-padding-remove-top uk-width-1-3@m">
      <h2 v-if="contactPhone()" class="uk-h1 uk-text-center uk-text-primary uk-text-bold uk-link-text"><a
          :href="contactPhone()">{{ contactPhone() }}</a></h2>
    </div>
    <div v-if="this.franchise === 'Decor'" class="uk-padding-remove">
      <a class="uk-logo decor-logo uk-visible@m" :href="this.lowenCorpURL" target="_blank">
        <img class="uk-img" src="/public/logos/DecorLogo.png" alt="Decor Renaissance">
      </a>
    </div>
  </div>
</template>