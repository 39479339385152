<script setup>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
</script>
<script type="module">
export default {
    inject: ["doFileAction"],
    props: {
        folders: Object,
        selectedfolder: "",
    },
    data() {
        return {}
    },
    mounted() {
        // log("mounted FileFolderTemplate");
        UIkit.use(Icons);
    },
    computed: {
        isFolder() {
            // log("isFolder", this.folders);
            return this.folders.nodes && this.folders.nodes.length
        }
    },
    methods: {}
}
</script>

<template id="FileFolderTemplate">
    <li v-bind:class="{'uk-parent': isFolder, 'uk-active': selectedfolder == folders.path}">
        <a href="#" v-on:click="doFileAction(folders.path)" class="uk-nav-header">
            <span v-if="isFolder" class="uk-icon" uk-icon="icon:album"></span>
            <span v-if="!isFolder" class="uk-icon" uk-icon="folder"></span>
            <span class="folder-text">{{ folders.text }}</span>
        </a>
        <ul id="LibraryFolderList" class="uk-nav-sub" v-if="isFolder">
            <tree-item v-for="folders in folders.nodes" v-bind:selectedfolder="selectedfolder"
                       v-bind:folders="folders"></tree-item>
        </ul>
    </li>
</template>