import {createRouter, createWebHistory} from 'vue-router';
import {useAuthStore} from '../stores/auth.store.js';
import {useWizardStore} from '../stores/wizard.store.js';
import UIkit from "uikit";
// The static pages
import TradesourceHome from '../views/TradesourceHome.vue';
import AspireHome from '../views/AspireHome.vue';
import DecorHome from '../views/DecorHome.vue';
import EPDHome from '../views/EPDHome.vue';
import WebPrintHome from '../views/WebPrintHome.vue';
import TradesourceTerms from '../views/TradesourceTerms.vue';
import TradesourceResources from '../views/TradesourceResources.vue';
import TradesourceHelp from '../views/TradesourceHelp.vue';
// The Application pages
import TheAddresses from '../views/TheAddresses.vue';
import TheCart from '../views/TheCart.vue';
import TheCheckout from '../views/TheCheckout.vue';
import TheOrderDetails from '../views/TheOrderDetails.vue';
import TheDesigner from '../views/TheDesigner.vue';
import TheManageFiles from '../views/TheManageFiles.vue';
import TheManageAccounts from '../views/TheManageAccounts.vue';
import TheMiscellaneous from '../views/TheMiscellaneous.vue';
import ThePatternLibrary from '../views/ThePatternLibrary.vue';
import ThePayments from '../views/ThePayments.vue';
import TheUserAccount from '../views/TheUserAccount.vue';
import TheUserList from '../views/TheUserList.vue';
import TheApply from '../views/TheApply.vue';
import VuePortal from '../views/VuePortal.vue';
import NotFound from '../views/NotFound.vue';
import Login from '../views/Components/Login.vue';
import TileMenu from '../views/Components/TileMenu.vue';


function createRoutes() {
    const franchise = process.env.Franchise;
    let franchiseRoutes = [];
// FRN I would rather only load components when needed, but menu and footer broke.
    let commonRoutes = [
        {
            name: 'account',
            title: 'User Account',
            path: '/user',
            component: TheUserAccount
        },
        {
            name: 'accountDetails',
            meta: {
                title: 'Account Details',
            },
            path: '/account',
            component: TheUserAccount
        },
        {
            name: 'manageAccounts',
            meta: {
                title: 'Manage Accounts',
            },
            path: '/account-manager',
            component: TheManageAccounts
        },
        {
            name: 'cart',
            meta: {
                title: 'The Cart',
            },
            path: '/store/cart',
            component: TheCart
        },
        {
            name: 'checkout',
            meta: {
                title: 'Checkout',
            },
            path: '/store/checkout',
            component: TheCheckout
        },
        {
            name: 'manageFiles',
            title: 'Manages Your Library',
            meta: {
                title: 'Manage Your Library',
                tags: [
                    {
                        name: 'description',
                        content: 'Upload your personal files for design projects.'
                    },
                ]
            },
            path: '/library',
            component: TheManageFiles
        },
        {
            name: 'notFound',
            meta: {
                title: 'Oh! No!',
            },
            path: '/:pathMatch(.*)*',
            component: NotFound
        },
        {
            name: 'orderDetails',
            meta: {
                title: 'Order Details',
            },
            path: '/store/orderdetails/:orderNumber/:userId',
            component: TheOrderDetails,
            props: true
        },
        {
            name: 'shippingAddresses',
            meta: {
                title: 'Manage Addresses',
            },
            path: '/account/shippingaddresses',
            component: TheAddresses
        },
        {
            name: 'help',
            meta: {
                title: 'Help',
            },
            path: '/help',
            component: TradesourceHelp
        },
        {
            name: 'managePayments',
            meta: {
                title: 'Manage Payments',
            },
            path: '/account/paymentoptions',
            component: ThePayments
        },
        {
            name: 'misc',
            meta: {
                title: 'Order Accessories',
            },
            path: '/store/miscellaneous',
            component: TheMiscellaneous
        },
        {
            name: 'resources',
            meta: {
                title: 'Helpful Resources',
            },
            path: '/resources',
            component: TradesourceResources
        },
        {
            name: 'terms',
            meta: {
                title: 'Terms of Use',
            },
            path: '/terms',
            component: TradesourceTerms
        },
        {
            name: 'patternLibrary',
            meta: {
                title: 'Pattern Library',
            },
            path: '/patternLibrary',
            component: ThePatternLibrary
        },
        {
            name: 'publicLibrary',
            meta: {
                title: 'Public Library',
            },
            path: '/PublicLibrary',
            component: ThePatternLibrary
        },
        {
            name: 'portal',
            meta: {
                title: 'Wholesale Graphics',
            },
            path: '/portal/:editID?',
            component: VuePortal,
            props: true
        },
        // testing passing selection as prop in router
        {
            name: 'wizard-portal',
            meta: {
                title: 'Wholesale Graphics',
            },
            path: '/portal/:materialSelection?',
            component: VuePortal,
            props: true
        },
        // for testing tile menu without distractions
        {
            name: 'tileMenu',
            meta: {
                title: 'Guided Product Selection',
            },
            path: '/wizard',
            component: TileMenu
        },
        {
            name: 'BELogin',
            path: '/belogin',
            params: {username: "", foo: ""},
            component: Login
        },
        {name: 'UserList', path: '/userList', component: TheUserList},
    ];

    if (franchise === "NGS") {
        franchiseRoutes = [
            {
                name: 'home',
                meta: {
                    title: 'Aspire Films and Graphics',
                },
                path: '/',
                component: AspireHome
            },
            {
                name: 'designer',
                meta: {
                    title: 'Pattern and Texture Designer',
                },
                path: '/design',
                component: TheDesigner
            },
        ];
    }
    if (franchise === "Decor") {
        franchiseRoutes = [
            {
                name: 'home',
                meta: {
                    title: 'Lowen Decor Renaissance',
                },
                path: '/',
                component: DecorHome
            },
            {
                name: 'designer',
                meta: {
                    title: 'Pattern and Texture Designer',
                },
                path: '/design',
                component: TheDesigner
            },
        ];
    }
    if (franchise === "EPD") {
        franchiseRoutes = [
            {
                name: 'home',
                meta: {
                    title: 'EPD',
                },
                path: '/',
                component: EPDHome
            },
        ];
    }
    if (franchise === "WebPrint") {
        franchiseRoutes = [
            {
                name: 'home',
                meta: {
                    title: 'Web Print On Demand',
                },
                path: '/',
                component: WebPrintHome
            },
        ];
    }
    if (franchise === "Wholesale") {
        franchiseRoutes = [
            {
                name: 'home',
                meta: {
                    title: 'Lowen Tradesource | Wholesale, Printed, Large Scale Graphics',
                },
                path: '/',
                component: TradesourceHome
            },
            {
                name: 'apply',
                meta: {
                    title: 'Create an Account',
                },
                path: '/apply',
                component: TheApply
            },
        ];
    }
    // let routes = allRoutes;
    let routes = _.union(commonRoutes, franchiseRoutes);
    // log("routes are", routes);
    return routes;
}

export const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'uk-active',
    routes: createRoutes(),
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/', '/apply', '/terms', '/resources', '/help', '/belogin', '/PublicLibrary'];
    const authRequired = !publicPages.includes(to.path);
    const authStore = useAuthStore();
    let userStatus = false;
    // Secured Page
    if (authRequired) {
        // No user go home. Preserve where we came from    
        if (!authStore.user) {
            // log("Root GROOT");
            authStore.returnUrl = to.fullPath;
            return '/';
        }
        if (to.name === 'UserList') {
            let allowed = process.env.UserListAccess;
            if (!allowed.includes(authStore.user.Id)) {
                return '/';
            }

        }
    }
    manageMeta(to);
});

router.afterEach(async (to, from) => {
    UIkit.offcanvas("#offcanvasNav").hide();
});

function manageMeta(to) {
    // Standard Meta
    _.forEach(to.meta, function (v, k) {
        // console.log("manageMeta",k);
        // console.log("manageMeta",v);
        if (k === 'tags') {
            addMetaTag(v)
        } else {
            document[k] = v
        }
    });
}

function addMetaTag(tags) {
    // Property Tags
    // console.log("addMetaTag", tags);
    _.forEach(tags, function (v, k) {
        // console.log("manageMetaTags",k);
        // console.log("manageMetaTags",v.content);
        let meta = document.createElement('meta');
        meta.name = v.name;
        meta.content = v.content;
        // console.log("metaTag",meta);
        document.head.appendChild(meta);
    });
}