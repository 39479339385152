<script setup>
import ManageFiles from "./Components/ManageFiles.vue"
</script>
<script type="module">
export default {
  data() {
    return {
      force: false,
      TheName: "TheManageFiles"
    };
  },
  provide: function () {
    return {
      addToProject: this.addToProject
    }
  },
  components: {
    "manage-files-app": ManageFiles,
  },
  mounted() {
    // console.log("ManageFilesApp is mounted");
  },
  methods: {
    addtoProject() {
      return false;
    }
  }
};
</script>
<template>
      <manage-files-app v-bind:caller="TheName" is="vue:manage-files-app"></manage-files-app>
</template>
