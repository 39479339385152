<script setup>
  import Apply from "./Components/Apply.vue"
</script>
<script type="module">
export default {
    data() {
        return {
            force: false,
            TheName: "TheApply"
        };
    },
    provide: function () {
    return {
        submitForm: this.submitForm
    }
  },
    components: {
        "apply-app": Apply,
    },
    mounted() {
        console.log("ApplyApp is mounted");
    },
    methods: {
        submitForm() {
            return false;
        }
    }
};
</script>
<template>
        <apply-app v-bind:caller="TheName" is="vue:apply-app"></apply-app>
</template>