﻿<script>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import {utilsMixin} from '../helpers/utils';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import LoadingAnimation from "./Components/LoadingAnimation.vue";
import uikit from 'uikit';

export default {
  mixins: [utilsMixin],
  data() {
    return {
      franchise: process.env.Franchise,
      // form
      isError: false,
      isLoading: false,
      loadingCount: 0,
      ConfirmConform: false,
      ValidForm: false,
      minCharge: false,
      minChargeAmount: 0,
      Checkout: {},
      // address
      addressList: {},
      addressInfo: {},
      cartItems: [],
      countryList: [],
      monthList: [],
      paymentsList: {},
      serviceItems: [],
      stateList: [],
      yearList: [],
      // payment
      paymentInfo: {},
      currentPayment: "",
      currentAddress: "",
      savedPayment: false,
      termsPayment: false,
      savedAddress: false,
      showBillingMessage: false,
      billingMessage: "", //used for errors with billing or shipping fields
      showCartMessage: false,
      cartMessage: "", //I use this for second card, can expand on that
      showError: false,
      errorMessage: "",
      requiredPropsCC: [
        "Name", "CardNo", "CVV", "YearExpire", "MonthExpire", "Address", "City", "State", "Zip", "Country"
      ],
      requiredPropsCC2: [
        "SecondCreditCardName", "SecondCreditCardNumber", "SecondCreditCardCVV", "SecondCreditCardExpireYear", "SecondCreditCardExpireMonth", "SecondCreditCardAddress", "SecondCreditCardCity", "SecondCreditCardState", "SecondCreditCardZip"
      ],
      requiredPropsAD: [
        "FirstName", "LastName", "Address1", "City", "State", "Zip", "Country", "PhoneNumber", "Residential"
      ],
      // shipping
      shippingWait: false,
      BillingIsShipping: false,
      cantDoFreight: false,
      showShippingMessage: true,
      showShipping: false,
      shippingMessage: "A Shipping Address is required to get rates.",
      isShippingError: true,
      defaultShippingRates: [{
        "Method": "",
        "DisplayText": "Choose Shipping Method.",
        "FreightAmount": false,
        "TaxAmount": false
      },
      ],
      shippingRates: {},
      filteredShippingRates: [],
      ownShipping: false,
      shippingAccount: "",
      companyShipping: "",
      BlindShipping: false,
      PreserveFilenames: false,
      AlwaysShowFileNames: false,
      Shipping: "",
      TaxAmount: "",
      FreightAmount: "",
      ShippingMethod: "",
      showSecondCard: false,
      passCustomerFileName: false,
      OrderNotes: "",
      baseUrl: process.env.APIURL
    };
  },
  components: {
    "LoadingAnimation": LoadingAnimation
  },
  mounted() {
    log("CheckoutApp is mounted");
    UIkit.use(Icons);
    this.isLoading = true;
    this.getAccount();
    this.getAddresses();
    this.getPayments();
    this.getStateList();
    this.getCountryList();
    this.getMonthList();
    this.getYearList();
    this.getServiceItems();
    this.getCartItems();
    this.shippingRates = this.defaultShippingRates;
  },
  computed: {
    fileSpecs() {
      return this.getFileSpecs();
    },
    cartProducts() {
      // log("cartProducts");
      let products = this.cartItems.products;
      let minCharge = null;
      let fixed = {};
      // fix 90OC nonsense for now
      // FRN need to move this to the end and populate it so it does not break.
      // FRN probably a computed row at the end of the table.
      _.forEach(products, function (item, k) {
        if (item.ItemNo === "90OC") {
          //item.MaterialText = "$150 Minimum Purchase Charge";
          minCharge = k;
        }
      });
      if (minCharge) {
        let drop = products.splice(minCharge, 1);
        this.minCharge = true;
        this.minChargeAmount = drop[0].Price;
      }
      if (this.minCharge === true) {
        this.minCharge = this.isFranchiseEnabled('portalPricing');
      }
      // log(products);
      return products;
    },
    cartServices() {
      //log("cartServices");
      const services = this.cartItems.services;
      return services;
    },
    formReady() {
      let returnValue = false;
      if (this.ValidForm && this.ConfirmConform) {
        returnValue = true;
      }
      return returnValue;
    },
    // showPrice() {
    //   return this.showPortalPrice();
    // },
    totalOrder() {
      let returnValue = 0;
      let products = 0;
      let services = 0;
      let shipping = 0;
      let minCharge = this.minChargeAmount;
      _.forEach(this.cartItems.products, function (item) {
        products = products + item.Price;
      });
      _.forEach(this.cartItems.services, function (item) {
        services = services + item.Price;
      });
      // log("products", products);
      if (this.FreightAmount > 0) {
        console.log("doing freight and tax calculation");
        let f = parseFloat(this.FreightAmount);
        let t = parseFloat(this.TaxAmount);
        shipping = f + t;
      }
      returnValue = products + services + shipping + minCharge;
      return returnValue;
      //return returnValue;
    },
    showBilling() {
      let returnValue = true;
      if (this.termsPayment && this.isTermsOnly) {
        returnValue = false;
      }
      return returnValue;
    },
  },
  methods: {
    prestageTerms() {
      // When Franchise is termsOnly, preopulate the payment field with Terms option.
      if (this.isTermsOnly) {
        this.termsPayment = true;
        // log("PaymentsList: ", this.paymentsList);
        for (let i = 0; i < this.paymentsList.length; i++) {
          if (this.paymentsList[i].CCkey === "open account") {
            this.currentPayment = this.paymentsList[i].CCkey;
          }
        }
      } else {
        // Allow adding payment method when not terms only.
        this.paymentsList.unshift({ "CCkey": "false", "CardNo": "Add Payment Method" });
        // Do not default to terms when other payment options are available.
        this.currentPayment = "false";
      }
    },
    addressType(residential) {
      let returnValue = "Business";
      if (residential) {
        returnValue = "Residence";
      }
      return returnValue;
    },
    checkBilling() {
      let _this = this;

      if (_.isEmpty(_this.paymentInfo)) {
        return "Payment Info is required.";
      } else {
        let tempMsg = '';
        let header = "Billing: ";

        _this.requiredPropsCC.forEach(prop => {
          if (_this.paymentInfo.hasOwnProperty(prop)) {
            if (_this.paymentInfo[prop] === "") {
              tempMsg += header + prop + " is required.<br>";
            }
          }
          if (!_this.paymentInfo.hasOwnProperty(prop)) {
            tempMsg += header + prop + " is required.<br>";
          }
        });
        return tempMsg;
      }
    },
    checkShippingAddress() {
      let _this = this;
      if (!_this.BillingIsShipping) {
        log("Billing is not Shipping");
        // Validate address info
        if (_.isEmpty(_this.addressInfo)) {
          // log("no addressInfo");
          _this.ValidForm = false;
          return "A Shipping Address is required.";
        } else {
          // log("checking address info valid form is true", this.addressInfo);
          // We can get shipiping rates because we have a shipping address.
          let tempMsg = '';
          let header = "Shipping: ";
          _this.requiredPropsAD.forEach(prop => {
            // log("checking ", prop);
            if (_this.paymentInfo.hasOwnProperty(prop)) {
              if (_this.addressInfo[prop] === "") {
                // log("prop found but missing value");
                tempMsg += header + prop + " is required.<br>";
              } else {
                // log("prop found, value: ", _this.addressInfo[prop]);
              }
            }
            if (!_this.addressInfo.hasOwnProperty(prop)) {
              // log("missing ", prop);
              tempMsg += header + prop + " is required.<br>";
            }
          });
          if (tempMsg == '') {
            _this.ValidForm = true;
          }
          return tempMsg;
        }
      } else {
        _this.ValidForm = true;
        // Sorry we need a way to ensure Residential is picked
        if (!_this.addressInfo.hasOwnProperty("Residential")) {
          return "You must select an Address Type."
        }
      }
    },
    checkShippingMethod() {
      let _this = this;
      // console.log("Shipping: ", _this.Shipping);
      if (!this.Shipping) {
        return "A shipping method is required.";
      }
      return '';
    },
    checkSecondCC() {
      let _this = this;
      let tempMsg = '';
      let header = "Second Card: ";
      // FRN  why do you we let users enter both values?  Should calc second amount based on price - first card amount.  
      if (_this.showSecondCard === true) {
        if (parseFloat(this.paymentInfo.SecondCardAmount) + parseFloat(this.paymentInfo.FirstCardAmount) !== parseFloat(this.totalOrder)) {
          tempMsg += this.billingMessage = "Please make sure both card amounts match the order total <br>";
        }
        _this.requiredPropsCC2.forEach(prop => {
          if (_this.paymentInfo.hasOwnProperty(prop)) {
            if (_this.addressInfo[prop] === "") {
              tempMsg += header + prop + " is required.<br>";
            }
          }
          if (!_this.addressInfo.hasOwnProperty(prop)) {
            tempMsg += header + prop + " is required.<br>";
          }
        });
      }
      return tempMsg;
    },
    checkConform() {
      if (this.ConfirmConform !== true) {
        return "You must confirm you have read and agree to terms and conditions.";
      }
      return '';
    },
    constructBillingMessage(billingMsg, shipMsg, shipMethodMsg, secondCCMsg, conformMsg) {
      let _this = this;
      let returnValue = "";
      if (billingMsg) {
        returnValue = billingMsg;
      }
      if (shipMsg) {
        if (returnValue) returnValue += "<br>";
        returnValue += shipMsg;
      }
      if (shipMethodMsg) {
        if (returnValue) returnValue += "<br>";
        returnValue += shipMethodMsg;
      }
      if (secondCCMsg) {
        if (returnValue) returnValue += "<br>";
        returnValue += secondCCMsg;
      }
      if (conformMsg) {
        if (returnValue) returnValue += "<br>";
        returnValue += conformMsg;
      }
      return returnValue;
    },
    notFreight(rate) {
      if (this.cantDoFreight && rate.Method === "FEDEX_FREIGHT_PRIORITY") {
        return false;
      }
      return true;
    },
    checkForm: _.debounce(function (requester) {
      log("checkForm");
      let _this = this;
      _this.ValidForm = false;
      // reset
      // _this.isError = false;
      // _this.billingMessage = "";
      // _this.showBillingMessage = false;
      // _this.ValidForm = true;
      // Validate payment info
      let billingCheckMessage = _this.checkBilling();
      // Check shipping address
      let shippingCheckMessage = _this.checkShippingAddress();
      // Ok, we can get shipping rates now.
      // log("ValidForm", _this.ValidForm);
      if (_this.ValidForm === true && requester !== "ConfirmConform" && requester !== "Shipping" && requester !== "purchase") {
        this.showShipping = true;
        this.getShippingRates();
      }
      // else {
      //   log("Get Shipping skipped because the requester is: ", requester);
      // }
      // check if shipping method has been selected
      let shippingMethodMessage = _this.checkShippingMethod();
      //has second card
      //ML 4-14-23: Moved below GetShippingRates() because form will not be valid on initial load of the second card div
      let secondCCMsg = _this.checkSecondCC();
      let conformMessage = _this.checkConform();
      _this.billingMessage = _this.constructBillingMessage(billingCheckMessage, shippingCheckMessage, shippingMethodMessage, secondCCMsg, conformMessage);

      if (!_this.billingMessage) {
        _this.showBillingMessage = false;
        _this.isError = false;
        _this.ValidForm = true;
      } else {
        _this.showBillingMessage = true;
        _this.isError = true;
        _this.ValidForm = false;
      }

    }, 750),
    async getAccount() {
      const url = this.baseUrl + "/Account/GetUserAccount";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let account = await response.json();
      // log("fetchUserDetails: ", account);
      if (account.Status === "ok") {
        this.passCustomerFileName = account.Data.passCustomerFileName;
      }
      if (account.Status === "exception") {
        log("Could not retrieve account:", account.Message);
      }
    },
    async getBillingInfo() {
      // get billing info and link data to billing fields
      const url = this.baseUrl + "/Account/GetUserAccount";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let account = await response.json();
      // log("fetchUserDetails: ", account);
      if (account.Status === "ok") {
        this.paymentInfo.Address = account.Data.Address;
        this.paymentInfo.City = account.Data.City;
        this.paymentInfo.State = account.Data.State;
        this.paymentInfo.Zip = account.Data.Zip;
        this.paymentInfo.Country = account.Data.Country;
        this.paymentInfo.Phone = account.Data.PhoneNumber;
      }
      if (account.Status === "exception") {
        log("Could not retrieve account:", account.Message);
      }
    },
    getShippingRates() {
      log("getShippingRates");
      // log("Residential is reading as ", typeof this.addressInfo.Residential)
      this.shippingWait = true;
      let doSubmit = false;
      let d = {};
      const { user } = useAuthStore();
      const userId = user.Id;
      // Billing and Shipping the same populate.
      if (!_.isEmpty(this.paymentInfo)) {
        if (this.BillingIsShipping) {
          d = {
            City: this.paymentInfo.City,
            State: this.paymentInfo.State,
            Zip: this.paymentInfo.Zip,
            Country: this.paymentInfo.Country,
            Residential: this.addressInfo.Residential,
            County: "",
          };
        }
        doSubmit = true;
      }
      // Shipping is unique populate if values
      if (!this.BillingIsShipping) {
        if (!_.isEmpty(this.addressInfo)) {
          // log("setting d from addressInfo");
          d = {
            City: this.addressInfo.City,
            State: this.addressInfo.State,
            Zip: this.addressInfo.Zip,
            Country: this.addressInfo.Country,
            Residential: this.addressInfo.Residential,
            County: "",
          };
          doSubmit = true;
        }
      }
      if (!doSubmit) {
        this.shippingMessage = "Please check Shipping Address is complete and accurate."
        this.isShippingError = true;
        this.showShippingMessage = true;
      }
      // doSubmit = true;
      // log("getShippingRates,doSubmit: " + doSubmit);
      if (doSubmit) {
        if (d["Zip"].length < 5) {
          doSubmit = false;
        }
        if (d["Country"] === "") {
          doSubmit = false;
        }
      }
      if (doSubmit) {
        this.showShippingMessage = false;
        this.shippingMessage = "";
        this.fetchShippingRates(d);
      }
    },
    fetchShippingRates: _.debounce(function (address) {
      // log("fetchShippingRates", address);
      this.shippingWait = true;
      // Reset the shipping selection since we are getting rates again.
      this.Shipping = "";
      const url = this.baseUrl + "/Store/GetShippingRates";
      const { user } = useAuthStore();
      const userId = user.Id;
      address.userId = userId;
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(address)
      }).then(response => {
        // log("shipping response", response);
        return response.json();
      }).then(jsondata => {
        let shippingRates = jsondata;
        // log("shipping json", jsondata);
        if (shippingRates.Status === "ok") {
          this.shippingWait = false;
          this.calcShipping(shippingRates.Data);
          this.filteredShippingRates = shippingRates.Data;
          this.filterShippingRates();
          this.isShippingError = false;
          this.showShippingMessage = false;
        }
        if (shippingRates.Status === "invalid") {
          this.shippingWait = false;
          this.isShippingError = true;
          this.showShippingMessage = true;
          this.shippingMessage = shippingRates.Message;
        }
        if (shippingRates.Status === "exception") {
          this.shippingWait = false;
          this.shippingRates = this.defaultShippingRates;
          this.shippingMessage = shippingRates.Message;
          this.showShippingMessage = true;
          this.isShippingError = true;
        }
      });
    }, 750),
    calcShipping(shippingRates) {
      // log("calcShipping");
      let sortedData = _.orderBy(shippingRates, ["FreightAmount"], ["asc"]);
      let c = {
        "Method": "",
        "DisplayText": "Choose Shipping Method",
        "FreightAmount": 0,
        "TaxAmount": 0
      };
      _.forEach(sortedData, function (item) {
        item["DisplayText"] = item.DisplayText + " - $" + item.FreightAmount.toFixed(2);
      });
      sortedData.unshift(c);
      // log("Shipping sortedData", sortedData);
      this.shippingRates = sortedData;
    },
    getAddresses() {
      // log("getAddresses");
      const url = this.baseUrl + "/Account/GetShippingAddresses";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        // log("getAddresses response ", jsondata);
        this.addressList = jsondata;
        this.addressList.unshift({ "ShipKey": "AddAddress", "Address1": "Add New Address" })
        this.currentAddress = "AddAddress";
        this.loadingCount++;
      });
    },
    getCountryList() {
      //log("getCountryList");
      const url = this.baseUrl + "/Account/GetCountryList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.countryList = jsondata;
        this.loadingCount++;
      });
    },
    getPaymentNames(name) {
      let returnValue = "";
      let nameSplit = this.paymentInfo.Name.split(" ");
      if (name === "first") {
        returnValue = nameSplit[0];
      }
      if (name === "last") {
        returnValue = (nameSplit.length > 1) ? nameSplit[1] : "";
      }
      return returnValue;
    },
    getPayments() {
      log("getPayments");
      const url = this.baseUrl + "/Account/GetPaymentOptions";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.paymentsList = jsondata;
        // Mask the credit card number;
        let _mask = this.maskCreditCard;
        _.forEach(this.paymentsList, function (p) {
          p.CardNo = _mask(p.CardNo);
        });
        this.prestageTerms();
        // this.currentPayment = "false";
        this.loadingCount++;
      });
    },
    getStateList() {
      //log("getStateList");
      const url = this.baseUrl + "/Account/GetStateList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.stateList = jsondata;
        this.loadingCount++;
        //log("stateList", this.stateList);
      });
    },
    getMonthList() {
      // log("getMonthList");
      const url = this.baseUrl + "/Account/GetMonthList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.monthList = jsondata;
        this.loadingCount++;
        // log("monthList", this.monthList);
      });
    },
    getYearList() {
      //log("getYearList");
      const url = this.baseUrl + "/Account/GetYearList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.yearList = jsondata;
        this.loadingCount++;
        //log("yearList", this.yearList);
      });
    },
    async getCartItems() {
      //log("getCartItems");
      this.isLoading = true;
      const url = this.baseUrl + "/Store/GetCart";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let returnedData = await response.json();
      // log("CART returned: ", returnedData);
      if (returnedData.Status === "ok") {
        this.cartItems = returnedData.Data;
        this.loadingCount++;
        this.isLoading = false;
        // log("cartItems", this.cartItems);
      }

      if (returnedData.Status === "nodata") {
        this.isLoading = false;
        this.showCartMessage = true;
        this.cartMessage = "<h3 class='uk-h1'>" + returnedData.Message + "</h3>"
      }

      if (returnedData.Status === "exception") {
        this.isLoading = false;
        this.showCartMessage = true;
        this.isError = true;
        this.cartMessage = "<p>" + returnedData.Message + "</p>"
      }
    },
    async getServiceItems() {
      //log("getServiceItems");
      const url = this.baseUrl + "/Store/GetServiceItems";
      let response = await fetch(url, {
        method: "GET",
        headers: authHeader(url),
      });
      let returnedData = await response.json();
      //log("SERVICE returned: ", returnedData);
      if (returnedData.Status === "ok") {
        this.serviceItems = returnedData.Data;
        this.loadingCount++;
      }
      if (returnedData.Status === "exception") {
        this.showCartMessage = true;
        this.isError = true;
        this.cartMessage = "<p>" + returnedData.Message + "</p>"
      }
    },
    maskCreditCard(cardNo) {
      return "**** **** **** " + cardNo;
    },
    ShowFinish(item) {
      //log("ShowFinish", item);
      let returnValue = false;
      if (item.FinishText) {
        if (item.FinishText.length > 0) {
          returnValue = true;
        }
      }
      if (item.Filename === "PantoneChart") {
        returnValue = true;
      }
      return returnValue;
    },
    ShowFilename(item) {
      let returnValue = false;
      if (item.ItemNo === "TS-SVC-1") {
        returnValue = true;
      }
      return returnValue;
    },
    ShowContourCut(item) {
      let returnValue = false;
      if (item.ItemNo === "TS-WINDOWFILM") {
        returnValue = false;
      }
      if (item.product_type === "adhesive") {
        returnValue = true;
      }
      return returnValue;
    },
    ShowRigid(item) {
      let returnValue = false;
      if (item.product_type === "rigid") {
        returnValue = true;
      }
      return returnValue;
    },
    ShowSize(item) {
      let returnValue = true;
      if (item.ItemNo === "90OC") {
        returnValue = false;
      }
      return returnValue;
    },
    ShowSquareFt(item) {
      let returnValue = ""
      if (item.SquareFeet > 0) {
        returnValue = "(" + item.SquareFeet + " Square Ft.)";
        if (item.product_type === "rigid") {
          returnValue = "(" + item.SquareFeet + " Sheets)";
        }
      }
      return returnValue;
    },
    ShowOverlap(item) {
      let returnValue = false;
      if (item.Overlap) {
        if (item.Overlap > 0) {
          returnValue = true;
        }
      }
      return returnValue;
    },
    FinishText(item) {
      let returnValue = item.FinishText;
      if (item.FinishText) {
        if (item.ItemNo === "TS-PMSC") {
          returnValue = item.LaminateType;
        }
      }
      if (item.Filename === "PantoneChart") {
        returnValue = item.LaminateType;
      }
      //log("FinishText return ", returnValue);
      return returnValue;
    },
    ContourCut(item) {
      let returnValue = "No contour cutting";
      if (item.ContourCutFile.length > 0) {
        // backward compat. No longer allow cut file uploads. Require them to be embedded.
        returnValue = "Has contour cut file: " + item.ContourCutFile;
        if (item.ContourCutFile === "embedded") {
          returnValue = "Has embedded cut file";
        }
      }
      //log("ContourCut return ", returnValue);
      return returnValue;
    },
    async makePurchase() {
      if (!this.shippingWait) {
        this.requester = "purchase";
        log("makePurchase");
        let doSubmit = false;
        this.checkForm("purchase");
        if (this.ValidForm) {
          doSubmit = true;
        }
        if (doSubmit) {
          const authStore = useAuthStore();
          const user = authStore.user;
          // log("currentPayment", this.currentPayment);
          // log("savedPayment", this.savedPayment);
          // log("paymentInfo", this.paymentInfo);
          this.Checkout = {
            userId: user.Id,
            savedAddress: this.currentAddress,
            savedCreditCard: this.savedPayment.toString(),
            CreditCardNumber: this.paymentInfo.CardNo,
            ExpireMonth: this.paymentInfo.MonthExpire,
            ExpireYear: this.paymentInfo.YearExpire,
            Cvv: this.paymentInfo.CVV,
            BillingFirstName: this.getPaymentNames("first"),
            BillingLastName: this.getPaymentNames("last"),
            BillingCompanyName: this.addressInfo.CompanyName,
            BillingAddress: this.paymentInfo.Address,
            BillingAddress2: this.paymentInfo.Address2,
            BillingCity: this.paymentInfo.City,
            BillingState: this.paymentInfo.State,
            BillingZip: this.paymentInfo.Zip,
            BillingCountry: this.paymentInfo.Country,
            BillingPhoneNumber: this.paymentInfo.Phone,
            ShippingFirstName: this.addressInfo.FirstName,
            ShippingLastName: this.addressInfo.LastName,
            ShippingCompanyName: this.addressInfo.CompanyName,
            ShippingAddress: this.addressInfo.Address1,
            ShippingAddress2: this.addressInfo.Address2,
            ShippingCity: this.addressInfo.City,
            ShippingState: this.addressInfo.State,
            ShippingZip: this.addressInfo.Zip,
            ShippingCountry: this.addressInfo.Country,
            ShippingPhoneNumber: this.addressInfo.PhoneNumber,
            BillingIsShipping: this.BillingIsShipping,
            ShippingEmailAddress: this.addressInfo.Email,
            ShippingAddressType: this.addressInfo.Residential,
            CCKey: this.paymentInfo.CCkey,
            ShipKey: this.addressInfo.ShipKey,
            CompanyShipping: this.CompanyShipping,
            ShippingAccount: this.ShippingAccount,
            OrderNotes: this.OrderNotes, //pass in errors to this
            BlindShipping: this.BlindShipping,
            cantdofreight: this.cantDoFreight,
            ShippingRates: this.FreightAmount.toString(), // FRN This is a String in Checkout
            ShippingMethod: this.ShippingMethod,
            TaxAmount: this.TaxAmount.toString(), // FRN This is a String in Checkout
            PreserveFilenames: this.PreserveFilenames,
            AlwaysShowFilenames: this.AlwaysShowFileNames,
            ConfirmConform: this.ConfirmConform,
          };
          if (this.showSecondCard) { //ML 2-9-23: Make sure this gets passed back
            this.Checkout["secondCardName"] = this.paymentInfo.SecondCreditCardName;
            this.Checkout["SecondCreditCardNumber"] = this.paymentInfo.SecondCreditCardNumber;
            this.Checkout["SecondExpireMonth"] = this.paymentInfo.SecondCreditCardExpireMonth;
            this.Checkout["SecondExpireYear"] = this.paymentInfo.SecondCreditCardExpireYear;
            this.Checkout["SecondBillingAddress"] = this.paymentInfo.SecondCreditCardAddress;
            this.Checkout["SecondBillingCity"] = this.paymentInfo.SecondCreditCardCity;
            this.Checkout["SecondBillingState"] = this.paymentInfo.SecondCreditCardState;
            this.Checkout["SecondBillingZip"] = this.paymentInfo.SecondCreditCardZip;
            this.Checkout["SecondCvv"] = this.paymentInfo.SecondCreditCardCVV;
            this.Checkout["SecondCardKey"] = this.paymentInfo.SecondCreditCardKey;
            this.Checkout["firstCardAmount"] = this.paymentInfo.FirstCardAmount;
            this.Checkout["secondCardAmount"] = this.paymentInfo.SecondCardAmount;
          }
          // log("checkout object", this.Checkout);
          let order = await this.SubmitOrder();
          //let order = null;
          // log("order is", order);
          if (order.Status === "ok") {
            // window.location.href = 'orderDetails/' + order.Data;
            const authStore = useAuthStore();
            const user = authStore.user;
            router.push({ name: 'orderDetails', params: { orderNumber: order.Data, userId: user.Id } });
          }
          if (order.Status === "exception") {
            this.errorMessage = order.Message;
            this.showError = true;
            this.isError = true;
            return;
          }
          if (order.Status === "invalid") {
            this.errorMessage = order.Message;
            this.showError = true;
            this.isError = true;
            return;
          }
          if (order.Status === "error") {
            this.errorMessage = order.Message;
            this.showError = true;
            this.isError = true;
            return;
          }
        } //doSubmit
      }
    },
    async SubmitOrder() {
      log("SubmitOrder");
      const url = this.baseUrl + "/Store/Checkout";
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(this.Checkout)
      });
      let order = await response.json();
      // log("SubmitOrder result: ", order);
      return order
    },
    checkTermsPayment() {
      // log("checkTermsPayment", this.paymentInfo);
      if (this.paymentInfo.CVV === "TERMS") {
        console.log("terms payment");
        this.savedPayment = false;
        this.termsPayment = true;
        this.getBillingInfo();

        // get customer billing address
      } else {
        this.savedPayment = true;
        this.termsPayment = false;
      }
    },
    filterShippingRates() {
      let filteredRates = [];
      this.shippingRates.forEach((rate) => {
        if (this.cantDoFreight === true && rate.Method === "FEDEX_FREIGHT_PRIORITY") {
          log("Freight found and skipped");
        } else {
          filteredRates.push(rate);
        }
      });
      filteredRates.sort((a, b) => {
        return a.FreightAmount - b.FreightAmount;
      });
      // log("Returned filtered rates: ", filteredRates);
      if (!this.isFranchiseEnabled('portalPricing')) {
        filteredRates = this.stripPricing(filteredRates);
      }
      this.filteredShippingRates = filteredRates;
    },
    stripPricing(rates) {
      // log("stripPricing before: ", rates);
      let cleanedRates = {};
      _.forEach(rates, function (item, k) {
        // console.log(item);
        item.DisplayText = item.DisplayText.replace(/ - \$\d+(\.\d{2})?/, '');
      });
      // console.log("stripPricing after: ", rates);
      return rates
    }
  },
  watch: {
    "paymentInfo.SecondCardAmount"(newVal, oldVal) {
      if (this.showSecondCard) {
        this.checkForm("secondCardAmount")
      }
    },
    "showSecondCard"(newVal, oldVal) {
      this.checkForm("showSecondCard")
    },
    "currentPayment"(newVal, oldVal) {
      // log("Current Payment Passed Value: ", newVal);
      if (newVal === "false") {
        this.paymentInfo = {};
        this.savedPayment = false;
      } else {
        this.paymentInfo = _.find(this.paymentsList, ["CCkey", newVal]);
        // log("currentPayment: ", this.paymentInfo);
        if (!_.isEmpty(this.paymentInfo)) {
          this.savedPayment = true;
          this.checkTermsPayment();
          this.checkForm("currentPayment");
        }
      }
    },
    "currentAddress"(newVal, oldVal) {
      // log("currentAddress");
      if (newVal === "AddAddress") {
        this.addressInfo = {};
        this.savedAddress = false;
      } else {
        this.addressInfo = _.find(this.addressList, ["ShipKey", newVal]);
        // log("currentAddress:", this.addressInfo);
        if (!_.isEmpty(this.addressInfo)) {
          this.savedAddress = true;
          this.checkForm("currentAddress");
        }
      }
    },
    "BillingIsShipping"(newVal, oldVal) {
      // log("BillingIsShipping", newVal, oldVal);
      this.checkForm("BillingIsShipping");
    },
    "loadingCount"(newVal, oldVal) {
      //log("loadingCount", this.loadingCount);
      if (this.loadingCount === 8) {
        this.isLoading = false;
      }
    },
    "Shipping"(newVal, oldVal) {
      // log("Watching Shipping: ", newVal);
      let current = _.find(this.shippingRates, ["Method", newVal]);
      // log("watch Shipping", current);
      this.TaxAmount = current.TaxAmount;
      this.FreightAmount = current.FreightAmount;
      this.ShippingMethod = current.Method;
      this.checkForm("Shipping");

    },
    "cantDoFreight"(newVal, oldVal) {
      this.filterShippingRates();
    },
    "ShippingMethod"(newVal, oldVal) {
      if (this.ShippingMethod === "BST") {
        this.ownShipping = true;
      } else {
        this.ownShipping = false;
      }
    },
    "paymentInfo": {
      deep: true,
      handler() {
        this.checkForm("paymentInfo");
      },
    },
    "addressInfo": {
      handler() {
        this.checkForm("addressInfo");
      },
      deep: true,
    },
    "ConfirmConform"(newVal, oldVal) {
      this.ConfirmConform = newVal;
      this.checkForm("ConfirmConform");
    },
  },
};
</script>

<template>
  <LoadingAnimation v-if="this.isLoading"></LoadingAnimation>

  <div class="uk-container uk-container-xlarge uk-align-center uk-flex" v-if="!isLoading" uk-grid>
    <div class="uk-width-2-3@m">
      <section class="uk-section uk-card uk-card-default uk-card-body uk-position-relative">
        <h2 class="uk-card-title">Billing Details</h2>
        <hr/>
        <!-- <div v-if="showBillingMessage"
             v-bind:class="{ 'uk-alert-warning': isError, 'uk-alert-success': !isError }" class="uk-alert"
             v-html="billingMessage">
        </div> -->
        <div class="billing-message-container uk-margin-bottom" uk-accordion uk-fixed>
          <li class="uk-alert uk-padding-remove"
              :class="billingMessage ? 'uk-background-primary uk-light uk-text-bold' : 'uk-alert-success'">

            <a v-if="billingMessage" class="uk-accordion-title uk-margin-remove uk-padding-small" href="#">Missing
              Requirements</a>
            <p v-else class="uk-margin-remove uk-padding-small">Ready to Order</p>
            <div class="uk-accordion-content uk-alert-danger uk-padding-small uk-margin-remove-top"
                 v-html="billingMessage"></div>
          </li>
        </div>
        <section id="BillingDetails" uk-grid>
          <div class="uk-width-1-1">
            <label for="savedCreditCard" class="uk-form-label">Saved Payment Method</label>
            <div class="uk-form-controls">
              <select v-model="currentPayment" name="savedCreditCard" id="savedCreditCard"
                      class="uk-select">
                <option v-for="(py, key) in paymentsList" v-bind:value="py.CCkey">{{ py.CardNo }}
                </option>
              </select>
            </div>
          </div>
          <input type="hidden" name="CCkey" id="CCkey" v-model.trim="paymentInfo.CCkey" placeholder=""
                 class="uk-input"/>
          <!-- hide if terms start -->
          <div v-if="showBilling" class="uk-width-1-2">
            <!--            FRN this should become First and Last-->
            <label v-if="paymentInfo.Name" for="Name" class="uk-form-label">Full Name</label>
            <div class="uk-form-controls">
              <input type="text" name="Name" id="Name" v-model.trim="paymentInfo.Name"
                     placeholder="Full Name (on card)" v-bind:readonly="savedPayment || termsPayment "
                     class="uk-input"/>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-1">
            <label v-if="paymentInfo.CardNo" for="CardNo" class="uk-form-label">
              Card Number
              <span v-if="savedPayment">
                                <em> You must <a href="/Account/PaymentOptions">Update Payments </a> to make changes to
                                    a saved card.</em>
                            </span>
            </label>
            <div class="uk-form-controls">
              <input type="text" name="CardNo" id="CardNo" v-model.trim="paymentInfo.CardNo"
                     placeholder="Card Number" v-bind:readonly="savedPayment || termsPayment" class="uk-input"/>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-3">
            <label for="monthExpire" class="uk-form-label">Expire Month</label>
            <div class="uk-form-controls">
              <input v-model="paymentInfo.MonthExpire" type="text" name="monthExpire" id="monthExpire"
                     v-bind:class="{ 'uk-hidden': !savedPayment }" class="uk-input"
                     v-bind:disabled="!savedPayment" readonly/>
              <select v-model="paymentInfo.MonthExpire" name="monthExpire"
                      v-bind:class="{ 'uk-hidden': savedPayment }" class="uk-select"
                      v-bind:disabled="savedPayment  || termsPayment">
                <option v-for="(mo, key) in monthList" v-bind:value="mo.key">{{ mo.value }}</option>
              </select>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-3">
            <label for="yearExpire" class="uk-form-label">Expire Year</label>
            <input v-model="paymentInfo.YearExpire" type="text" name="yearExpire" id="yearExpire"
                   v-bind:class="{ 'uk-hidden': !savedPayment }" class="uk-input"
                   v-bind:disabled="!savedPayment" readonly/>
            <div class="uk-form-controls">
              <select v-model="paymentInfo.YearExpire" name="yearExpire"
                      v-bind:class="{ 'uk-hidden': savedPayment }" class="uk-select"
                      v-bind:disabled="savedPayment || termsPayment">
                <option v-for="(yr, key) in yearList" v-bind:value="yr.key">{{ yr.value }}</option>
              </select>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-3">
            <label for="CVV" class="uk-form-label">CVV</label>
            <div class="uk-form-controls">
              <input type="text" name="CVV" id="CVV" v-model.trim="paymentInfo.CVV" placeholder="CVV"
                     class="uk-input" v-bind:disabled="savedPayment || termsPayment"/>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-1">
            <label v-if="paymentInfo.Address" for="address" class="uk-form-label">Address</label>
            <div class="uk-form-controls">
              <input type="text" name="address" id="address" v-model.trim="paymentInfo.Address"
                     placeholder="Address Line 1" v-bind:readonly="savedPayment" class="uk-input"/>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-1">
            <label v-if="paymentInfo.Address2" for="address2" class="uk-form-label">Address Line 2</label>
            <div class="uk-form-controls">
              <input type="text" name="address2" id="address2" v-model.trim="paymentInfo.Address2"
                     placeholder="Address Line 2" v-bind:readonly="savedPayment" class="uk-input"/>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-2-5">
            <label for="city" class="uk-form-label">City</label>
            <div class="uk-form-controls">
              <input type="text" name="city" id="city" v-model.trim="paymentInfo.City" placeholder="City"
                     v-bind:readonly="savedPayment" class="uk-input"/>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-5">
            <label for="State" class="uk-form-label">State</label>
            <div class="uk-form-controls">
              <input v-model="paymentInfo.State" type="text" name="State" id="State"
                     v-bind:class="{ 'uk-hidden': !savedPayment }" class="uk-input"
                     v-bind:disabled="!savedPayment" readonly/>
              <select v-model="paymentInfo.State" class="uk-select" id="billingState"
                      v-bind:class="{ 'uk-hidden': savedPayment }" v-bind:disabled="savedPayment">
                <option v-for="(st, key) in stateList" v-bind:value="st.key">{{ st.value }}</option>
              </select>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-5">
            <label for="zipCode" class="uk-form-label">Postal Code</label>
            <div class="uk-form-controls">
              <input type="text" name="zipCode" id="zipCode" v-model.trim="paymentInfo.Zip"
                     placeholder="Postal Code" v-bind:readonly="savedPayment" class="uk-input"/>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-5">
            <label for="Country" class="uk-form-label">Country</label>
            <div class="uk-form-controls">
              <input v-model="paymentInfo.Country" type="text" name="Country" id="Country"
                     v-bind:class="{ 'uk-hidden': !savedPayment }" class="uk-input"
                     v-bind:disabled="!savedPayment" readonly/>
              <select v-model="paymentInfo.Country" v-bind:class="{ 'uk-hidden': savedPayment }"
                      class="uk-select" v-bind:disabled="savedPayment" id="billingCountry">
                <option v-for="(co, key) in countryList" v-bind:value="co.key">{{ co.value }}</option>
              </select>
            </div>
          </div>
          <div v-if="showBilling" class="uk-width-1-1">
            <label v-if="paymentInfo.Phone" for="phoneNumber" class="uk-form-label">Phone Number</label>
            <div class="uk-form-controls">
              <input type="text" name="phoneNumber" id="phoneNumber" v-model.trim="paymentInfo.Phone"
                     placeholder="Phone Number" v-bind:readonly="savedPayment" class="uk-input"/>
            </div>
          </div>
        </section> <!--The Form Billing Section-->
        <section id="ShippingDetails" uk-grid>
          <!--The Shipping Address Section-->
          <h3 class="uk-h4">Shipping Details</h3>
          <div v-if="!termsPayment && checkBilling().length == 0" class="uk-width-1-1">
            <label for="BillingIsShipping" class="uk-form-label">
              <input type="checkbox" class="uk-checkbox" id="BillingIsShipping"
                     v-model="BillingIsShipping" name="BillingIsShipping"> Billing Address Same as
              Shipping
            </label>
          </div>
          <div v-if="!BillingIsShipping" class="uk-width-1-1">
            <label for="savedAddress" class="uk-form-label">Saved Shipping Addresses</label>
            <div class="uk-form-controls">
              <select v-model="currentAddress" name="savedAddress" id="savedAddress" class="uk-select">
                <option v-for="(ad, key) in addressList" v-bind:value="ad.ShipKey">{{ ad.Address1 }}
                </option>
              </select>
            </div>
          </div>
          <div class="uk-margin-small uk-width-1-1">
            <label for="residence" class="uk-form-label">Address Type</label>
            <div class="uk-form-controls">
              <select v-model="addressInfo.Residential" name="residence" id="residence" class="uk-select">
                <option v-bind:value=false>Business</option>
                <option v-bind:value=true>Residence</option>
              </select>
            </div>
            <input type="hidden" name="shipKey" v-model.trim="addressInfo.ShipKey" placeholder=""
                   class="uk-input"/>
          </div>

          <div v-if="!BillingIsShipping" class="uk-width-1-1" uk-grid>
            <div class="uk-margin-small uk-margin-small-top uk-width-1-2">
              <label v-if="addressInfo.FirstName" for="shippingFirstName" class="uk-form-label">First
                Name</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingFirstName" id="shippingFirstName"
                       v-model.trim="addressInfo.FirstName" placeholder="First Name" class="uk-input"/>
              </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
              <label v-if="addressInfo.LastName" for="shippingLastName" class="uk-form-label">Last
                Name</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingLastName" id="shippingLastName"
                       v-model.trim="addressInfo.LastName" placeholder="Last Name" class="uk-input"/>
              </div>
            </div>

            <div class="uk-margin-small uk-width-1-1">
              <label v-if="addressInfo.CompanyName" for="shippingCompanyName"
                     class="uk-form-label">Company</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingCompanyName" id="shippingCompanyName"
                       v-model.trim="addressInfo.CompanyName" placeholder="Company" class="uk-input"/>
              </div>
            </div>
            <div class="uk-margin-small uk-width-1-1">
              <label v-if="addressInfo.Address1" for="shippingAddress1"
                     class="uk-form-label">Address</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingAddress1" id="shippingAddress1"
                       v-model.trim="addressInfo.Address1" placeholder="Address Line 1"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-margin-small uk-width-1-1">
              <label v-if="addressInfo.Address2" for="shippingAddress2" class="uk-form-label">Address Line
                2</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingAddress2" id="shippingAddress2"
                       v-model.trim="addressInfo.Address2" placeholder="Address Line 2"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-margin-small uk-width-2-5">
              <label for="shippingCity" class="uk-form-label">City</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingCity" id="shippingCity" v-model.trim="addressInfo.City"
                       placeholder="City" class="uk-input"/>
              </div>
            </div>
            <div class="uk-margin-small uk-width-1-5">
              <label for="shippingState" class="uk-form-label">State</label>
              <div class="uk-form-controls">
                <select v-model="addressInfo.State" name="shippingState" id="shippingState"
                        class="uk-select">
                  <option v-for="(st, key) in stateList" v-bind:value="st.key">{{ st.value }}</option>
                </select>
              </div>
            </div>
            <div class="uk-margin-small uk-width-1-5">
              <label for="shippingZip" class="uk-form-label">Postal Code</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingZip" id="shippingZip" v-model.trim="addressInfo.Zip"
                       placeholder="Postal Code" class="uk-input"/>
              </div>
            </div>
            <div class="uk-margin-small uk-width-1-5">
              <label for="shippingCountry" class="uk-form-label">Country</label>
              <div class="uk-form-controls">
                <select v-model="addressInfo.Country" name="shippingCountry" id="shippingCountry"
                        class="uk-select">
                  <option v-for="(co, key) in countryList" v-bind:value="co.key">{{ co.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="uk-margin-small uk-width-1-1">
              <label v-if="addressInfo.PhoneNumber" for="shippingPhoneNumber" class="uk-form-label">Phone
                Number</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingPhoneNumber" id="shippingPhoneNumber"
                       v-model.trim="addressInfo.PhoneNumber" placeholder="Phone Number"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-margin-small uk-width-1-1">
              <label v-if="addressInfo.Email" for="shippingEmail" class="uk-form-label">Shipping
                Email</label>
              <div class="uk-form-controls">
                <input type="text" name="shippingEmail" id="shippingEmail"
                       v-model.trim="addressInfo.Email" placeholder="Email Address" class="uk-input"/>
              </div>
            </div>
          </div>
        </section> <!--The Form Shipping Section-->
      </section> <!--The User Details Section-->
      <section v-if="isHolidayMessage" class="uk-text-center uk-margin-bottom">
        <div class="uk-card uk-card-primary">
          <div class="uk-card-body uk-text-bolder" style="color: #fff;" v-html="holidayMessage"></div>
        </div>
      </section>
    </div>
    <div class="uk-width-1-3@m">
      <section class="uk-card uk-card-secondary uk-card-body">
        <section id="cartItems">
          <h2 class="uk-card-title">Order Items</h2>
          <div v-if="showCartMessage"
               v-bind:class="{ 'uk-alert-warning': isError, 'uk-alert-success': !isError }"
               class="uk-alert uk-text-center" v-html="cartMessage"></div>
          <!--Products-->
          <div v-for="(c, k) in cartProducts" class="uk-margin">
            <p class="uk-text-break uk-h4 uk-margin-small">{{ c.MaterialText !== "" ? c.MaterialText : c.Filename }}</p>
            <p class="uk-text-break uk-margin-small uk-padding-remove uk-text-italic uk-text-bolder">{{
                c.Filename
              }}</p>
            <ul class="uk-list uk-list-collapse uk-margin-small uk-padding-remove">
              <li v-if="ShowSize(c)">{{ c.InchWidth }}"w by {{ c.InchHeight }}"h {{ ShowSquareFt(c) }}
              </li>
              <li v-if="ShowFinish(c)">Finish: {{ FinishText(c) }}</li>
              <li v-if="ShowContourCut(c)">{{ ContourCut(c) }}</li>
              <li v-if="ShowOverlap(c)">Overlap: {{ c.Overlap }} in</li>
              <li v-if="ShowRigid(c)">
                <ul class="uk-list uk-list-collapse">
                  <li>Print Sides: {{ c.sides }}</li>
                  <li v-if="c.round_corners > 0">Round Corners: {{ c.round_corners }}</li>
                  <li v-if="c.hole_placement">Drill Hole Placement: {{ c.hole_placement }}</li>
                  <li v-if="c.hole_edge_distance">Drill Hole Edge Distance: {{ c.hole_edge_distance }}
                  </li>
                  <li v-if="c.hole_hole_distance">Drill Hole to Hole Distance:
                    {{ c.hole_hole_distance }}
                  </li>
                  <li>
                    <span v-if="c.grommets">Item Has Grommets</span>
                  </li>
                </ul>
              </li>
              <li>
                <span class="uk-text-italic">{{ c.OrderNotes }}</span>
              </li>
            </ul>
            <div uk-grid>
              <div class="uk-width-1-2"><span class="uk-text-bolder">QTY: </span>{{ c.Quantity }}</div>
              <div v-if="showPrice" class="uk-width-1-2 uk-text-bolder">{{ $toCurrency(c.Price) }}</div>
            </div>
          </div>
          <!--Services-->
          <div v-for="(s, k) in cartServices">

            <div>
              <p v-if="s.ItemDesc" class="uk-h4 uk-margin-small  uk-margin-remove-bottom">{{ s.ItemDesc }}
              </p>
              <p v-if="s.MaterialText" class="uk-h4 uk-margin-small">{{ s.MaterialText }}</p>
              <p v-if="s.LaminateType" class="uk-margin-remove-bottom uk-margin-remove-top">
                <strong>Finish: </strong>{{ s.LaminateType }}
              </p>
              <p v-if="ShowFilename(s)" class="uk-text-break uk-margin-remove-bottom uk-margin-remove-top"><strong>For
                Filename: </strong>{{ s.Filename }}</p>
              <ul v-if="s.Colors.length > 0" class="uk-margin-remove-top uk-list uk-list-collapse">
                <li v-for="color in s.Colors">{{ color }}</li>
              </ul>
            </div>
            <div uk-grid>
              <div class="uk-width-1-2"><span class="uk-text-bolder">QTY: </span>{{ s.Quantity }}</div>
              <div class="uk-width-1-2 uk-text-bolder">{{ $toCurrency(s.Price) }}</div>
            </div>
          </div>

          <div v-if="minCharge" uk-grid>
            <div class="uk-width-1-2">
              <p class="uk-text-bolder">$150 Minimum Purchase Charge: </p>
            </div>
            <div class="uk-width-1-2 uk-text-bolder">{{ $toCurrency(minChargeAmount) }}</div>
          </div>
          <div v-if="showPrice" uk-grid>
            <div class="uk-width-1-2 uk-text-bolder uk-text-lead">Subtotal:</div>
            <div class="uk-width-1-2 uk-text-lead">{{ $toCurrency(totalOrder) }}</div>
          </div>
          <div class="uk-width-1-1">
            <div v-if="passCustomerFileName">
              <label for="AlwaysShow" class="uk-form-label">
                <input type="checkbox" class="uk-checkbox" id="AlwaysShow"
                       v-model="AlwaysShowFileNames" name="AlwaysShow"> Preserve my original filenames.
              </label>
            </div>
            <div v-if="!passCustomerFileName">
              <label for="PreserveFilenames" class="uk-form-label">
                <input type="checkbox" class="uk-checkbox" id="PreserveFilenames"
                       v-model="PreserveFilenames" name="PreserveFilenames"> Preserve my original
                filenames.
              </label>
            </div>
          </div>
        </section>
        <section id="shipping" class="uk-section">
          <div uk-grid>
            <div class="uk-width-1-1">
              <h4 class="uk-h4">Shipping:</h4>
              <div v-if="showShippingMessage" class="uk-alert"
                   v-bind:class="{ 'uk-background-primary uk-light': isShippingError, 'uk-alert-success': !isError }"
                   v-html="shippingMessage"></div>
            </div>
            <div v-if="shippingWait" class="shippingWait uk-width-1-1 uk-margin" uk-icon="icon: more"></div>
            <div v-if="!shippingWait && showShipping" class="uk-width-1-1 uk-margin">
              <select name="ShippingRates" id="ShippingRates"
                      :class="{'uk-select' : {}, 'uk-background-primary uk-light': Shipping == ''}" v-model="Shipping">
                <option v-for="rate in filteredShippingRates" v-bind:value="rate.Method">
                  <p :class="!notFreight(rate) ? 'uk-danger' : 'uk-warning'">{{ rate.DisplayText }}</p>
                  <!-- <p v-if="!notFreight(rate)" :class='uk-hidden'>{{ rate.DisplayText }}</p> -->
                </option>
              </select>
            </div>
            <div id="ShippingAccountWrapper" class="uk-panel uk-padding-small-bottom"
                 v-bind:class="{ 'uk-hidden': !ownShipping }">
              <p>Enter the Shipping Account Information:</p>
              <div class="uk-width-1-1 uk-margin-small-bottom">
                <label for="CompanyShipping" class="uk-form-label"></label>
                <div class="uk-form-controls">
                  <input type="text" name="CompanyShipping" id="CompanyShipping"
                         placeholder="Shipping Company" class="uk-input"
                         v-model.trim="companyShipping">
                </div>
              </div>
              <div class="uk-width-1-1">
                <label for="ShippingAccount" class="uk-form-label"></label>
                <div class="uk-form-controls">
                  <input type="text" name="ShippingAccount" id="ShippingAccount"
                         placeholder="Shipping Account Number" class="uk-input"
                         v-model.trim="shippingAccount">
                </div>
              </div>
            </div>
            <div class="uk-width-1-1">
              <label for="BlindShipping" class="uk-form-label">
                <input type="checkbox" class="uk-checkbox" id="BlindShipping" v-model="BlindShipping"
                       name="BlindShipping"> Blind Shipping.
              </label>
            </div>
            <div class="uk-width-1-1">
              <label for="cantDoFreight" class="uk-form-label">
                <input type="checkbox" class="uk-checkbox" id="cantDoFreight" v-model="cantDoFreight"
                       v-on:change="cantDoFreightChanged"
                       name="cantDoFreight"> My shipping location cannot accept freight.
              </label>
            </div>
            <div v-if="showPrice" class="uk-width-1-2">
              <label for="Tax" class="uk-h4">Tax: </label>
            </div>
            <div v-if="showPrice" class="uk-width-1-2 uk-form-controls">
              <!-- this doesn't allow for formatting as currency -->
              <!-- <input type="text" class="uk-input" readonly id="Tax" v-model="TaxAmount" name="Tax"> -->
              <input type="text" class="uk-input" readonly id="Tax" name="Tax"
                     :value="$toCurrency(TaxAmount)">
            </div>
          </div>
        </section>
        <section v-if="showPrice" id="secondCard" class="uk-section">
          <input type="checkbox" class="uk-checkbox" id="SecondCard" name="SecondCard" v-model="showSecondCard"> <label
            for="SecondCard">Split payment
          between two credit cards.</label>
          <div id="SecondCardInfo" v-if="showSecondCard" uk-grid>
            <div class="uk-width-1-1">
              <label for="SecondCreditCardName" class="uk-form-label">Name on Second Credit Card</label>
              <div class="uk-form-controls">
                <input type="text" name="SecondCreditCardName" id="SecondCreditCardName"
                       v-model.trim="paymentInfo.SecondCreditCardName" placeholder="Second Card Name"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-width-1-1">
              <label for="SecondCreditCardNumber" class="uk-form-label">Second Card Number</label>
              <div class="uk-form-controls">
                <input type="text" name="SecondCreditCardNumber" id="SecondCreditCardNumber"
                       v-model.trim="paymentInfo.SecondCreditCardNumber"
                       placeholder="Second Credit Card Number" class="uk-input"/>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label for="SecondCreditCardExpireMonth" class="uk-form-label">Expire Month</label>
              <div class="uk-form-controls">
                <select v-model="paymentInfo.SecondCreditCardExpireMonth"
                        name="SecondCreditCardExpireMonth" id="SecondCreditCardExpireMonth" class="uk-select">
                  <option v-for="(mo, key) in monthList" v-bind:value="mo.key">{{ mo.value }}</option>
                </select>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label for="SecondCreditCardExpireYear" class="uk-form-label">Expire Year</label>
              <div class="uk-form-controls">
                <select v-model="paymentInfo.SecondCreditCardExpireYear"
                        name="SecondCreditCardExpireYear" id="SecondCreditCardExpireYear" class="uk-select">
                  <option v-for="(yr, key) in yearList" v-bind:value="yr.key">{{ yr.value }}</option>
                </select>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label for="SecondCreditCardCVV" class="uk-form-label">CVV</label>
              <div class="uk-form-controls">
                <input type="text" name="SecondCreditCardCVV" id="SecondCreditCardCVV"
                       v-model.trim="paymentInfo.SecondCreditCardCVV" placeholder="CVV"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-width-1-1">
              <label for="SecondCreditCardAddress" class="uk-form-label">Second Card Address</label>
              <div class="uk-form-controls">
                <input type="text" name="SecondCreditCardAddress" id="SecondCreditCardAddress"
                       v-model.trim="paymentInfo.SecondCreditCardAddress" placeholder="Address"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label for="SecondCreditCardCity" class="uk-form-label">City</label>
              <div class="uk-form-controls">
                <input type="text" name="SecondCreditCardCity" id="SecondCreditCardCity"
                       v-model.trim="paymentInfo.SecondCreditCardCity" placeholder="City"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label for="SecondCreditCardState" class="uk-form-label">State</label>
              <div class="uk-form-controls">
                <select v-model="paymentInfo.SecondCreditCardState" name="SecondCreditCardState"
                        id="SecondCreditCardState"
                        class="uk-select">
                  <option v-for="(st, key) in stateList" v-bind:value="st.key">{{ st.value }}</option>
                </select>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label for="SecondCreditCardZip" class="uk-form-label">Zip Code</label>
              <div class="uk-form-controls">
                <input type="text" name="SecondCreditCardZip" id="SecondCreditCardZip"
                       v-model.trim="paymentInfo.SecondCreditCardZip" placeholder="Zip Code"
                       class="uk-input"/>
              </div>
            </div>
            <div class="uk-width-1-1">
              <div class="uk-form-controls">
                <input type="text" name="Amount1" id="Amount1"
                       v-model.trim="paymentInfo.FirstCardAmount"
                       placeholder="Dollar Amount to put on card #1" class="uk-input"/>
              </div>
            </div>
            <div class="uk-width-1-1">
              <div class="uk-form-controls">
                <input type="text" name="Amount2" id="Amount2"
                       v-model.trim="paymentInfo.SecondCardAmount"
                       placeholder="Dollar Amount to put on card #2" class="uk-input"/>
              </div>
            </div>
          </div>
        </section>
        <section v-if="isFranchiseEnabled('specSheets')" id="disclamer">
          <p class="uk-text-bolder uk-margin-small-bottom">PLEASE NOTE:</p>
          <p class="uk-margin-small-top">
            Eligibility for 24 Hour turnaround time is dependent on the following:
          </p>
          <ol class="uk-margin-small-top">
            <li>No manipulation to your art files is required.</li>
            <li>Additional requests or special instructions (E.g.: contour cut, checklists, color matching,
              etc.)
            </li>
            <li>Downloaded art files adhere to Art Specifications.</li>
          </ol>
        </section>
        <section id="specSheets">
          <div v-if="fileSpecs" id="SpecSheetContainer">
            <div class="uk-text-center@m">
              <a v-for="(url, desc) in fileSpecs"
                 class="uk-button uk-button-default uk-margin" :href="url"
                 target="_blank" uk-icon="download">Download {{ desc }} Art Specs</a>
            </div>
          </div>
        </section>


        <section id="makePurchase" class="uk-section">
          <div v-if="isFranchiseEnabled('specSheets')" class="uk-form-controls uk-margin">
            <label for="ConfirmConform" class="uk-form-label">
              <input type="checkbox" name="ConfirmConform" id="ConfirmConform"
                     :class="{'uk-background-primary' : ConfirmConform == false}" v-model="ConfirmConform"
                     class="uk-checkbox"/>
              I have read and agree to the art specifications as well as the website terms and conditions.
            </label>
          </div>
          <div v-else class="uk-form-controls uk-margin">
            <label for="ConfirmConform" class="uk-form-label">
              <input type="checkbox" name="ConfirmConform" id="ConfirmConform"
                     :class="{'uk-background-primary' : ConfirmConform == false}" v-model="ConfirmConform"
                     class="uk-checkbox"/>
              I have read and agree to the website terms and conditions.
            </label>
          </div>
          <div v-if="!billingMessage" class="uk-text-center">
            <button class="uk-button uk-button-primary project-button" v-bind:disabled="!formReady"
                    v-on:click.prevent="makePurchase">Place Order
            </button>
            <div v-if="showError" class="uk-alert"
                 v-bind:class="'uk-alert-danger'"
                 v-html="errorMessage">
            </div>
          </div>

        </section>
      </section>
    </div>
  </div>
</template>
<style lang="less">
.shippingWait {
  width: 100px;
  height: 100%;
  overflow: hidden;
  position: relative;
  animation: scroll 3.5s infinite linear;
}


@keyframes scroll {
  from {
    left: 5%;
  }
  to {
    left: 80%;
  }
}
</style>