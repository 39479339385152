<script setup>
import {router} from '../helpers/router';
import Cookies from './Components/Cookies';
import ContactMe from './Components/Contact';
import TradesourceFooter from './Components/TradesourceFooter';
import AspireFooter from './Components/AspireFooter';
import DecorFooter from './Components/DecorFooter';
</script>

<script type="module">
export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      lowenCertifiedURL: process.env.LowenCertifiedURL,
      lowenColorGraphicsURL: process.env.LowenColorGraphicsdURL,
      lowenCorpURL: process.env.LowenCorpURL,
      NGSCorpURL: process.env.NGSCorpURL,
      franchise: process.env.Franchise,
    }
  },
  components: {
    "cookies-app": Cookies,
    "contact-app": ContactMe,
    "tradesource-footer": TradesourceFooter,
    "aspire-footer": AspireFooter,
    "decor-footer": DecorFooter
  }
}
</script>

<template>
  <div id="CookiesModal" class="uk-position-bottom-right" style="z-index: 10" uk-grid>
    <cookies-app></cookies-app>
  </div>
    <tradesource-footer v-if="franchise === 'Wholesale'"></tradesource-footer>
    <aspire-footer v-if="franchise === 'NGS'"></aspire-footer>
    <decor-footer v-if="franchise === 'Decor'"></decor-footer>

  <div id="ContactModal" class="uk-flex-top" uk-modal="bg-close: false">
    <contact-app is="vue:contact-app"></contact-app>
  </div>
</template>