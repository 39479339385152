<script setup>
import {utilsMixin} from '../../helpers/utils';
</script>
<script>
export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      lowenCertifiedURL: process.env.LowenCertifiedURL,
      lowenColorGraphicsURL: process.env.LowenColorGraphicsdURL,
      lowenCorpURL: process.env.LowenCorpURL,
      NGSCorpURL: process.env.NGSCorpURL,
      franchise: process.env.Franchise,
    };
  },
  mixins: [utilsMixin],
}
</script>
<template>
  <div id="Footer"
       class="uk-padding uk-padding-remove@m uk-margin-remove uk-flex uk-flex-around@m uk-align-middle" uk-grid>
    <div id="LowenCorpContactSocial" class="uk-padding-remove uk-width-1-3@m uk-text-center uk-text-left@m">
      <div>
        <a v-if="facebookURL()" class="uk-icon-button uk-margin-right" :href="facebookURL()"
           uk-icon="icon: facebook; ratio: 1.15" target="_blank"></a>
        <a v-if="instagramURL()" class="uk-icon-button uk-margin-right" :href="instagramURL()"
           uk-icon="icon: instagram; ratio: 1.15" target="_blank"></a>
        <a v-if="twitterURL()" class="uk-icon-button uk-margin-right" :href="twitterURL()"
           uk-icon="icon: twitter; ratio: 1.15" target="_blank"></a>
        <a v-if="linkedinURL()" class="uk-icon-button" :href="linkedinURL()"
           uk-icon="icon: linkedin; ratio: 1.15" target="_blank"></a>
      </div>
    </div>
    <div id="ContactInformation" class="uk-padding uk-padding-remove-top uk-width-1-3@m">
      <h2 v-if="contactPhone()" class="uk-h1 uk-text-center uk-text-primary uk-text-bold uk-link-text"><a
          :href="contactPhone()">{{ contactPhone() }}</a></h2>
    </div>
    <div v-if="this.franchise === 'NGS'" class="uk-padding-remove uk-flex uk-flex-around uk-width-1-3@m">
      <a class="uk-logo" :href="this.NGSCorpURL" target="_blank">
        <img class="uk-img" src="/public/logos/ASPIREBlack.png" alt="Aspire">
      </a>
    </div>
  </div>
</template>