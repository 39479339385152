<script setup>
import PatternLibrary from "./Components/PatternLibrary.vue";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {router} from "../helpers/router";
import {useAuthStore} from "../stores/auth.store";
import {authHeader} from "../helpers/authheader";
</script>

<script>
export default {
    data() {
        return {};
    },
    components: {
        "pattern-library": PatternLibrary
    },
    mounted() {
        log("The Pattern Library Application is mounted");
    },
    methods: {}
};
</script>

<template>
    <div class="uk-section uk-section-small">
        <div class="uk-container uk-padding-remove">
            <h2 class="uk-text-center">Pattern Library</h2>
            <pattern-library></pattern-library>
        </div>
    </div>
</template>
