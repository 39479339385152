﻿<script>
import {utilsMixin} from '../helpers/utils';
import UIkit from 'uikit';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import ManageUsers from './Components/ManageUsers.vue';
import OrdersList from './Components/OrdersList.vue';
import EditAccount from './Components/EditAccount.vue'
import ChangePassword from './Components/ResetPassword.vue'

export default {
  data() {
    return {
      activeUsers: false,
      selectedUser: "",
      selectedUserEmail: "",
      selectedType: "active",
      showEditForm: false,
      showPasswordForm: false,
      newUser: false,
      deleteUser: false,
      baseUrl: process.env.APIURL + "/users",
      franchise: process.env.Franchise
    };
  },
  mixins: [utilsMixin],
  components: {
    "manage-users-app": ManageUsers,
    "order-list-app": OrdersList,
    "edit-account-app": EditAccount,
    "change-password-app": ChangePassword,
  },
  mounted() {
    if (this.isOfficeManager) {
      console.log("TheManageAccounts is mounted");
      let _this = this;
      // Clear orders list when switcher clears
      UIkit.util.on(".ManageUsersSwitch", "hidden", function (event) {
        if (event.target.classList.contains("ManageUsersSwitch")) {
          _this.setOrderParams("", "ordered");
        }
      });
    }
  },
  methods: {
    setOrderParams(user, email, type) {
      this.selectedUser = user;
      this.selectedUserEmail = email;
      this.selectedType = type;
    },
    setDeleteAccount(user, email) {
      this.selectedUser = user;
      this.selectedUserEmail = email;
      this.deleteUser = true;
      this.newUser = false;
      this.showEditForm = true;
      this.showPasswordForm = false;
    },
    addNewUser() {
      this.selectedUser = '';
      this.newUser = true;
      this.deleteUser = false;
      this.showEditForm = true;
      this.showPasswordForm = false;
    },
    chooseEditAccount(user, email) {
      this.selectedUser = user;
      this.selectedUserEmail = email;
      this.newUser = false;
      this.deleteUser = false;
      this.showEditForm = true;
      this.showPasswordForm = false;
    },
    changeAccountPassword(userId, userEmail) {
      this.selectedUser = userId;
      this.selectedUserEmail = userEmail;
      this.newUser = false;
      this.deleteUser = false;
      this.showPasswordForm = true;
      this.showEditForm = false;
    },
    closeDialogs() {
      this.showEditForm = false;
      this.showPasswordForm = false;
    }
  }
};
</script>

<template>
  <div class="uk-container uk-margin-top">
    <div class="" uk-grid>
      <div>
        <router-link class="uk-button uk-button-primary" :to="{name: 'accountDetails'}"><span uk-icon="icon: chevron-double-left"></span>Account</router-link>
      </div>
      <div>
        <h2>Manage Users</h2>
      </div>
    </div>

    <div class="uk-box-shadow-small uk-padding-small uk-margin-bottom">
      <div class="uk-margin-bottom">
        <div v-on:click="addNewUser()" class="uk-button uk-button-text">
          Add User
        </div>
      </div>

      <div>
        <div id="EditUserForm" class="" :hidden="!showEditForm">
          <div class="uk-animation-slide-top">
            <edit-account-app v-on:closedialog="closeDialogs" :userAccount="selectedUser"
                              :isNew="newUser" :isDelete="deleteUser"></edit-account-app>
          </div>
        </div>
        <div id="ChangePassForm" class="uk-animation-toggle" :hidden="!showPasswordForm">
          <div class="uk-animation-slide-top">
            <change-password-app v-on:closedialog="closeDialogs"
                                 :userEmail="selectedUserEmail"></change-password-app>
          </div>
        </div>
      </div>

      <div id="ManageActiveUsers" class="uk-section uk-section-xsmall" :class="{'uk-section-primary': activeUsers === true}">
        
        <manage-users-app :activeOnly="activeUsers" v-on:editaccount="chooseEditAccount"
                          v-on:changepassword="changeAccountPassword"
                          v-on:showorders="setOrderParams" v-on:deleteaccount="setDeleteAccount"></manage-users-app>
      </div>
    </div>

    <div id="ReturnedOrders" class="returnedOrders">
      <order-list-app :userAccount="selectedUser" :userEmail="selectedUserEmail"
                      :orderType="selectedType" :pageType="'admin'"></order-list-app>
    </div>
  </div>
</template>
