export const lv = {
    methods: {
    validEmail: function (address) {
        // console.log("validEmail", address);
        let returnValue = false;
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (this.isNotEmpty(address) && emailPattern.test(address)) {
            returnValue = true;
        }
        return returnValue;
    },
    validPhone: function (phone) {
        // console.log("validPhone", phone);
        let returnValue = false;
        let phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (this.isNotEmpty(phone) && phonePattern.test(phone)) {
            returnValue = true;
        }
        return returnValue;
    },
    isNotEmpty: function (test) {
        // console.log("isNotEmpty", test);
        let returnValue = false;
        if (Array.isArray(test)) {
            returnValue = test.length > 0;
        } else if (typeof test === 'object' && test !== null) {
            returnValue = Object.keys(test).length > 0;
        } else {
            if (test !== null && test !== "" && test !== undefined && test !== 'undefined')
                returnValue = true;
        }
        return returnValue;
    }
    }
}