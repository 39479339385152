<script setup>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

</script>
<script>
export default {
  data() {
    return {
      franchise: process.env.Franchise
    };
  }
}
</script>
<template>
  <!-- Jumbotron -->
  <section class="uk-section">
    <div uk-grid>
      <div class="uk-width-1-1">

        <div class="uk-align-center"
             style="background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjQsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTEzMHB4IiBoZWlnaHQ9IjQ1MHB4IiB2aWV3Qm94PSIwIDAgMTEzMCA0NTAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDExMzAgNDUwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxyZWN0IGZpbGw9IiNGNUY1RjUiIHdpZHRoPSIxMTMwIiBoZWlnaHQ9IjQ1MCIvPg0KPC9zdmc+DQo=') 50% 0 no-repeat; height: 450px;">
          <div class="uk-align-center uk-width-1-2">
            <h2 class="uk-heading-large">Sorry...</h2>
            <p class="uk-text-large">That page does not live here.</p>
            <p>
              <router-link class="uk-button uk-button-primary uk-button-large" :to="{name: 'home'}">Go Home</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Jumbotron -->
  <!-- Features -->
  <section v-if="this.franchise != 'NGS'" class="uk-section">
    <div uk-grid>
      <div class="uk-width-1-3@m">
        <div uk-grid>
          <div class="uk-width-1-6">
            <i class="uk-text-primary" uk-icon="icon: cog; ratio: 2"></i>
          </div>
          <div class="uk-width-5-6">
            <h2 class="uk-h3">Lowen Site</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </div>

      <div class="uk-width-1-3@m">
        <div uk-grid>
          <div class="uk-width-1-6">
            <i class="uk-text-primary" uk-icon="icon: nut; ratio: 2"></i>
          </div>
          <div class="uk-width-5-6">
            <h2 class="uk-h3">Lowen Site</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </div>

      <div class="uk-width-1-3@m">
        <div uk-grid>
          <div class="uk-width-1-6">
            <i class="uk-text-primary" uk-icon="icon: cloud-download; ratio: 2"></i>
          </div>
          <div class="uk-width-5-6">
            <h2 class="uk-h3">Lowen Site</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Features -->

  <!-- End Container -->
</template>