﻿<script setup>
import {router} from '../../helpers/router';
import {useAuthStore} from '../../stores/auth.store.js';
import {authHeader} from '../../helpers/authheader';
import Loading from "./Loading.vue";

import UIkit from 'uikit';
</script>
<script>
export default {
  data() {
    return {
      hideButtons: true,
      editMessage: "",
      showMessage: false,
      isError: false,
      isLoading: true,
      visibleColorSet: "",
      pantoneColors: [],
      pantoneSets: [],
      fileSet: {},
      spotColorFiles: {},
      spotColorLimit: 0,
      baseUrl: process.env.APIURL + "/Store/"
    };
  },
  mounted() {
    log("ColorMatchComponent Mounted");
    // have to do this because mounted() seems to share _this variables.
    let _colorthis = this;
    // log("colormatch: ", this);
    UIkit.util.on("#ColorMatchingModal", "beforeshow", function () {
      //log("BEFORESHOW")
      //log("colormatch:",_colorthis);
      _colorthis.getFileColorSet();
      _colorthis.editMessage = "";
      _colorthis.showMessage = false;
      _colorthis.isError = "";
    });
  },
  computed: {},
  methods: {
    async addColorMatch() {
      let _this = this;
      let carts = [];
      let uCarts = [];
      if (_this.pantoneColors.length > 0) {
        _.forEach(_this.spotColorFiles, function (file, key) {
          uCarts.push(key);
          let pt = {};
          let colors = [];
          _.forEach(_this.pantoneColors, function (pantone, file) {
            if (pantone.file == key) {
              colors.push(pantone.name);
            }
          });
          if (colors.length > 0) {
            pt["cart"] = key;
            let fileName = file;
            pt["fileName"] = fileName;
            pt["colors"] = colors;
            _this.pantoneSets.push(pt);
          }
        });
        _.forEach(this.pantoneSets, function (set) {
          log("filtered colors", set);
          let addCart = _this.callAddOption1(set);
        });
      } else {
        this.isError = true;
        this.showMessage = true;
        this.editMessage = "You must pick at least one Spot Color to add the service.";
      }
    },
    async callAddOption1(colorSet) {
      // log("callAddOption1", colorSet.fileName);
      const url = this.baseUrl + "AddOption1ToCart";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
        colorSetId: colorSet.cart,
        colorSetFilename: colorSet.fileName,
        colorSetColors: colorSet.colors
      }
      log("call is ", call);
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call),
      });
      let addCart = await response.json();
      //log(addCart);
      if (addCart.Status === "ok") {
        log("Success message");
        this.editMessage = this.editMessage + addCart.Message + "<br>";
        this.showMessage = true;
        UIkit.modal("#ColorMatchingModal").hide();
        router.go(0);
      }
      if (addCart.Status === "exception") {
        this.editMessage = addCart.Message;
        this.showMessage = true;
        this.isError = true;
      }
    },
    async getFileColorSet() {
      log("getFileColorSet");
      //this.isServiceLoading = true;
      const url = this.baseUrl + "GetFileColorSet";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
      }
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const fileSet = await response.json();
      log("getFileColorSet result: ", fileSet);
      if (fileSet.Status === "ok") {
        this.isLoading = false;
        this.fileSet = fileSet.Data;
        this.setFileList();
      }

      if (fileSet.Status === "exception") {
        this.isLoading = false;
        this.editMessage = fileSet.Message;
        this.showMessage = true;
      }
    },
    setFileList() {
      this.spotColorFiles = this.fileSet.SpotColorFiles;
      this.spotColorLimit = this.fileSet.SpotColorLimit;
    },
    showPantones(cartId) {
      log("showPantones", cartId);
      this.visibleColorSet = cartId;
    },
    colorSet(cartId) {
      // log("colorSet", cartId);
      // log("selected Color Set", this.fileSet.SpotColors[cartId]);
      let spotColors = this.fileSet.SpotColors[cartId];
      // log("spotColors for this file", spotColors);
      return spotColors;
    }
  },
  watch: {
    //
    // Needed a way to count checked items before submitting
    // Brute force, could use refinement.
    //
    pantoneColors(newValue, oldValue) {
      log("pantoneColors", newValue);
      let pantoneColors = this.pantoneColors;
      let colorLimit = this.spotColorLimit;
      let _this = this;
      this.isError = false;
      this.showMessage = false;
      this.editMessage = "";
      _.forEach(this.spotColorFiles, function (fileName, fileId) {
        let count = 0;
        log(fileId)
        _.forEach(pantoneColors, function (pantone) {
          if (pantone.file === fileId) {
            count++
          }
        });
        if (count > colorLimit) {
          _this.isError = true;
          _this.showMessage = true;
          _this.editMessage = "You cannot add more than 5 Spot Colors to a file.";
        }
      });
    }
  }
}
</script>

<template id="ColorMatchTemplate">
  <div class="uk-modal-dialog">
    <div class="uk-modal-body">
      <h3 class="uk-modal-title">Add Color Matching</h3>
      <hr/>
      <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}"
           class="uk-alert" v-html="editMessage"></div>

      <Loading v-if="isLoading"/>
      <div v-if="!isLoading">
        <div id="serviceDetails">
          <p class="uk-text-lead">
            Touch-up and color matching for your existing file.<br/>
            Service includes:
          </p>
          <ul class="uk-list uk-list-disc uk-margin-remove-top">
            <li>Up to 2 revisions.</li>
            <li>Up to 2 vinyl proofs of artwork - Scaled to fit on 12” x 52” Max.</li>
            <li>Color match up to 4 Pantone Colors.</li>
            <li>Up to 2 vinyl printed Color Samples per requested Color Match - 4” x 4”.</li>
            <li>File must include Spot Color Chart.</li>
            <li>If color matching services are selected, please anticipate an additional production time of 1-3 business
              days.
            </li>
          </ul>
        </div>
        <div v-if="Object.keys(spotColorFiles).length > 0">
          <div id="serviceControls" class="uk-background-muted uk-padding-small uk-margin-bottom">
            <h4 class="uk-h4 uk-text-center uk-margin-remove">Choose file to select spot colors:</h4>
          </div>
          <div v-for="(fileName, cartId) in spotColorFiles">
            <div class="uk-text-center uk-margin-small">
              <button v-on:click="showPantones(cartId)"
                      class="uk-button uk-button-primary project-button uk-margin-small-right"
                      type="button">{{ fileName }}
              </button>
            </div>
            <ul v-bind:class="{'uk-hidden': visibleColorSet != cartId}" class="uk-list">
              <li v-for="(color) in colorSet(cartId)">
                <label v-bind:for="$stripSpaces(color.name)">
                  <input type="checkbox" class="pantoneColors" v-model="pantoneColors"
                         v-bind:id="$stripSpaces(color.name)"
                         v-bind:name="$stripSpaces(cartId)"
                         v-bind:value="{name: color.name, file:cartId, fileName: fileName}"/>{{ color.name }}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <h4 class="uk-h4 uk-text-center uk-margin-remove">No files have Spot Color Chart.</h4>
        </div>
        <div class="uk-margin uk-margin-remove-bottom uk-padding-small uk-padding-remove-bottom">
          <p class="uk-text-right uk-margin-remove">
            <button class="uk-button uk-button-small uk-button-primary project-button uk-margin-right"
                    :disabled="isError" type="button"
                    v-on:click="addColorMatch">
              Add
              Service
            </button>
            <button class="uk-button uk-button-default uk-modal-close" type="button">Close</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>