﻿<script setup>
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
</script>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.APIURL + "/users",
      userList: [],
      franchise: process.env.Franchise
    };
  },
  components: {},
  mounted() {
    console.log("TheUserList is mounted");
    this.fetchAllUsers();
  },
  methods: {
    async fetchAllUsers() {
      log("fetchAllUsers");
      const url = this.baseUrl + "/GetAll";
      let response = await fetch(url, {
        method: "GET",
        headers: authHeader(url),
      });
      let userResponse = await response.json();
      log("fetchUserDetails: ", userResponse);
      if (userResponse.Status === "ok") {
        this.userList = userResponse.Data;
      }
      if (userResponse.Status === "exception") {
        log("Could not retrieve userResponse:", userResponse.Message);
      }
    }
  }
};
</script>

<template>
  <div class="uk-container uk-container-large">
    <div>
      <h3>Users</h3>
      <table class="uk-table uk-table-striped uk-table-middle">
        <thead>
        <th class="uk-table-shrink" ><span class="uk-h4">ID</span></th>
        <th class="uk-table-shrink"><span class="uk-h4">Customer ID</span></th>
        <th class="uk-table-shrink"><span class="uk-h4">Username</span></th>
        <th class="uk-table-expand"><span class="uk-h4">RefreshTokens</span></th>
        </thead>
        <tbody>
        <tr v-for="user in userList">
          <td>{{ user.id }}</td>
          <td>{{ user.customerId }}</td>
          <td>{{ user.username }}</td>
          <td class="uk-text-truncate">
            <div class="uk-panel uk-panel-scrollable">
              <template v-for="t in user.refreshTokens">
                <div class="uk-alert">
                  <!--                  <div class="uk-text-truncate"><strong>Token:</strong> {{ (t.token).slice(0, 50) }}...</div>-->
                  <div class="uk-text-truncate"><strong>Token:</strong> {{ t.token }}...</div>
                  <div><strong>Created:</strong> {{ t.created }}</div>
                  <div><strong>Active:</strong> {{ t.isActive }}</div>
                  <div><strong>Revoked:</strong> {{ t.isRevoked }}</div>
                  <div><strong>Revoked Reason:</strong> {{ t.reasonRevoked }}</div>
                  <div><strong>Expires:</strong> {{ t.expires }}</div>
                  <div><strong>Expired:</strong> {{ t.isExpired }}</div>
                </div>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div> <!--end container-->

</template>
