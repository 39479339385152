import { createApp } from "vue";
import { createPinia } from "pinia";
import  FranchiseApp  from "./views/FranchiseApp.vue";
import { router } from "./helpers/router";

globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = false;
const TheApp = createApp(FranchiseApp);
// format helpers
TheApp.config.globalProperties.$toCurrency = toCurrency;
TheApp.config.globalProperties.$stripSpaces = stripSpaces;
// enables computed on provide/inject
TheApp.config.unwrapInjectedRef = true
TheApp.use(createPinia());
TheApp.use(router);
console.log("mount the franchise application");
TheApp.mount("#FranchiseAppContainer");
