﻿<script setup>
    import {useAuthStore} from '../../stores/auth.store.js';
    import {authHeader} from "../../helpers/authheader";
</script>

<script>
    export default {
        data() {
            return {
                firstName: "",
                lastName: "",
                email: "",
                showMessage: false,
                inviteMessage: "",
                isError: false,
                baseUrl: process.env.APIURL + "/Account",
            };
        },
        mounted() {
            log("InviteUserComponent is mounted");
        },
        methods: {
            async callInviteUser() {
                log("callInviteUser");
                const url = this.baseUrl + '/InviteAccountUser';
                const { user } = useAuthStore();
                // log("user is", user);
                returnValue = false;
                call = {
                    "userId": user.Id,
                    "FirstName": this.firstName,
                    "LastName": this.lastName,
                    "Email": this.email,
                };
                let response = await fetch(url, {
                    method: 'POST',
                    headers: authHeader(url),
                    body: JSON.stringify(call)
                });
                let accountEmail = await response.json();
                // log("callInviteUser result: ", accountEmail);
                return accountEmail;
            },
            async inviteUser() {
                log("inviteUser");
                ready = false;
                this.inviteMessage = "";
                if (this.firstName.length < 1) {
                    this.isError = true;
                    this.showMessage = true;
                    this.inviteMessage = "First Name is required."
                }
                if (this.lastName.length < 1) {
                    this.isError = true;
                    this.showMessage = true;
                    this.inviteMessage = this.inviteMessage +"<br>Last Name is required."
                }
                if (this.email.length < 1) {
                    this.isError = true;
                    this.showMessage = true;
                    this.inviteMessage = this.inviteMessage + "<br>Email address is required."
                }

                if (_.trim(this.firstName) !== "" && _.trim(this.lastName) !== "" && _.trim(this.email) !== "") {
                    ready = true;
                }

                if (ready) {
                    log("do the thing");
                    result = await this.callInviteUser();
                    if (result.Status == "ok") {
                        this.inviteMessage = result.Message;
                        this.showMessage = true;
                    }
                    if (result.Status !== "ok") {
                        this.inviteMessage = result.Message
                        this.showMessage = true;
                        this.isError = true;
                    }
                }
            }
        }
    };
</script>

<template id="InviteUserTemplate">
    <div class="uk-modal-dialog">
        <div class="uk-modal-body">
            <h3 class="uk-modal-title">Invite a User</h3>
            <hr />
            <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}" class="uk-alert" v-html="inviteMessage"></div>
            <form v-on:submit.prevent="inviteUser" method="post" class="uk-form-stacked">
                <div class="uk-margin-small">
                    <div class="uk-form-controls">
                        <input type="text" name="firstName" v-model.trim="firstName" placeholder="First Name" class="uk-input uk-form-blank" />
                    </div>
                </div>

                <div class="uk-margin-small">
                    <div class="uk-form-controls">
                        <input type="text" name="lastName" v-model.trim="lastName" placeholder="Last Name" class="uk-input uk-form-blank" />
                    </div>
                </div>

                <div class="uk-margin-small">
                    <div class="uk-form-controls">
                        <input type="text" name="email" v-model.trim="email" placeholder="Email" class="uk-input uk-form-blank" />
                    </div>
                </div>
                <div class="uk-margin">
                    <input type="submit" name="InviteUser" value="Invite User" class="uk-button uk-button-primary uk-margin-right" />
                    <input type="button" name="Cancel" value="Cancel" class="uk-modal-close uk-button uk-button-secondary" />
                </div>
            </form>
        </div>
    </div>
</template>