﻿<script setup>
import {useAuthStore} from '../../stores/auth.store.js';
import {authHeader} from '../../helpers/authheader';
import Loading from './Loading';
import UIkit from 'uikit';
</script>
<script>
export default {
  props: ['shipkey'],
  data() {
    return {
      addAddress: false,
      addressInfo: {
        FirstName: "",
        LastName: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "Select",
        Zip: "",
        Country: "US",
        PhoneNumber: "",
        Email: "",
        Residential: false,
        County: "",
        CompanyName: "",
        ShipKey: "",
      },
      confirmDelete: false,
      closeButton: "Cancel",
      editMessage: "",
      countryList: [],
      showMessage: false,
      stateList: [],
      isError: false,
      isLoading: false,
      requiredProps: [
        "FirstName", "LastName", "Address1", "City", "State", "Zip", "Country"
      ],
      baseUrl: process.env.APIURL + "/Account"
    };
  },
  mounted() {
    log("EditAddressComponent is mounted");
    var _this = this;
    UIkit.util.on("#EditAddressModal", "beforeshow", function () {
      // Cleaning up the dialog before showing.
      _this.getStateList();
      _this.getCountryList();
      _this.confirmDelete = false;
      _this.closeButton = "Cancel";
      _this.editMessage = "";
      _this.showMessage = false;
      _this.isError = false;
    });

    UIkit.util.on("#EditAddressModal", "hidden", function () {
      log("calling modal hidden");
      log('reload the window');
      window.location.reload();
    });
  },
  watch: {
    shipkey(newValue) {
      // log("new shipkey value", this.shipkey);
      if (this.shipkey === "addAddress") {
        this.setupAdd();
      } else {
        this.getEditAddress();
      }
    },
  },
  methods: {
    getStateList() {
      //log("getStateList");
      const url = this.baseUrl + "/GetStateList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.stateList = jsondata;
        this.loadingCount++;
        //log("stateList", this.stateList);
      });
    },
    getCountryList() {
      //log("getCountryList");
      const url = this.baseUrl + "/GetCountryList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.countryList = jsondata;
        this.loadingCount++;
      });
    },
    getEditAddress: async function () {
      // log("getEditAddress");
      let accountInfo = false;
      this.isLoading = true;
      const url = this.baseUrl + "/GetEditAddress";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
        shipkey: this.shipkey
      };
      //log("shipkey", this.shipkey);
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call),
      });
      const addressInfo = await response.json();
      this.addressInfo = addressInfo;
      log("editAddress INFO", this.addressInfo);
      this.isLoading = false;
    },
    async manageAddress() {
      // log("manageAddress");
      const url = this.baseUrl + "/ManageAddress";
      const authStore = useAuthStore();
      const user = authStore.user;
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(this.addressInfo)
      });
      const addressChange = await response.json();
      log("manageAddress result: ", addressChange);
      return addressChange;
    },
    async deleteAddress() {
      // log("deleteAddress", this.addressInfo);
      let returnValue = false;
      const url = this.baseUrl + "/DeleteAddress";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
        shipkey: this.addressInfo.ShipKey
      }
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const addressChange = await response.json();
      log("deleteAddress result: ", addressChange);
      if (addressChange.Status === "ok") {
        UIkit.modal("#EditAddressModal").hide();
      }
    },
    async editAddress() {
      // log("editAddress");
      let ready = false;
      let _this = this;
      _this.editMessage = "";

      _.forEach(_this.addressInfo, function (prop, key) {
        let isReq = _.indexOf(_this.requiredProps, key);
        if (isReq > -1) {
          if (prop === "") {
            _this.editMessage = _this.editMessage + key + " is required.<br>";
          }
        }
      });

      if (_this.editMessage.length > 0) {
        _this.showMessage = true;
        _this.isError = true;
      } else {
        ready = true;
      }
      if (ready) {
        // log("do the thing!", this.addressInfo);
        const authStore = useAuthStore();
        const user = authStore.user;
        this.addressInfo.userId = user.Id;
        if (this.addAddress) {
          this.addressInfo.ShipKey = "0";
        }
        if(this.addressInfo.Residential === "true") {
          this.addressInfo.Residential = true;
        }
        const result = await this.manageAddress();

        if (result.Status === "ok") {
          this.editMessage = "Address Updated.";
          this.closeButton = "Close";
          this.showMessage = true;
          this.isError = false;
          UIkit.modal("#EditAddressModal").hide();
        }
        if (result.Status !== "ok") {
          this.editMessage = result.Message
          this.showMessage = true;
          this.isError = true;
        }
      }
    },
    setupAdd() {
      log("setUpAdd");
      this.addressInfo = {
        FirstName: "",
        LastName: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "Select",
        Zip: "",
        Country: "United States",
        PhoneNumber: "",
        Email: "",
        Residential: false,
        County: "",
        CompanyName: "",
        ShipKey: "",
      };
      this.addAddress = true;
    },
  },
};
</script>

<template id="EditAddressTemplate">
  <div class="uk-modal-dialog">
    <div class="uk-modal-body">
      <h3 class="uk-modal-title">Edit Address</h3>
      <hr/>
      <div class="uk-margin-small">
        <label for="residence" class="uk-form-label">Address Type</label>
        <div class="uk-form-controls">
          <div class="uk-form-controls">
            <select name="residence" id="residence" v-model="addressInfo.Residential" class="uk-select">
              <option value=false>Business</option>
              <option value=true>Residence</option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}"
           class="uk-alert" v-html="editMessage"></div>

      <Loading v-if="isLoading" />

      <section v-if="!isLoading">
        <form v-on:submit.prevent="editAddress" method="post" class="uk-form-stacked">
          <input type="hidden" name="shipKey" v-model.trim="addressInfo.ShipKey" placeholder=""
                 class="uk-input uk-form-blank"/>

          <div class="uk-margin-small">
            <label v-if="addressInfo.FirstName" for="firstName" class="uk-form-label">First Name</label>
            <div class="uk-form-controls">
              <input type="text" name="firstName" id="firstName" v-model.trim="addressInfo.FirstName"
                     placeholder="First Name" class="uk-input uk-form-blank"/>
            </div>
          </div>

          <div class="uk-margin-small">
            <label v-if="addressInfo.LastName" for="lastName" class="uk-form-label">Last Name</label>
            <div class="uk-form-controls">
              <input type="text" name="lastName" id="lastName" v-model.trim="addressInfo.LastName"
                     placeholder="Last Name" class="uk-input uk-form-blank"/>
            </div>
          </div>

          <div class="uk-margin-small">
            <label v-if="addressInfo.CompanyName" for="companyName" class="uk-form-label">Company</label>
            <div class="uk-form-controls">
              <input type="text" name="companyName" id="companyName" v-model.trim="addressInfo.CompanyName"
                     placeholder="Company" class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="addressInfo.Address1" for="address1" class="uk-form-label">Address</label>
            <div class="uk-form-controls">
              <input type="text" name="address1" id="address1" v-model.trim="addressInfo.Address1"
                     placeholder="Address Line 1" class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="addressInfo.Address2" for="address2" class="uk-form-label">Address Line 2</label>
            <div class="uk-form-controls">
              <input type="text" name="address2" id="address2" v-model.trim="addressInfo.Address2"
                     placeholder="Address Line 2" class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="addressInfo.City" for="city" class="uk-form-label">City</label>
            <div class="uk-form-controls">
              <input type="text" name="city" id="city" v-model.trim="addressInfo.City" placeholder="City"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label for="state" class="uk-form-label">State</label>
            <div class="uk-form-controls">
              <select v-model="addressInfo.State" class="uk-select" name="state" id="state">
                <option v-for="(st, key) in stateList" v-bind:value="st.key">{{ st.value }}</option>
              </select>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="addressInfo.Zip" for="zipCode" class="uk-form-label">Postal Code</label>
            <div class="uk-form-controls">
              <input type="text" name="zipCode" id="zipCode" v-model.trim="addressInfo.Zip" placeholder="Postal Code"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label for="country" class="uk-form-label">Country</label>
            <div class="uk-form-controls">
              <select v-model="addressInfo.Country" name="country" id="country" class="uk-select">
                <option v-for="(co, key) in countryList" v-bind:value="co.key">{{ co.value }}</option>
              </select>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="addressInfo.PhoneNumber" for="phoneNumber" class="uk-form-label">Phone Number</label>
            <div class="uk-form-controls">
              <input type="text" name="phoneNumber" id="phoneNumber" v-model.trim="addressInfo.PhoneNumber"
                     placeholder="Phone Number" class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div v-if="confirmDelete" class="uk-alert uk-alert-danger">
            <p>Are you sure you want to delete this address?</p>
            <input v-on:click="deleteAddress" type="button" name="Delete" value="Yes, Delete!"
                   class="uk-button uk-button-danger uk-margin-right"/>
            <input type="button" name="Delete" value="No" class="uk-modal-close uk-button uk-button-secondary"/>
          </div>
          <div class="uk-modal-footer">
            <div class="uk-margin">
              <input type="submit" name="editAddress" value="Save" class="uk-button uk-button-primary uk-margin-right"/>
              <input type="button" name="Cancel" v-bind:value="closeButton"
                     class="uk-modal-close uk-button uk-button-secondary"/>

              <input v-on:click="confirmDelete = true" type="button" name="Delete" value="Delete"
                     class="uk-button uk-button-danger uk-align-right"/>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>