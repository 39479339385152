﻿<script>
import {authHeader} from "../../helpers/authheader";
import {lv} from "../../helpers/lowenValidation";

export default {
  mixins: [lv],
  props: ['userEmail'],
  data() {
    return {
      changeUsername: "",
      showSuccess: false,
      showError: false,
      resetMessage: "An Email has been sent to associated account. Follow instructions in that email to complete the process of resetting your password.",
      baseUrl: process.env.APIURL,
      franchise: process.env.Franchise
    }
  },
  methods: {
    async resetPassword() {
      log("resetPassword");
      const url = this.baseUrl + '/Account/ResetPassword';
      this.showSuccess = false;
      this.showError = false;
      const call = {
        "Email": this.changeUsername,
        "Franchise": this.franchise
      };
      let response = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let accountReset = await response.json();
      // log("accountReset status: ", accountReset);
      if (accountReset.Status == "ok") {
        this.showSuccess = true;
      }
      if (accountReset.Status == "exception") {
        this.showError = true;
        this.resetMessage = accountReset.Message;
      }
    }
  },
  watch: {
    userEmail(newVal, oldVal) {
      if (this.isNotEmpty(newVal)) {
        this.changeUsername = newVal;
      }
    }
  }
}
</script>
<template id="ResetTemplate">
  <div>
    <h2 class="uk-h3">Reset Password</h2>
    <hr>
    <div v-if="showSuccess" class="uk-alert uk-alert-success">
      <p>{{ resetMessage }}</p>
    </div>
    <div v-if="showError" class="uk-alert uk-alert-danger">
      <p>{{ resetMessage }}</p>
    </div>
    <div v-if="!showSuccess">
      <p v-if="!isNotEmpty(userEmail)">
        This will reset your password. If you have an active account, a new password will be emailed to you.
      </p>
      <p v-if="isNotEmpty(userEmail)">
        This will email a new password to the account holder. They will have to reset their password the next time they
        login.
      </p>
    </div>
    <div class="uk-form-stacked">
      <div class="uk-margin">
        <input class="uk-form-large uk-form-blank" type="text" name="changeUsername"
               v-model="changeUsername" placeholder="Username">
      </div>
      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-margin-right"
                v-on:click.prevent="resetPassword">
          Reset
        </button>
        <button v-on:click="$emit('closedialog')" class="uk-button uk-button-secondary uk-modal-close">
          <span v-if="!showSuccess">Cancel</span>
          <span v-if="showSuccess">Close</span>
        </button>
      </div>
    </div>
  </div>
</template>