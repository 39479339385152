<script setup>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
</script>
<script type="module">
export default {
  data() {
    return {
      baseUrl: process.env.APIURL
    };
  },
  mounted() {
    console.log("Tradesource is mounted");
    UIkit.use(Icons);
  },
  methods: {}
};
</script>
<template>
  <div class="uk-section">
    <div class="uk-container uk-container-small">
      <section class="uk-section">
        <h2 class="uk-h2 uk-text-center">Terms of Use and Conditions of Order</h2>
        <p>This Terms of Use and Conditions of Order Agreement (“Agreement”) is made by and between Lowen TradeSource
          ("Lowen") and its subsidiaries, parent company, and affiliates, (“Lowen,” “us,” “we” and “our”) and you
          (“you,”
          “your,” or “user”). This Agreement contains the terms and conditions that govern your use of any website, or
          any
          part of a website, and any services offered through such website that displays, links, or refers to this
          Agreement (such websites referred to herein collectively and individually as “website,” or “this website” and
          collectively such website and any services offered on them being the "Services").</p>
        <p>BY ACCESSING, VISITING, BROWSING, USING, OR ATTEMPTING TO INTERACT WITH THE SERVICES OR ANY PART THEREOF, YOU
          AGREE, ON YOUR OWN BEHALF PERSONALLY, AND ON BEHALF OF ANY ENTITY FOR WHICH YOU ARE AN AGENT OR YOU APPEAR TO
          REPRESENT, THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO BE
          BOUND BY THIS AGREEMENT, DO NOT ACCESS OR USE ANY PART OF THIS WEBSITE.</p>
        <p>LOWEN RESERVES THE RIGHT, FROM TIME TO TIME, WITH OR WITHOUT NOTICE TO YOU, TO MAKE CHANGES TO THIS AGREEMENT
          IN LOWEN’S SOLE DISCRETION. CONTINUED USE OF ANY PART OF THIS WEBSITE OR PURCHASE OF SERVICES CONSTITUTES YOUR
          ACCEPTANCE OF SUCH CHANGES. THE MOST CURRENT VERSION OF THIS AGREEMENT, WHICH SUPERSEDES ALL PREVIOUS
          VERSIONS,
          CAN BE REVIEWED BY CLICKING ON THE “TERMS OF USE” HYPERLINK LOCATED ON THE LOWEN WEBSITE.</p>
        <h2 class="uk-h2 uk-text-center">TERMS OF USE</h2>
        <h3 class="uk-h4">1. ACCESS TO THE SERVICES</h3>
        <p>To access the Services or some of the resources it offers, including contacting Lowen, you may be asked to
          provide certain registration details or other information such as your name, e-mail address, and other
          credentials. It is a condition of your use of the Services that all the information you provide in relation to
          the Services will be correct, current, and complete. If Lowen believes the information you provide is not
          correct, current, or complete, Lowen has the right to refuse you access to the Services or any of its
          resources,
          and to block, terminate, or suspend your access at any time.</p>
        <p>Lowen collects the name, address, phone, and e-mail address from any visitor who requests information. These
          data are used for the purpose of sending samples and literature about our products and services. In the event
          that an order is placed, we may use all data provided to contact the Customer with order-related questions
          and/or information. From time to time, we may also send newsletters and promotional information via e-mail on
          product updates, special offers, discounts, or other promotions. You may choose not to receive promotional
          e-mails from us by using the "unsubscribe" instructions (in the e-mail that is sent to you) or by sending us
          an
          e-mail at unsubscribe@lowen.com requesting removal from future promotional e-mails. We will respond to a
          request
          as soon as possible. We reserve the right to contact a Customer if we have order-related questions or
          information. Visitor information and data that are collected will never be sold to another company. If you
          have
          any questions or comments regarding our policy, please contact us via our "Contact" page.</p>
        <h3 class="uk-h4">2. RESTRICTIONS ON USE</h3>
        <p>You may use the Services for purposes expressly permitted by this Agreement. As a condition of your use of
          the
          Services, you warrant to Lowen that you will not use the Services for any purpose that is unlawful or
          prohibited
          by any part of this Agreement. For example, you may not (and may not authorize any party to) (i) co-brand the
          Services, or (ii) frame the Services, without the express prior written permission of an authorized
          representative of Lowen. For purposes of this Agreement, “co-branding” means to display a name, logo,
          trademark,
          or other means of attribution or identification of any party in such a manner as is reasonably likely to give
          a
          user the impression that such other party has the right to display, publish, or distribute the Services or
          content accessible within the Services. You agree to cooperate with Lowen in causing any unauthorized
          co-branding or framing immediately to cease. You may not connect “deep links” to the website, i.e., create
          links
          to the Services that bypass the home page or other parts of the Services without the prior written permission
          of
          Lowen.</p>
        <p>In addition, you may not use the Services in any manner that could disable, overburden, damage, or impair the
          Services or interfere with any other party’s use and enjoyment of the Services. You may not obtain or attempt
          to
          obtain any materials, content, or information through any means not intentionally made available or provided
          for
          through the Services. You may not use scrapers, bots, spiders, or other automated tools to collect or index
          the
          content of the Services without Lowen’s express prior written permission. You may not attempt to probe, scan
          or
          test the vulnerability of the Services or any system to which it is connected or attempt to breach any
          security
          measures. </p>
        <h3 class="uk-h4">3. PROPRIETARY INFORMATION</h3>
        <p>The material and content accessible from the Services, and any other website owned, operated, licensed, or
          otherwise controlled by Lowen, including without limitation any text, photos, videos, audio, scripts,
          software,
          markup language, or other content whether perceptible or not to a human (whether displayed, downloadable or
          otherwise) (the “Content”) is the proprietary information of Lowen or the party that provided or licensed the
          Content to Lowen, whereby such providing party retains all right, title, and interest in the Content.
          Accordingly, the Content may not be copied, distributed, republished, uploaded, posted, displayed, performed,
          licensed, modified, or transmitted in any way without the prior written consent of Lowen. Terms related to
          your
          license to any purchased outputs from the Services are set forth in the Conditions of Order. Modification or
          use
          of the Content except as expressly provided in this Agreement violates Lowen’s intellectual property rights.
          Neither title nor intellectual property rights of any kind are transferred to you by access to the
          Services.</p>
        <h3 class="uk-h4">4. ADDITIONAL USE LIMITATION</h3>
        <p>You may not modify, translate, decompile, disassemble, reverse engineer, alter, copy, distribute, display,
          send, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information,
          Content, software, products, or Services obtained from or otherwise connected to Lowen’s website, except for
          the
          as expressly permitted pursuant to applicable law and as set forth in the license in the Conditions of Order
          for
          purchased outputs from the Services.</p>
        <h3 class="uk-h4">5. HYPERLINKS</h3>
        <p>The Services may be hyper-linked to other websites which are not maintained by, or related to, Lowen and may
          contain information about products or services that are not related to or endorsed by Lowen. Hyperlinks to
          such
          websites and information are provided as a service to you and are not sponsored by or affiliated with this
          website or Lowen. Lowen may not have reviewed any or all of such websites and information and is not
          responsible
          for the content of those websites or the descriptions of their products and services. Lowen is not responsible
          for webcasting or any other form of transmission received from any hyper-linked website. Hyperlinks are to be
          accessed at your own risk and information contained therein is relied upon at your own risk, and Lowen makes
          no
          representations or warranties about the content, completeness, or accuracy of these hyperlinks or the websites
          hyper-linked to the Services or the information regarding or relating to their products and services. Lowen
          provides hyperlinks as a convenience, and the inclusion of any hyperlink to a third-party website or
          information
          does not necessarily imply endorsement by Lowen of that website, its products or services, or any association
          with its operators.</p>
        <h3 class="uk-h4">6. FEEDBACK</h3>
        <p>You hereby grant assign to Lowen all right, title, and interest including all intellectual property rights in
          and to any and all feedback, suggestions, or improvements you communicate to Lowen regarding the Services
          ("Feedback"), without incurring any liability for royalties or any other consideration of any kind, and will
          not
          incur any liability as a result of any similarities that may appear in future Lowen Services. You warrant and
          represent that you own or otherwise control all of the rights to any Feedback including, without limitation,
          all
          the rights necessary for you to provide, post, upload, input or submit the Feedback and assign it in
          accordance
          with this Agreement, and that the Feedback will not violate any law or the rights, including without
          limitation
          the intellectual property rights, of any person or entity.</p>
        <h3 class="uk-h4">7. DISCLAIMERS</h3>
        <p>You understand that Lowen cannot and does not guarantee or warrant that the Services or its Content, or any
          other files available for downloading from the Internet will be free of viruses, worms, Trojan horses, or
          other
          code that may manifest contaminating or destructive properties. You are responsible for implementing
          sufficient
          procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and
          for maintaining a means external to this website for any reconstruction of any lost data. Lowen does not
          assume
          any responsibility or risk for your use of the Services, its Content, or the Internet.</p>
        <p>All Service sales are also subject to the Conditions of Order as set forth in this Agreement.</p>
        <p>YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES INCLUDING WITHOUT LIMITATION ALL CONTENT IS
          PROVIDED
          “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. LOWEN DISCLAIMS ALL WARRANTIES,
          INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR
          NON-INFRINGEMENT RELATED TO THE SERVICES AND THE CONTENT. LOWEN DOES NOT WARRANT THAT THE SERVICES OR CONTENT
          WILL BE PROVIDED UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE
          SERVER
          THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. LOWEN DOES NOT WARRANT OR MAKE ANY
          REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF THE SERVICES INCLUDING WITHOUT LIMITATION THE CONTENT
          IN
          TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE SERVICES INCLUDING WITHOUT LIMITATION THE CONTENT MAY
          INCLUDE
          TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND LOWEN MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU,
          AND
          NOT LOWEN, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION IN THE EVENT OF ANY LOSS
          OR
          DAMAGE ARISING FROM THE USE OF THE SERVICES OR ITS CONTENT.</p>
        <p>ADDITIONAL DISCLAIMERS AND WARRANTIES MAY BE SET FORTH AS TO SERVICES YOU PURCHASED IN THE CONDITIONS OF
          ORDER.</p>
        <p>SOME JURISDICTIONS, MAY NOT ALLOW THE EXCLUSION OF CERTAIN TYPES OF WARRANTIES INCLUDING WITHOUT LIMITATION
          OF
          IMPLIED WARRANTIES. AS A RESULT, THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
        <p>Like most websites, this website is accessible worldwide. However, website and the Services as a whole are
          intended only for customers located in the United States of America, and you warrant that you are located in
          the
          United States of America.</p>
        <h3 class="uk-h4">8. LIMITATION OF LIABILITY</h3>
        <p>LOWEN, ITS SUBSIDIARIES, AFFILIATES, PARENT COMPANY, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS,
          EMPLOYEES, AGENTS, OFFICERS, OWNERS, AND DIRECTORS (THE "LOWEN PARTIES") WILL NOT BE LIABLE FOR ANY
          INCIDENTAL,
          INDIRECT, PUNITIVE, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, LOSS OF
          BUSINESS, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF LOWEN HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES IN RELATION TO OR REGARDING THE SERVICES INCLUDING WITHOUT LIMITATION THE CONTENT.
          IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE LOWEN PARTIES TO YOU (REGARDLESS OF THE FORM OF ACTION,
          WHETHER
          IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE AMOUNT YOU PAID TO LOWEN FOR SERVICES IN THE THREE (3) MONTHS
          PRECEDING THE EVENT THAT GAVE RISE TO THE LIABILITY, OR THE MINIMUM ALLOWED BY LAW, WHICHEVER IS GREATER.</p>
        <p>SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES SUCH
          AS
          INCIDENTAL OR CONSEQUENTIAL DAMAGES OR DAMAGES TO PERSONAL OR PROPERTY DAMAGE, OR DAMAGES CAUSED BY THE
          NEGLIGENT OR WILLFUL ACTIONS OF THE LOWEN PARTIES. AS A RESULT, THE ABOVE LIMITATION OR EXCLUSION MAY NOT
          APPLY
          TO YOU.</p>
        <h3 class="uk-h4">9. ADDITIONAL GOVERNING TERMS AND CONDITIONS</h3>
        <p>Services may contain additional disclosures, terms and conditions or other legal terms that apply to the
          goods
          and services being offered on the website. Wherever the services present additional terms and conditions,
          disclosures, or other agreements, you are also bound by those additional terms and, wherein specific conflict
          with the terms and conditions of this Agreement, those additional terms shall control over the terms in this
          Agreement.</p>
        <h3 class="uk-h4">10. TERMINATION OR RESTRICTION OF ACCESS</h3>
        <p>Lowen reserves the right, in its sole discretion, to terminate your access to any or all of Lowen’s websites
          or
          the Services, or to any portion thereof at any time, without notice.</p>
        <h3 class="uk-h4">11. INDEMNITY</h3>
        <p>You will indemnify and hold the Lowen Parties (the “Indemnified Parties”) harmless from any breach of this
          Agreement by you, including any use of the Services or Content other than as expressly authorized in this
          Agreement. You agree that the Lowen Parties will have no liability in connection with any such breach or
          unauthorized use, and you agree to indemnify any and all resulting loss, damages, judgments, awards, costs,
          settlements, expenses, and attorney’s fees of the Lowen Parties in relation to your breach. You will also
          indemnify, including any and all resulting loss, damages, judgments, awards, costs, settlements, expenses, and
          attorney’s fees of the Lowen Parties, and hold the Lowen Parties harmless from and against any claims brought
          by
          third parties arising out of your breach of this Agreement or use of the Services and its Content, except to
          the
          extent such claim is due to the negligence or willful misconduct of Lowen.</p>
        <h3 class="uk-h4">12. TRADEMARKS AND COPYRIGHTS</h3>
        <p>Trademarks, service marks, logos, and copyrighted works appearing in the Services are the property of Lowen
          or
          the party that provided the trademarks, services marks, logos, and copyrighted works to Lowen. Lowen and any
          party that provided trademarks, service marks, logos, and copyrighted works to Lowen retain all rights with
          respect to any of their respective trademarks, service marks, logos, and copyrighted works appearing in the
          Services.</p>
        <p>All Content is: Copyright © 2021 Lowen Corporation, All rights reserved.</p>
        <h3 class="uk-h4">13. SECURITY</h3>
        <p>Any passwords used for the Services including, without limitation, any customer profiles used in relation to
          the online store are for individual use only. You will be responsible for the security of your password (if
          any). Lowen will be entitled to monitor your password and, at its discretion, require you to change it. If you
          use a password that Lowen considers insecure, Lowen will be entitled to require the password to be changed
          and/or terminate your account.</p>
        <p>You are prohibited from using any Services to compromise our security or tamper with system resources and/or
          accounts. The use or distribution of tools designed for compromising security (e.g., password guessing
          programs,
          cracking tools, or network probing tools) is strictly prohibited. If you become involved in any violation of
          system security related to our Services, Lowen reserves the right to release your details to system
          administrators at other websites in order to assist them in resolving security incidents.</p>
        <p>Lowen reserves the right to fully cooperate with any law enforcement authorities, subpoena, investigations,
          or
          court order requesting or directing Lowen to disclose the identity of anyone using the Services or information
          regarding the use of the Services. BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD HARMLESS LOWEN FROM ANY
          CLAIMS
          RESULTING FROM ANY ACTION TAKEN BY LOWEN DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS
          TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER LOWEN OR LAW ENFORCEMENT AUTHORITIES, OR LOWEN'S
          COOPERATION
          WITH COURT ORDERS OR SUBPOENAS TO THE FULL EXTENT SUCH WAIVER IS ALLOWED BY LAW.</p>
        <h3 class="uk-h4">14. MISCELLANEOUS</h3>
        <p>The laws of the State of Kansas shall apply to this Agreement, without regard to any conflict of laws
          provisions. The 1980 United Nations Convention on Contracts for the International Sale of Goods, the United
          Nations Convention on the Limitation Period in the International Sale of Goods, and the Uniform Computer
          Information Transactions Act, and any implementations thereof in various jurisdictions and any subsequent
          revisions thereto, shall not apply to this Agreement. Any controversy or claim arising out of or relating to
          this Agreement or your use of the Services including without limitation the Content (a “Dispute”) shall be
          settled by arbitration under the rules provided by the American Arbitration Association (AAA), and you hereby
          waive any right you may otherwise have to a jury trial. A single arbitrator shall preside over any
          arbitration,
          and such arbitrator shall, in his or her sole discretion, determine the arbitrability of any alleged Dispute.
          Any arbitration shall be conducted in Hutchinson, Kansas. To the fullest extent permitted by applicable law,
          no
          arbitration under this Agreement will be joined to an arbitration involving any other party subject to this
          Agreement, whether through class arbitration proceedings or otherwise. Judgment on an award rendered by an
          arbitrator may be entered in any state or federal court within or without the State of Kansas. Notwithstanding
          the foregoing, in lieu of or addition to any other remedies available to Lowen, Lowen may seek injunctive or
          other relief, including without limitation a suit for money damages, in any state, federal, or national court
          of
          competent jurisdiction for any actual or alleged infringement of Lowen’s or any third party’s intellectual
          property or proprietary rights. You hereby irrevocably consent to non-exclusive jurisdiction and venue of the
          State and Federal courts of the State of Kansas with respect to any such injunctive or other relief. You
          further
          acknowledge that Lowen’s rights in its intellectual property are of a special, unique, extraordinary
          character,
          giving those rights peculiar value, the unauthorized use, disclosure, or loss of which cannot be readily
          estimated and may not be adequately compensated for in monetary damages.</p>
        <p>If any part of this Agreement is unlawful, void, or unenforceable, that part will be deemed severable, shall
          be
          modified by the arbitrator or, where an action in court is allowed as stated in this Section of the Agreement,
          court of competent jurisdiction to reflect to the maximum extent possible the original intention of the
          parties
          as dictated by the original wording, and will not affect the validity and enforceability of any remaining
          provisions.</p>
        <p>No waiver by Lowen of any breach or default hereunder shall be deemed to be a waiver of any preceding or
          subsequent breach or default.</p>
        <p>No rights, duties, agreements or obligations hereunder, may be assigned or transferred by operation of law,
          merger or otherwise, without the prior written consent of Lowen.</p>
        <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and Lowen
          as a result of this agreement or use of Lowen’s Services.</p>
        <p>This Agreement constitutes the entire agreement among the parties relating to this subject matter and
          supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written
          between the user and Lowen with respect to the Services including without limitation the Content.</p>
        <p>Lowen may revise this Agreement at any time by updating this posting.</p>
      </section>
      <section class="uk-section">
        <h2 class="uk-h2 uk-text-center">CONDITIONS OF ORDER</h2>
        <p><strong>All orders placed with Lowen are also subject to the terms of these Conditions of Order. Any
          purported
          change submitted by a purchaser in any additional documentation is hereby expressly rejected. These Conditions
          of
          Order are in addition to any terms and conditions provided by any third party payment processor.</strong></p>
        <p><strong>1. Order Validation and Acceptance.</strong> When you place an order, we may verify your method of
          payment, contact information, and/or tax exempt identification number, if any, before processing your order.
          Your
          placement of an order with Lowen is an acceptance of Lowen’s offer to sell our Services. Lowen, at its
          discretion,
          may complete your order by processing your payment or may, for any reason, decline to complete your order or
          any
          part of your order.</p>
        <p>Lowen recommends and requires print ready art. You are fully responsible for the creation and proofing of all
          content; Lowen does not make any changes to Customer files. However, Lowen will attempt to discover and
          correct
          obvious errors (such as typographical, punctuation, spelling errors, errant font size or type, etc.) shown in
          art
          files. Lowen will not be held liable for failure to correct your errors or for making corrections to your
          errors.</p>
        <p><strong>2. Turnaround Time. </strong>24 Hour Turnaround: Most orders placed by 3:00pm CST will ship the
          following
          business day. Custom jobs or jobs that require color matching or proofs won’t be considered as a 24-Hour
          Turnaround job and may require additional production time. In addition, large orders may require additional
          processing time.</p>
        <p>Note that it is “most orders” that are available for 24 Hour Turnaround; however, we do not guarantee that
          your
          job will go out the next business day. While we do make every accommodation to honor this turnaround, various
          factors such as problems with art files, equipment failures, technical difficulties, etc., will have an effect
          on
          the 24 hour turnaround time.</p>
        <p><strong>3. Color Proofing & Matching. </strong>Standard order processing does not include color matching or
          proofing. If you would like for us to color match or manipulate your art in any way, shape, or form, please
          select
          that service option on the Lowen website as we do offer color matching services. However, if you choose the
          color
          matching service, there will be a fee and order turnaround time will no longer be considered 24 hours. To
          learn
          more about what this service entails, please see “What are Color Matching Services” in the FAQ section in the
          resources tab on the Lowen TradeSource Website.</p>
        <p>PMS color matching and customer proof approval are available upon request. When ordering, if customer
          requests a
          specific PMS color from the PMS Solid Coated Plus Library, every reasonable effort will be made to match that
          color. Customer may also provide a physical sample for color match. Lowen is not liable for color matching or
          proofing files without color match specified in the order process. Reduced image proofs are available up to
          12” x
          52”.</p>
        <p>Due to differences in equipment, print media and inks, and other conditions between color proofing and
          production
          operations, a reasonable variation in color between color proofs and the completed job is expected,
          unavoidable
          and shall constitute an acceptable delivery. Lowen will make every effort to produce a reasonable
          representation
          based on samples supplied by the customer; however, Lowen does not guarantee a “perfect match.” From time to
          time,
          there is a misrepresentation of color due to soft proofing because of calibration of one monitor to
          another.</p>
        <p><strong>4. Electronic Communication. </strong>When you place an order via the Services, you are required to
          provide valid contact information we may use to communicate with you regarding the status of your order and to
          provide you with any other notices, disclosures or other communications relating to your order. You agree that
          Lowen will not be responsible for any damage you incur, or information you do not receive, as a result of your
          failure to provide and maintain valid contact information or as a result of any failure of your e-mail system,
          spam filters, or other impediments to your receipt of our communications outside of the reasonable control of
          Lowen.</p>
        <p><strong>5. Pricing and Product Information. </strong>Lowen makes every effort to provide current and accurate
          information relating to the Services and prices, which are reflected in the prices shown on its website or
          otherwise within the Services. Information relating to Services is subject to change without notice. Prices
          are
          subject to change at any time prior to Lowen’s charging you for your order. The square foot pricing is
          dependent
          on the full web width for the roll required to produce your order. In other words, the price is based on the
          actual square footage of material used. In the event we discover a material error in pricing, we will notify
          you
          of the corrected version, and you may choose to accept the corrected version, or cancel the order. If you
          choose
          to cancel the order, and your credit card has already been charged for a purchase, Lowen will issue a credit
          to
          your credit card in the amount of the charge. All prices are in U.S. dollars.</p>
        <p><strong>6. Payment. </strong>Payment is taken through our third party payment processors and may be made
          through
          the payment forms offered through that processor at checkout. Lowen will not process any order until it has
          received payment; credit cards are charged when orders are placed, not when they ship.</p>
        <p><strong>7. Taxes. </strong>Except as otherwise stated, prices shown do not include any federal, state or
          local
          taxes, or any other taxes or charges imposed by any government authority, including, without limitation,
          sales,
          use, excise, value-added or similar taxes. Where applicable, such taxes and charges may be billed as a
          separate
          item and paid by you. Orders are accepted with the understanding that such taxes and charges shall be added,
          as
          required by law. Lowen may charge applicable sales tax unless you have a valid sales tax exemption certificate
          on
          file with Lowen. Lowen will not refund tax amounts collected in the event a valid sales tax certificate is not
          provided. If you provide a certificate that is not accepted for any reason by any governmental or regulatory
          authority, and Lowen is required to pay tax on your purchase, you will reimburse Lowen for the amount of such
          tax,
          and Lowen’s reasonable expenses incurred in connection with the payment and collection of such tax.</p>
        <p><strong>8. Dishonored and Late Payments. </strong>You shall pay to Lowen all costs incurred by Lowen in
          collecting on any dishonored payment or on any past due amount from you, including all court costs, collection
          costs, and attorney’s fees. If a payment method you give us for payment is dishonored or for any reason by the
          bank or other institution on which it is drawn, you agree to pay Lowen $30.00 as a service charge (if the
          payment
          cannot be redeposited) pursuant to K.S.A. § 16a-2-501.</p>
        <p><strong>9. Export Compliance. </strong>The Lowen Services are for United States use only. You agree to comply
          with all applicable export control laws, restrictions, regulations and orders of the United States or
          applicable
          foreign agencies or authorities. You shall not, directly or indirectly, sell, export, transfer, transship,
          assign,
          use, or dispose of Services in a manner which may result in any non-compliance with applicable export control
          laws, restrictions, regulations, and orders of the United States or applicable foreign agencies or
          authorities.
          You are responsible for obtaining any license or other official authorizations that may be required to export,
          re-export or import Services. Diversion contrary to U.S. law is prohibited.</p>
        <p><strong>10. Return Policy. </strong>ALL SALES ARE FINAL.</p>
        <p><strong>11. Output Rights. </strong>You shall retain ownership of the information you input into the Services
          ("Your Input Data"). The output from your purchased Services will be a document in the form of a pdf or other
          file
          which may change from time to time in Lowen's discretion (the "Output"). Such Output is Content and therefore
          owned by Lowen, excluding Your Input Data, and the Output is subject to the restrictions set forth in the
          Terms of
          Use including without limitation in Section 4. Lowen hereby grants to you a limited, non-exclusive,
          terminable,
          non-sublicensable, royalty-free license to the Output solely for your internal use and to share a copy of the
          Output with third party vendors solely for their use in conjunction with you and for your benefit. You may not
          sell, rent, license, commercialize, or otherwise distribute the Output. The Services and the Output may
          contain
          proprietary methodologies, work flows, pictorial navigation, and other elements that, for the avoidance of
          doubt,
          may not be reproduced or recreated without the express written approval of Lowen. All rights not granted
          herein
          are expressly reserved by Lowen.</p>
        <p><strong>12. Order Shipping and Delivery. </strong>Lost or damaged shipments must be reported to Lowen
          immediately
          upon discovery. Lowen is not responsible for third-party shipping errors, omissions, or damaged shipments.</p>
        <p><strong>13. Force Majeure. </strong>Lowen will not be liable for delays in delivery or for failure to perform
          its
          obligations due to causes beyond its reasonable control including, but not limited to, product allocations,
          material shortages, labor disputes, transportation delays, unforeseen circumstances, acts of God, acts or
          omissions of other parties, acts or omissions of civil or military authorities, Government priorities, fires,
          strikes, floods, severe weather conditions, computer interruptions, terrorism, epidemics, quarantine
          restrictions,
          riots or war. Lowen’s time for delivery or performance will be extended by the period of such delay or Lowen
          may,
          at its option, cancel any order or remaining part thereof, without liability, by giving notice to you.</p>
        <p class="uk-text-meta">Last Modified: September 10, 2021</p>
    </section>
    </div>
  </div>
</template>