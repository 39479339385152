<script setup>
import {utilsMixin} from '../../helpers/utils';
</script>
<script>
export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      lowenCertifiedURL: process.env.LowenCertifiedURL,
      lowenColorGraphicsURL: process.env.LowenColorGraphicsdURL,
      lowenCorpURL: process.env.LowenCorpURL,
      NGSCorpURL: process.env.NGSCorpURL,
      franchise: process.env.Franchise,
    };
  },
  mixins: [utilsMixin],
}
</script>
<template>
  <div id="Footer"
       class="uk-padding uk-padding-remove@m uk-margin-remove uk-flex uk-flex-around@m uk-align-middle uk-child-width-1-3@m" uk-grid>
    <div id="LowenCorpContactSocial" class="uk-padding-remove uk-text-center uk-text-left@m">
      <div>
        <router-link v-if="this.franchise === 'Decor'" :to="{ name: 'home'}">
          <img src="/public/logos/DecorIcon.png" alt="Decor Renaissance">
        </router-link>
        <a v-if="facebookURL()" class="uk-icon-button uk-margin-right" :href="facebookURL()"
           uk-icon="icon: facebook; ratio: 1.15" target="_blank"></a>
        <a v-if="instagramURL()" class="uk-icon-button uk-margin-right" :href="instagramURL()"
           uk-icon="icon: instagram; ratio: 1.15" target="_blank"></a>
        <a v-if="twitterURL()" class="uk-icon-button uk-margin-right" :href="twitterURL()"
           uk-icon="icon: twitter; ratio: 1.15" target="_blank"></a>
        <a v-if="linkedinURL()" class="uk-icon-button" :href="linkedinURL()"
           uk-icon="icon: linkedin; ratio: 1.15" target="_blank"></a>
      </div>
      <p>
        <span v-if="address1()" v-html="address1()"></span>
        <br>
        <span v-if="address2()">{{ address2() }}</span>
        <br>
        <span v-if="address3()">{{ address3() }}</span>
      </p>
    </div>
    <div id="ContactInformation" class="uk-padding uk-padding-remove-top uk-text-center">
      <h2 v-if="contactPhone()" class="uk-h1 uk-text-primary uk-text-bold uk-link-text"><a
          :href="contactPhone()">{{ contactPhone() }}</a></h2>
      <button v-if="this.franchise === 'Wholesale'"
              class="uk-button uk-button-primary contact-button"
              uk-toggle="target: #ContactModal"><span
          uk-icon="icon: mail"></span>&nbsp;Contact Lowen Tradesource
      </button>
    </div>
    <div v-if="this.franchise === 'Wholesale'" id="TermsAndHelp"
         class="uk-padding-large uk-padding-remove-top uk-text-center">
      <ul class="uk-nav uk-nav-primary uk-nav-center uk-nav-divider">
        <li class="">
          <router-link :to="{name: 'terms'}">Terms of Use</router-link>
        </li>
        <li>
          <router-link :to="{name: 'resources'}">Resources</router-link>
        </li>
        <li>
          <router-link :to="{name: 'help'}">Help</router-link>
        </li>
      </ul>
    </div>
<!--    <div v-if="this.franchise === 'Wholesale'"
         class="uk-child-width-1-3@m uk-padding-remove uk-width-1-4@m uk-text-center uk-text-left@m uk-flex uk-flex-middle"
         uk-grid>
      <div>
        <a :href="this.lowenCertifiedURL" class="uk-inline-block" target="_blank">
          <img src="/public/logos/CertifiedLogo.png" class="footerCorpLogo" alt="Lowen Certified">
        </a>
      </div>
      &lt;!&ndash;        FRN can't use uk-svg here. Bad svgs. See https://stackoverflow.com/questions/46234957/svg-styles-interfering-with-each-other&ndash;&gt;
      <div>
        <a :href="this.lowenColorGraphicsURL" class="uk-inline-block" target="_blank">
          <img src="/public/logos/LowenCGLogo.svg" class="footerCorpLogo" alt="Lowen Color Graphics">
        </a>
      </div>
      <div>
        <a :href="this.lowenCorpURL" class="uk-inline-block" target="_blank">
          <img src="/public/logos/LowenCorpLogo.svg" class="footerCorpLogo" alt="Lowen Corporation">
        </a>
      </div>
    </div>-->
  </div>
</template>