<script setup>
import {RouterLink, RouterView} from "vue-router";
import TheMenu from "./TheMenu.vue";
import TheFooter from "./TheFooter.vue";

defineProps({
  branding: String
})
</script>
<script>
export default {
  beforeCreate() {
    this.$nextTick(() => {
      const JwtToken = localStorage.getItem("JwtToken");
      this.JWT = JwtToken;
      // log("JWT beforeCreate: ", this.JWT);
    })
  },
  data() {
    return {
      JWT: null,
      isAuthenticated: false,
      franchise: process.env.Franchise
    };
  },
  components: {
    "menu-navigation": TheMenu,
    "footer-navigation": TheFooter,
  },
  mounted() {
    // log("FranchiseApp api", process.env.APIURL);
    // log("FranchiseApp franchise", process.env.Franchise);
  },
  computed: {},
  methods: {},
};
</script>

<template>
  <div id="PrimaryLayout">
    <div id="MenuContainer" class="menu-nav">
      <!-- FRN one way to pass franchise to components -->
      <menu-navigation/>
    </div>
    <section id="MainBodySection"
             class="uk-padding-remove">
      <div id="MainBodyContainer" class="mainbody" uk-height-viewport="expand: true">
        <RouterView/>
      </div>
    </section>

    <section id="GlobalFooter" class="uk-section uk-margin-small uk-section-xsmall">
      <footer-navigation/>
    </section>
  </div>
</template>
