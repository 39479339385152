﻿<script setup>
import {router} from '../../helpers/router';
import {authHeader} from '../../helpers/authheader';
import {useAuthStore} from '../../stores/auth.store.js';
import Loading from "./Loading.vue";

import UIkit from 'uikit';
</script>
<script>
export default {
  data() {
    return {
      hideButtons: true,
      editMessage: "",
      showMessage: false,
      isError: false,
      isLoading: true,
      isValid: true,
      pantoneDetails: {},
      pantoneProducts: {},
      chartProduct: {},
      activeProduct: "",
      chartFinish: "SELECT",
      baseUrl: process.env.APIURL + "/Store"
    };
  },
  mounted() {
    log("PantoneChartComponent Mounted");
    let _pantonethis = this;
    UIkit.util.on("#PantoneChartModal", "beforeshow", function () {
      _pantonethis.getPantoneChartDetails();
      _pantonethis.getPantoneChartProducts();
    });
  },
  methods: {
    assignProductType(id) {
      log("assignProductType");
      this.activeProduct = id;
      this.chartProduct = _.find(this.pantoneProducts, ["Id", id]);
      //log(this.chartProduct);
    },
    async getPantoneChartDetails() {
      log("getPantoneChartDetails");
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      };
      const url = this.baseUrl + "/GetPantoneChartDetails";
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const pDetails = await response.json();
      // console.log("pDetails", pDetails);
      if (pDetails.Status === "ok") {
        this.isLoading = false;
        // log("pantoneDetails: ", pDetails.Data);
        this.pantoneDetails = pDetails.Data[0];
      }
      log("pantoneDetails: ", this.pantoneDetails);
      if(this.pantoneDetails === undefined) {
        this.isLoading = false;
        this.editMessage = "Unable to get Chart Details.";
        this.showMessage = true;
      }
      if (pDetails.Status === "exception") {
        this.isLoading = false;
        this.editMessage = pDetails.Message;
        this.showMessage = true;
      }
    },
    async getPantoneChartProducts() {
      log("getPantoneChartProducts");
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      };
      const url = this.baseUrl + "/GetPantoneChartProducts";
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const pDetails = await response.json();
      // console.log("pDetails", pDetails);
      if (pDetails.Status === "ok") {
        this.isLoading = false;
        this.pantoneProducts = pDetails.Data;
      }
      log("pantoneProducts: ", this.pantoneProducts);

      if (pDetails.Status === "exception") {
        this.isLoading = false;
        this.editMessage = pDetails.Message;
        this.showMessage = true;
      }
    },
    addPantone() {
      log("addPantone", this.pantoneDetails);
      // log("addPantone", this.chartProduct);
      const authStore = useAuthStore();
      const user = authStore.user;
      const url = this.baseUrl + "/AddToCart";
      // Because we really don't get what we need, until we have Service and Products.
      const data = [{
        userId: user.Id,
        Quantity: 1,
        LineNumber: 0,
        CartID: 0,
        SetId: 0,
        SetName: "",
        ContourCutFile: "",
        StretchCenter: true, // false becomes "Fit" true becomes "Stretch" in bullseye. Why this was Center??
        ColorMatching: "false", // this is a string in CartItem, so I am passing false as a string.
        FullUrl: this.pantoneDetails.FullUrl,
        Id: this.pantoneDetails.Id,
        InchHeight: this.pantoneDetails.InchHeight,
        InchWidth: this.pantoneDetails.InchWidth,
        ImageUrl: this.pantoneDetails.ImageUrl,
        ItemDesc: "Pantone Chart - " + this.chartProduct.Id,
        ItemNo: this.pantoneDetails.Id,
        // ItemNo: this.chartProduct.Id,
        Name: this.chartProduct.Name,
        Price: this.chartProduct.Price,
        Material: this.chartProduct.Id,
        MaterialText: "Pantone Chart - " + this.chartProduct.Id,
        HasClearCoat: this.chartFinish == "CLEAR" ? true : false,
        LaminateType: this.chartFinish,
      }];
      //log("pantone data", data);
      if (this.chartFinish === "SELECT") { this.isValid = false; }
      if (this.chartProduct.Name === undefined) { this.isValid = false; }
      if (this.chartFinish !== "SELECT" && this.chartProduct.Name !== undefined) { this.isValid = true; }
      if (this.isValid) {
        fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(data)
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        const returnedData = jsondata;
        // log(returnedData);
        if (returnedData.Status === "ok") {
          //log("Success!!")
          UIkit.modal("#PantoneChartModal").hide();
          router.go(0);
        } else {
          //log("Show Error Message");
          this.editMessage = "Unable to add Pantone Chart.";
          this.showMessage = true;
          this.isError = true;
        }
      });
      } else {
        this.editMessage = "Unable to add Pantone Chart. Please make sure Material and Finish Type are selected.";
        this.showMessage = true;
        this.isError = true;
      }
      
    }
  }
}
</script>

<template id="PantoneChartTemplate">
  <div class="uk-modal-dialog">
    <div class="uk-modal-body">
      <h3 class="uk-modal-title">Pantone Chart</h3>
      <hr/>
      <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}"
           class="uk-alert" v-html="editMessage"></div>

      <Loading v-if="isLoading" />

      <div v-if="!isLoading">
        <div class="uk-text-center uk-flex uk-flex-center uk-flex-around uk-margin-small">
          <div v-for="(prod, k) in pantoneProducts" v-bind:class="{active: activeProduct == prod.Id}"
               class="pantone-product-select uk-width-1-3">
            <a v-bind:data-productId="prod.Id" v-on:click="assignProductType(prod.Id)" class="uk-link uk-link-text">
              <img v-bind:data-productId="prod.Id" v-bind:src="prod.Image" v-bind:alt="prod.Name">
              {{ prod.ProductName }}
            </a>
          </div>
        </div>
        <div class="uk-margin-small">
          <select class="uk-select" name="PantoneChartFinishMethod" id="PantoneChartFinishMethod" v-model="chartFinish">
            <option value="SELECT">Select</option>
            <option value="LUSTER">Luster</option>
            <option value="GLOSS">Gloss</option>
            <option value="CLEAR">Clear Coat</option>
            <option value="MATTE">Matte</option>
          </select>
        </div>
        <div class="uk-text-right" uk-grid>

          <div>
            <button v-on:click="addPantone" class="uk-button uk-button-primary uk-margin-small-right" type="button">Add
              Chart
            </button>
          </div>

          <div>
            <button class="uk-button uk-button-link uk-modal-close" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>