<script setup>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import TileMenu from "./Components/TileMenu.vue";
import {utilsMixin} from '../helpers/utils';
</script>
<script type="module">
import {utilsMixin} from "../helpers/utils";

export default {
  mixins: [utilsMixin],
  data() {
    return {
      baseUrl: process.env.APIURL,
      authStore: useAuthStore(),
      franchise: process.env.Franchise
    };
  },
  mounted() {
    console.log("EPD is mounted");
    UIkit.use(Icons);
  },
  computed: {
    showLogin() {
      let returnValue = false;
      if (localStorage.getItem("user") == null) {
        returnValue = true;
      }
      return returnValue;
    },
  },
  methods: {}
};
</script>
<template>
  <!-- Jumbotron -->
  <section id="Jumbotron" class="uk-padding-remove">
    <div class="home-hero" uk-grid>
      <div class="uk-width-1-1 uk-flex uk-flex-middle uk-padding-remove">
        <div class="uk-align-center uk-width-3-5">
            <div class="uk-flex uk-flex-center uk-flex-wrap">
                <div class="epd-text uk-background-contain uk-margin-small-right" data-src="/public/logos/EPDIcon.png" uk-img></div>
                <h2 class="uk-heading-small uk-text-center uk-text-emphasis uk-margin-remove-top uk-margin-right">Print On-Demand Graphics</h2>
            </div>
          <div v-if="isHolidayMessage" class="uk-margin-bottom">
            <div v-if="isHolidayMessage" class="uk-card uk-card-primary">
              <div class="uk-card-body uk-text-bolder" style="color: #fff;" v-html="holidayMessage"></div>
            </div>
          </div>
          <div v-if="!authStore.user" class="uk-text-center">
            <button v-if="showLogin"
                    class="uk-button uk-button-primary dr-button uk-width-1-2 uk-margin-horizontal uk-margin-right"
                    uk-toggle="target: #LoginModal"><span
                uk-icon="icon: lock"></span>&nbsp;Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Container -->
</template>
