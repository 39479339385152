<script setup>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {authHeader} from "../../helpers/authheader";
</script>

<script>
export default {
  data() {
    return {
      Name: "",
      NickName: "",
      BusinessName: "",
      Email: "",
      PhoneNumber: "",
      Message: "",
      RequiredFields: [
        "Name", "Email", "Message"
      ],
      ConfirmationCheck: false,
      ContactModel: {},
      ValidForm: false,
      DisplayMessage: "",
      formError: false,
      formMessage: false,
      completedSubmit: false,
      baseUrl: process.env.APIURL
    }
  },
  mounted() {
    log("ContactComponent is mounted");
  },
  methods: {
    async submitForm() {
      log("Contact form submit");
      this.DisplayMessage = "";
      this.formMessage = false;
      this.formError = false;
      this.ContactModel = {
        Name: this.Name,
        NickName: this.NickName,
        BusinessName: this.BusinessName,
        Email: this.Email,
        PhoneNumber: this.Phone,
        Message: this.Message,
      }
      log("ContactModel", this.ContactModel);
      if (this.checkForm()) {
        log("sending contactModel");
        const url = this.baseUrl + "/Account/Contact";
        const response = await fetch(url, {
          method: "POST",
          headers: authHeader(url),
          body: JSON.stringify(this.ContactModel)
        });
        let result = await response.json();
        log("Contact result: ", result);
        if (result.Status === "ok") {
          this.completedSubmit = true;
          this.DisplayMessage = "Thank you for considering Lowen Tradesource for your wholesale graphic needs. Your request has been sent, and we will be in touch shortly.";
          this.formMessage = true;
          this.formError = false;
          this.completedSubmit = true;
        }
        if (result.Status === "failed") {
          this.DisplayMessage = result.Message;
          this.formMessage = true;
          this.formError = true;
        }
        if (result.Status === "exception") {
          this.formError = true;
          this.DisplayMessage = "There was an error submitting your form, please try again.";
          this.formMessage = true;
        }
      }
    },
    checkForm: function () {
      log("checkForm");
      let _this = this;
      // reset
      _this.ValidForm = true;
      _this.DisplayMessage = "";
      _this.formMessage = false;
      // Validate text fields
      _.forEach(_this.ContactModel, function (prop, key) {
        let isReq = _.indexOf(_this.RequiredFields, key);
        if (isReq > -1) {
          if (_.isEmpty(prop)) {
            _this.DisplayMessage = _this.DisplayMessage + key + " is required.<br>";
          }
        }
      });
      // Validate Honeypot
      if (_this.NickName.length > 0) {
        _this.ValidForm = false;
        _this.formMessage = true;
        _this.formError = true;
        _this.DisplayMessage += "Unable to submit this form.";
      }
      // log("checkForm result", _this.ValidForm);
      return _this.ValidForm;
    },
  }
}
</script>

<template>
  <section id="Contact" class="uk-section uk-section-default">
    <div class="uk-container uk-container-expand">
      <h1>Contact Lowen Tradesource</h1>
      <section id="Identity" class="uk-section uk-section-muted uk-padding-small">
        <div class="uk-margin full-name">
          <label for="txtName" class="uk-form-label">Name*</label>
          <div class="uk-form-controls">
            <input type="text" name="txtName" id="txtName" v-model="Name" placeholder="Full Name"
                   class="uk-input"/>
          </div>
        </div>

        <div class="uk-margin business-name">
          <label for="txtBusinessName" class="uk-form-label">Business Name</label>
          <div class="uk-form-controls">
            <input type="text" name="txtBusinessName" id="txtBusinessName" v-model="BusinessName"
                   placeholder="Business Name"
                   class="uk-input"/>
          </div>
        </div>

        <div class="uk-margin email">
          <label for="txtEmail" class="uk-form-label">E-Mail*</label>
          <div class="uk-form-controls">
            <input type="text" name="txtEmail" id="txtEmail" v-model="Email" placeholder="Email" class="uk-input"/>
          </div>
        </div>

        <div class="uk-margin phone number">
          <label for="txtPhone" class="uk-form-label">Phone Number</label>
          <div class="uk-form-controls">
            <input type="text" name="txtPhone" id="txtPhone" v-model="PhoneNumber" placeholder="Phone Number"
                   class="uk-input"/>
          </div>
        </div>

        <div class="uk-margin message">
          <label for="txtMessage" class="uk-form-label">Message*</label>
          <div class="uk-form-controls">
            <textarea cols="30" rows="10" name="txtMessage" id="txtMessage" v-model="Message"
                      class="uk-textarea"></textarea>
          </div>
        </div>

        <div class="uk-margin nick-name">
          <label for="txtNickName" class="uk-form-label">Nick Name*</label>
          <div class="uk-form-controls">
            <input type="text" name="txtNickName" id="txtNickName" v-model="NickName" placeholder="Nick Name"
                   class="uk-input"/>
          </div>
        </div>
      </section>
        <div id="StatusMessages" class="uk-margin-top">
          <div v-if="formMessage" v-bind:class="{ 'uk-alert-warning': formError, 'uk-alert-primary': !formError }"
               class="uk-alert"
               v-html="DisplayMessage" uk-alert></div>
        </div>
        <div v-if="!completedSubmit" id="Buttons" class="uk-margin-top" uk-grid>
          <div class="uk-width-1-2">
            <input id="SubmitContact" type="button" class="uk-button uk-button-primary" value="Submit"
                   v-on:click="submitForm"/>
          </div>
          <div class="uk-width-1-2">
            <input type="button" class="uk-button uk-button-danger uk-modal-close" value="Cancel"/>
          </div>
        </div>
        <div v-if="completedSubmit" id="Close" class="uk-margin-top" uk-grid>
          <div class="uk-width-1-2">
            <button class="uk-button uk-button-primary uk-modal-close">Close</button>
          </div>
        </div>
    </div>
  </section>
</template>