<script setup>
import {utilsMixin} from '../../helpers/utils';
</script>
<script>
export default {
  mixins: [utilsMixin],
  props: ['images', 'ui'],
  emits: ['deleteimage', 'selectimage', 'changecount'],
  methods: {
    itemQtyId(id) {
      return 'Qty' + id;
    },
  },
  computed: {
    // showPrice() {
    //   return this.showPortalPrice();
    // }
  },
  mounted() {
    log("ProjectLibraryComponent is Loaded");
  }
};
</script>

<template>
  <div v-for="(projectItem, index) in images"
       :key="projectItem.Id">
    <div class="uk-margin">
      <div v-bind:id="projectItem.Id" class="library-card uk-card uk-card-small uk-card-hover"
           v-bind:class="{'uk-card-default': ui.selectedImage == projectItem.Id}" v-bind:data-index="index"
           v-on:click="$emit('selectimage', index)">
        <div class="uk-card-body">
          <div id="FileNameText" class="uk-text-bold uk-text-center uk-margin-small-bottom">{{ projectItem.Name }}</div>
          <div class="uk-margin-small-bottom">
            <img v-bind:src="projectItem.base64String" class="uk-width-small">
          </div>
          <div uk-grid>
            <div class="uk-width-1-2 uk-text-center">
              <label v-bind:for="itemQtyId(projectItem.Id)"
                     class="uk-text-bold uk-float-left uk-margin-small-right">Qty:</label>
              <input type="text" v-bind:id="itemQtyId(projectItem.Id)"
                     class="uk-input uk-form-width-xsmall uk-form-small uk-float-left"
                     v-on:change="$emit('changecount', index, $event)" v-bind:value="projectItem.Quantity">
            </div>
            <div class="uk-width-1-2 uk-text-center">
              <button v-if="projectItem.Id" type="button" class="uk-button uk-button-danger"
                      v-on:click="$emit('deleteimage', index)" uk-icon="trash">
              </button>
            </div>
          </div>
          <div v-if="showPrice">
          <span v-if="projectItem.Price">{{
              projectItem.Price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })
            }}</span>
          </div>
          <div class="uk-text-normal uk-text-center">
            <span v-if="projectItem.rigid_type">{{ projectItem.RigidText }}</span>
            <span v-if="projectItem.rigid_type"> / </span>
            <span v-if="projectItem.ItemNo">{{ projectItem.MaterialText }}</span>
            <span v-if="projectItem.LaminateType"> / </span>
            <span v-if="projectItem.LaminateType">{{ projectItem.FinishText }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>