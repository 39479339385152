<script>
import UIkit from "uikit";
export default {
  data() {
    return {
        props: {
            isLoading: {
            type: Boolean,
            required: true
        }
        },
        name: 'Loading',
        franchise: process.env.Franchise,
        active: this.isLoading
    }
  },
  computed: {
    logoPath() {
        switch (this.franchise) {
            case "Wholesale":
                return "/public/logos/LTS_Logo.png";
            case "NGS":
                return "/public/logos/ASPIREBlack.png";
        }
    },
    logoName() {
        switch (this.franchise) {
            case "Wholesale":
                return "Lowen Tradesource";
            case "NGS":
                return "Aspire";
        }
    },
  }
}
</script>

      <!-- This needs some work on the css side.  Position get's out of wack on difference screen sizes.  
        Need to position based on screen size and pin down the loading text position. Not % based -->
<template>
    <div class="loading-animation-container uk-flex uk-flex-column uk-flex-middle uk-margin">
        <div class="loading-animation" uk-spinner="ratio: 10">
        </div>
        <img class="animation-img" :src="logoPath" :alt="logoName">
        <div class="loading-txt">Loading . . .</div>
    </div>
</template>