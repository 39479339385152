export const OrderDetails = {
    methods: {
        hasPublicUrl(item) {
            let returnValue = false;
            const imageUrl = item.ImageUrl;
            // log("hasPublicUrl", imageUrl);
            if (imageUrl.includes("\/public\/")) {
                if (item.product_type === "misc") {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        hasUrlType(item) {
            let returnValue = 'base64';
            const imageUrl = item.ImageUrl;
            if (imageUrl.includes("\/public\/")) {
                // if (item.product_type === "misc") {
                returnValue = 'public';
                // }
            }
            // console.log("hasUrlType", item);
            // console.log("hasUrlType return", returnValue);
            return returnValue;
        },
        ShowFinish(item) {
            //log("ShowFinish", item);
            let returnValue = false;
            if (item.FinishText) {
                if (item.FinishText.length > 0) {
                    returnValue = true;
                }
            }
            if (item.Filename === "PantoneChart") {
                returnValue = true;
            }
            return returnValue;
        },
        ShowFilename(item) {
            let returnValue = false;
            if (item.ItemNo === "TS-SVC-1") {
                returnValue = true;
            }
            return returnValue;
        },
        ShowContourCut(item) {
            let returnValue = true;
            // Always indicate if we have contour cutting or not.
            // To prevent confusion on order followup when CAD CUT tickets.
            /*if (item.ItemNo === "TS-WINDOWFILM") {
              returnValue = false;
            }
            if (item.product_type === "adhesive") {
              returnValue = true;
            }*/
            return returnValue;
        },
        ShowRigid(item) {
            let returnValue = false;
            if (item.product_type === "rigid") {
                returnValue = true;
            }
            return returnValue;
        },
        ShowSize(item) {
            let returnValue = true;
            if (item.ItemNo === "90OC" || item.product_type === "misc") {
                returnValue = false;
            }
            if (item.ImageUrl.includes("PatnLibrary")) {
                returnValue = false;
            }
            return returnValue;
        },
        ShowRollSize(item) {
            let returnValue = false;
            if (item.product_type === "Rolled") {
                returnValue = true;
            }
            return returnValue;
        },
        ShowSquareFt(item) {
            let returnValue = ""
            if (item.SquareFeet > 0) {
                returnValue = "(" + item.SquareFeet + " Square Ft.)";
                if (item.product_type === "rigid") {
                    returnValue = "(" + item.SquareFeet + " Sheets)";
                }
            }
            return returnValue;
        },
        ShowOverlap(item) {
            let returnValue = false;
            if (item.Overlap) {
                if (item.Overlap > 0) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        ShowImage(item) {
            // console.log("ShowImage");
            let returnValue = item.base64String;
            if (item.ItemNo === "TS-SVC-1") {
                returnValue = item.ImageUrl;
            }
            if (item.ItemNo === "TS-PMSC") {
                returnValue = item.ImageUrl;
            }
            return returnValue;
        },
        FinishText(item) {
            let returnValue = item.FinishText;
            if (item.FinishText) {
                if (item.ItemNo === "TS-PMSC") {
                    returnValue = item.LaminateType;
                }
            }
            if (item.Filename === "PantoneChart") {
                returnValue = item.LaminateType;
            }
            //log("FinishText return ", returnValue);
            return returnValue;
        },
        ContourCut(item) {
            let returnValue = "No contour cutting";
            if (item.ContourCutFile.length > 0) {
                // backward compat. No longer allow cut file uploads. Require them to be embedded.
                returnValue = "Has contour cut file: " + item.ContourCutFile;
                if (item.ContourCutFile === "embedded") {
                    returnValue = "Has embedded cut file";
                }
            }
            //log("ContourCut return ", returnValue);
            return returnValue;
        },
        getSvgDataURL(svgString) {
            const svgData = `data:image/svg+xml;base64,${btoa(svgString)}`;
            return svgData;
        },
    }
}