<script setup>
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {authHeader} from "../../helpers/authheader";
//import MASV from "https://massive.app/lib/masv-uploader-beta.min.js"
</script>

<script>
export default {
  data() {
    return {
      FirstName: "",
      LastName: "",
      BusinessName: "",
      BusinessType: "",
      BusinessTypeList: [
        "Sign Manufacturer", "Print/Sign Shop", "Print/Sign Broker", "Marketing/Design Firm", "Advertising Agency", "Event Production Company", "Installer"
      ],
      Email: "",
      Address: "",
      Address2: "",
      City: "",
      State: "",
      StateList: [],
      Zip: "",
      Country: "",
      CountryList: [],
      Phone: "",
      Password: "",
      ConfirmPassword: "",
      Website: "",
      HearAboutUs: "",
      HearAboutUsList: [
        "Online Search", "Facebook", "LinkedIn", "Instagram", "Email", "Referral", "Trade Publication Ad", "Tradeshow/Event", "Returning Customer", "Other"
      ],
      RequiredFields: [
        "FirstName", "LastName", "BusinessName", "Email", "Address", "City", "State", "Zip", "Country", "Phone", "Password", "TaxMethod"
      ],
      UploadTaxForm: false,
      EmailTaxForm: false,
      MailTaxForm: false,
      NotTaxExempt: false,
      AlreadyOnFile: false,
      TaxMethod: "",
      ConfirmationCheck: false,
      ApplyModel: {},
      ValidForm: false,
      DisplayMessage: "",
      formError: false,
      formMessage: false,
      UploadMessage: "",
      uploadError: "",
      uploadComplete: "",
      completedSubmit: false,
      showMessage: "",
      PackageID: "",
      PackageToken: "",
      baseUrl: process.env.APIURL,
      franchise: process.env.Franchise
    }
  },
  mounted() {
    log("ApplyComponent is mounted");
    let _this = this;
    _this.fillDropdowns();
  },
  methods: {
    fillDropdowns() {
      const url = this.baseUrl + "/Account/GetStateList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.StateList = jsondata;
      });
      const url2 = this.baseUrl + "/Account/GetCountryList";
      fetch(url2, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.CountryList = jsondata;
      });
    },
    async getToken() {
      log("getToken");
      const url = this.baseUrl + "/Library/apply_token";
      let response = await fetch(url,
          {
            method: "GET",
            headers: authHeader(url),
          }
      );
      let tokens = await response.json();
      // log("tokens", tokens);
      return {
        packageID: tokens.id,
        packageToken: tokens.access_token
      }
    },
    async submitForm() {
      log("Apply form submit");
      this.DisplayMessage = "";
      this.formMessage = false;
      this.formError = false;
      this.ApplyModel = {
        FirstName: this.FirstName,
        LastName: this.LastName,
        CompanyName: this.BusinessName,
        Email: this.Email,
        Address: this.Address,
        Address2: this.Address2,
        City: this.City,
        State: this.State,
        Zip: this.Zip,
        Country: this.Country,
        PhoneNumber: this.Phone,
        Password: this.Password,
        Website: this.Website,
        HearAboutUs: this.HearAboutUs,
        TaxIdMethod: this.TaxMethod,
        BusinessType: this.BusinessType
      }
      log("ApplyModel", this.ApplyModel);
      if (this.checkForm()) {
        log("sending applymodel");
        const url = this.baseUrl + "/Account/Apply";
        const response = await fetch(url, {
          method: "POST",
          headers: authHeader(url),
          body: JSON.stringify(this.ApplyModel)
        });
        let result = await response.json();
        // log("Apply result: ", result);
        if (result.Status === "ok") {
          this.completedSubmit = true;
          this.DisplayMessage = "Account created. You can Login with your new password.";
          this.formMessage = true;
          this.formError = false;
        }
        if (result.Status === "failed") {
          this.DisplayMessage = result.Message;
          this.formMessage = true;
          this.formError = true;
        }
        if (result.Status === "exception") {
          this.formError = true;
          this.DisplayMessage = "There was an error creating your account, please try again.";
          this.formMessage = true;
        }
        if (result.Status === "ok") {
          // log("this.TaxMethod is:", this.TaxMethod);
          let fileUploaded = false;
          if (this.TaxMethod === "upload") {
            this.completedSubmit = false;
            const taxUpload = UploadTaxFile.files[0];
            if (taxUpload !== undefined) {
              fileUploaded = await this.uploadTaxFile(taxUpload);
              if (fileUploaded.Status === "true") {
                this.completedSubmit = true;
              }
            }
            if (taxUpload === undefined) {
              this.UploadMessage = "No file was selected for upload."
              this.uploadError = true;
              this.showMessage = true;
            }
          }
        }
      }
    },
    async uploadTaxFile(uploadedFile) {
      log("uploadTaxFile", uploadedFile);
      this.uploadComplete = false;
      this.UploadMessage = "";
      this.showMessage = false;
      const filesArray = [{
        file: uploadedFile,
        path: "",
        id: 0
      }];
      const tokens = await this.getToken();
      const MASVuploader = new MASV.Uploader(tokens.packageID, tokens.packageToken, "https://api.massive.app");
      // Listener for upload event
      let listener = {
        onComplete: async () => {
          try {
            const formData = {
              "packageid": tokens.packageID,
              "packagetoken": tokens.packageToken, //needed?
            };
            const result = await fetch(url, {
              method: 'POST',
              headers: authHeader(url),
              body: JSON.stringify(formData)
            });
            const uploadData = await result.json();
            log("onComplete uploadData", uploadData);
            this.uploadComplete = true;
            this.UploadMessage = uploadData.Message;
            this.showMessage = uploadData.Message;
          } catch (e) {
            log("FRN unexpected exception: ", e);
            // this.UploadMessage = "There was an unexpected error uploading the file. " + e;
            // this.uploadError = true;
            // this.uploadComplete = false;
            // this.showMessage = true;
          }
        },
        onError: e => {
          log("listener on Error", e);
          this.UploadMessage = "There was a listener error uploading the file." + e;
          this.uploadError = true;
          this.uploadComplete = false;
          this.showMessage = true;
        },
        onFileComplete: f => {
          // log("listener onFileComplete ", f.file.name);
          this.UploadMessage = "Completed tax file upload for  " + f.file.name;
          this.showMessage = true;
        },
        onProgress: (transferred, total, speedStat) => {
          // log("listener onProgress", speedStat);
          const progress = Math.floor(transferred / total * 100);
          // log("listener progress math:", progress);
        },
        onRetry: e => {
          // log("listener onRetry", e);
          this.UploadMessage = "Error! Processing a retry...";
          this.showMessage = true;
          this.uploadError = true;
        }
      }
      MASVuploader.setListener(listener);
      MASVuploader.upload(...filesArray);
      this.MASVModel = {
        userId: 0,
        packageid: tokens.packageID,
        packagetoken: tokens.packageToken,
      }

      const taxUrl = this.baseUrl + "/Account/UploadTaxForm";
      const taxResponse = await fetch(taxUrl, {
        method: "POST",
        headers: authHeader(taxUrl),
        body: JSON.stringify(this.MASVModel)
      });
      const taxResult = await taxResponse.json();
      log("Upload Tax Form result: ", taxResult);
      return taxResult;
    },
    checkForm: function () {
      log("checkForm");
      let _this = this;
      // reset
      _this.ValidForm = true;
      _this.DisplayMessage = "";
      _this.formMessage = false;
      // Validate text fields
      _.forEach(_this.ApplyModel, function (prop, key) {
        let isReq = _.indexOf(_this.RequiredFields, key);
        if (isReq > -1) {
          if (_.isEmpty(prop)) {
            _this.DisplayMessage = _this.DisplayMessage + key + " is required.<br>";
          }
        }
      });
      // Validate compare passwords
      if (_this.Password !== _this.ConfirmPassword) {
        _this.ValidForm = false;
        _this.formMessage = true;
        _this.formError = true;
        _this.DisplayMessage += "Your Password and Password Confirmation do not match.<br>";
      }
      // Validate confirmation check
      if (!_this.ConfirmationCheck) {
        _this.ValidForm = false;
        _this.formMessage = true;
        _this.formError = true;
        _this.DisplayMessage += "Please confirm that you have read the art specs.<br>";
      }
      return _this.ValidForm;
    },

  }
}
</script>

<template>
  <section id="CreateAccount" class="uk-section uk-section-default">
    <div class="uk-container uk-container-small">
      <h1>Create Account</h1>
      <section id="Identity" class="uk-section uk-section-muted uk-padding-small">
        <div uk-grid>
          <div class="uk-width-1-2">
            <label for="txtFirstName" class="uk-form-label">First Name*</label>
            <input type="text" name="txtFirstName" id="txtFirstName" v-model="FirstName" placeholder="First Name"
                   class="uk-input"/>
          </div>
          <div class="uk-width-1-2">
            <label for="txtLastName" class="uk-form-label">Last Name*</label>
            <input type="text" name="txtLastName" id="txtLastName" v-model="LastName" placeholder="Last Name"
                   class="uk-input"/>
          </div>
        </div>
        <label for="txtBusinessName" class="uk-form-label">Business Name*</label>
        <input type="text" name="txtBusinessName" id="txtBusinessName" v-model="BusinessName"
               placeholder="Business Name"
               class="uk-input"/>
        <label for="ddlBusinessType" class="uk-form-label">Business Type</label>
        <select v-model="BusinessType" class="uk-select">
          <option v-for="(bt, key) in BusinessTypeList">{{ bt }}</option>
        </select>
        <label for="txtEmail" class="uk-form-label">E-Mail*</label>
        <input type="text" name="txtEmail" id="txtEmail" v-model="Email" placeholder="Email" class="uk-input"/>
      </section>

      <section id="Address" class="uk-section uk-section-default uk-padding-small">
        <label for="txtAddress" class="uk-form-label">Address*</label>
        <input type="text" name="txtAddress" id="txtAddress" v-model="Address" placeholder="Address" class="uk-input"/>
        <label for="txtAddress2" class="uk-form-label">Address (continued)</label>
        <input type="text" name="txtAddress2" id="txtAddress2" v-model="Address2" placeholder="Address (continued)"
               class="uk-input"/>
        <div uk-grid>
          <div class="uk-width-1-3">
            <label for="txtCity" class="uk-form-label">City*</label>
            <input type="text" name="txtCity" id="txtCity" v-model="City" placeholder="City" class="uk-input"/>
          </div>
          <div class="uk-width-1-3">
            <label for="ddlState" class="uk-form-label">State*</label>
            <select v-model="State" class="uk-select">
              <option v-for="(st, key) in StateList" v-bind:value="st.key">{{ st.value }}</option>
            </select>
          </div>
          <div class="uk-width-1-3">
            <label for="txtZip" class="uk-form-label">Zip*</label>
            <input type="text" name="txtZip" id="txtZip" v-model="Zip" placeholder="Zip" class="uk-input"/>
          </div>
        </div>
        <label for="ddlCountry" class="uk-form-label">Country*</label>
        <select v-model="Country" class="uk-select">
          <option v-for="(ct, key) in CountryList" v-bind:value="ct.key">{{ ct.value }}</option>
        </select>
        <label for="txtPhone" class="uk-form-label">Phone Number*</label>
        <input type="text" name="txtPhone" id="txtPhone" v-model="Phone" placeholder="Phone Number" class="uk-input"/>
      </section>

      <section id="Passwords" class="uk-section uk-section-muted uk-padding-small">
        <label for="txtPasswordEnter" class="uk-form-label">Password*</label>
        <input type="password" name="txtPasswordEnter" id="txtPasswordEnter" v-model="Password" placeholder="Password"
               class="uk-input"/>
        <label for="txtPasswordConfirm" class="uk-form-label">Confirm Password*</label>
        <input type="password" name="txtPasswordConfirm" id="txtPasswordConfirm" v-model="ConfirmPassword"
               placeholder="Confirm Password" class="uk-input"/>
        <label for="txtWebsite" class="uk-form-label">Website</label>
        <input type="text" name="txtWebsite" id="txtWebsite" v-model="Website" placeholder="Website" class="uk-input"/>
        <label for="ddlHearAboutUs" class="uk-form-label">How did you hear about us?</label>
        <select v-model="HearAboutUs" class="uk-select">
          <option v-for="(hau, key) in HearAboutUsList">{{ hau }}</option>
        </select>
        <p>In order to process your application, we will need your tax exemption certificate. If you are tax exempt,
          please send us a completed copy of your state’s resale/exemption certificate, NOT a copy of your business
          license. A resale/exemption certificate will generally show who you are purchasing from, what you are
          purchasing, and your reason for claiming exemption. How would you like to send this form to us?</p>
        <div class="uk-form-controls">
          <label><input type="radio" v-model="TaxMethod" value="upload" id="uploadTaxForm"
                        class="uk-radio"/>
            Upload</label>
          <div v-if="TaxMethod === 'upload'" uk-form-custom="target: true" class="uk-panel">
            <input type="file" id="UploadTaxFile" class="uk-form-input"
                   accept=".pdf,.png,.jpg,.jpeg"/>
            <input class="uk-input" type="text" placeholder="Select tax file">
            <p class="uk-text-small uk-margin-small-top uk-margin-bottom">
              The Supported file formats are .pdf, .png, .jpg, .jpeg.
            </p>
          </div>
        </div>

        <div class="uk-form-controls">
          <label class="uk-form-label">
            <input type="radio" v-model="TaxMethod" value="email" id="emailTaxForm" class="uk-radio"/> Email: <a
              href="mailto:taxcert@lowen.com">taxcert@lowen.com</a>
          </label>
        </div>

        <div class="uk-form-controls">
          <label class="uk-form-label">
            <input type="radio" v-model="TaxMethod" value="snail" id="mailTaxForm" class="uk-radio"/>
            US Mail: P.O. Box 1528 - Hutchinson, KS
            67504-1528
          </label>
        </div>

        <div class="uk-form-controls">
          <label class="uk-form-label">
            <input type="radio" v-model="TaxMethod" value="NotExempt" id="notTaxExempt" class="uk-radio"/>
            Not Tax Exempt
          </label>
        </div>

        <div class="uk-form-controls">
          <label class="uk-form-label">
            <input type="radio" v-model="TaxMethod" value="alreadyHave" id="alreadyOnFile" class="uk-radio"/>
            Already On File
          </label>
        </div>
      </section>
      <p class="uk-text-lead">Please read our <a
          href="/public/docs/TradesourceFileSpecificationsAdhesive.pdf"
          target="_blank">adhesive art specs</a> and our <a
          href="/public/docs/TradesourceFileSpecificationsRigid.pdf"
          target="_blank">rigid art specs</a> forms before creating your account.</p>
      <input type="checkbox" v-model="ConfirmationCheck" id="chkConfirmationCheck" name="chkConfirmationCheck"
             class="uk-checkbox"/>
      <label for="chkConfirmationCheck" class="uk-form-label">&nbsp;I have confirmed that I have read the art
        specs.</label>
      <div id="StatusMessages" class="uk-margin-top">
        <div v-if="formMessage" v-bind:class="{ 'uk-alert-warning': formError, 'uk-alert-primary': !formError }"
             class="uk-alert"
             v-html="DisplayMessage" uk-alert></div>
        <div v-if="DisplayMessage === 'Already applied'" class="uk-padding-small uk-panel">
          <p class="uk-text-lead uk-text-bolder">Looks like you already have an account with us under this email
            address. If you do not know your password, <a class="uk-link" href="#" uk-toggle="target: #ResetModal">Reset
              Password</a> to get a new one.</p>
        </div>
        <div v-if="showMessage" v-bind:class="{ 'uk-alert-warning': uploadError, 'uk-alert-primary': !uploadError }"
             class="uk-alert"
             v-html="UploadMessage" uk-alert></div>
      </div>
      <div v-if="!completedSubmit" id="Buttons" class="uk-margin-top" uk-grid>
        <div class="uk-width-1-2">
          <input id="SubmitCreateAccount" type="button" class="uk-button uk-button-primary" value="Submit"
                 v-on:click="submitForm"/>
        </div>
        <div class="uk-width-1-2">
          <input type="button" class="uk-button nuk-button-danger" value="Cancel" onclick="history.back()"/>
        </div>
      </div>
      <div v-if="completedSubmit" id="LoginAfterCreation" class="uk-margin-top" uk-grid>
        <div class="uk-width-1-2">
          <input id="LoginAfterCreateAccount" type="button" class="uk-button uk-button-primary" value="Login"
                 uk-toggle="target: #LoginModal"/>
        </div>
      </div>
    </div>
  </section>
</template>