import {defineStore} from 'pinia';

export const useWizardStore = defineStore({
    id: 'wizard',
    state: () => ({
        substrateName: '',
        substrateValue: '',
        substrateTile: '',
        substrateOptionValue: '',
        productType: '',
        wizardActive: false
    }),
    getters: {
        currentSubstrateName: (state) => {
            return state.substrateName;
        },
        currentSubstrateValue: (state) => {
            return state.substrateValue;
        },
        currentProduct_type: (state) => {
            return state.productType;
        },
        getWizardActive: (state) => {
            return state.wizardActive;
        },
        currentSubstrateTile: (state) => {
            return state.substrateTile;
        },
        currentSubstrateOptionValue: (state) => {
            return state.substrateOptionValue;
        }
    },
    actions: {
        setSubstrateName(selection) {
            this.substrateName = selection;
        },
        setSubstrateValue(selection) {
            this.substrateValue = selection;
        },
        setProductType(selection) {
            this.productType = selection;
        },
        setwizardActive(active) {
            this.wizardActive = active;
        },
        setSubstrateTile (selection) {
            this.substrateTile = selection;
        },
        setSubstrateOptionValue (selection) {
            this.substrateOptionValue = selection;
        }
    }
});