﻿<script>
import {ref} from "vue";
import {useAuthStore} from "../../stores/auth.store";
import {authHeader} from "../../helpers/authheader";
import Loading from "./LoadingAnimation.vue";
import {utilsMixin} from "../../helpers/utils";
import {lv} from "../../helpers/lowenValidation";
import OrderPreview from "./OrderPreview.vue";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

const rowIndicesShowing = ref(1);

export default {
  mixins: [utilsMixin, lv],
  props: ['orderType', 'userAccount', 'userEmail', 'pageType'],
  data() {
    return {
      adminUser: {},
      isLoading: false,
      orderMessage: "",
      searchText: "",
      noOrders: false,
      orderList: [],
      selectedOrder: "",
      selectedUser: "",
      pageCount: 1,
      pageNumber: 1,
      rowCount: 10,
      sortKey: 'orderNumber',
      sortDesc: false,
      baseUrl: process.env.APIURL + "/Store"
    };
  },
  components: {
    "order-preview-app": OrderPreview,
    "Loading": Loading,
  },
  mounted() {
    console.log("OrdersListComponent is mounted");
    UIkit.use(Icons);
    // In this case, the adminUser could just be are regular user
    const { user } = useAuthStore();
    this.adminUser = user;
    this.loadOrderRows();
  },
  computed: {
    filteredOrderList() {
      let returnValue = this.orderList;
      if (this.isNotEmpty(this.searchText)) {
        // console.log("TAL", this.orderList);
        returnValue = this.orderList.filter(order => {
          return Object.values(order).some(value =>
              value.toString().toLowerCase().includes(this.searchText.toLowerCase())
          );
        });
        // console.log("RV", returnValue);
      }
      return returnValue;
    },
    /*filteredData() {
      const sortKey = this.sortKey
      const filterKey = this.filterKey && this.filterKey.toLowerCase()
      const order = this.sortOrders[sortKey] || 1
      let data = this.data
      if (filterKey) {
        data = data.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      if (sortKey) {
        data = data.slice().sort((a, b) => {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }
      return data
    },*/
    columns() {
      let c = [
        {
          text: "Order Number",
          value: "SalesOrder"
        },
        {
          text: "Ordered By",
          value: "OrderedBy"
        },
        {
          text: "Date Ordered",
          value: "DateOrdered"
        },
        {
          text: "Production Date",
          value: "ProductionDate"
        },
        {
          text: "Subtotal",
          value: "Subtotal"
        },
        {
          text: "Carrier",
          value: "Carrier"
        },
        {
          text: "Tracking",
          value: "Tracking"
        },
        {
          text: "Actions",
          value: "actions"
        }
      ];
      // Don't show pricing column for some Franchises
      if (!this.isFranchiseEnabled('portalPricing')) {
        c = c.filter(column => column.value !== 'Subtotal');
      }
      if (!this.isOfficeManager) {
        c = c.filter(column => column.value !== 'OrderedBy');
      }
      return c;
    },
    previewOrder() {
      return this.selectedOrder;
    },
    previewUser() {
      /*let id = this.userAccount;
      if (!this.isNotEmpty(id)) {
        const { user } = useAuthStore()
        id = user.Id;
      }
      return id;*/
      return this.selectedUser;
    },
    tableName() {
      // return this.orderType + " Orders";
      let orderStatus = this.orderStatuses.find(status => status.key === this.orderType);
      return (orderStatus ? orderStatus.value : 'Ordered') + " Orders";
    },
  },
  methods: {
    approveOrder: async function (userId, orderNumber) {
      // console.log("approveOrder", orderNumber);
      // console.log("approveOrder", userId);
      const url = this.baseUrl + "/ApproveOrder";
      const call = {
        userId: userId,
        orderNumber: orderNumber.toString(),
        Level: this.adminUser.Level
      };
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let approveResponse = await response.json();
      if (approveResponse.Status === "ok") {
        this.orderMessage = approveResponse.Message;
        this.agentOrders = await this.loadOrderRows();
      }
      if (approveResponse.Status === "failed") {
        this.orderMessage = approveResponse.Message;
      }
      if (approveResponse.Status === "exception") {
        this.orderMessage = approveResponse.Message;
      }
    },
    convertISOtoReadable(dateString) {
      var date = new Date(dateString);
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleString('en-US', options);
    },
    changePage(page) {
      console.log('PAGECOUNT', this.pageCount);
      if (page < 1 || this.pageCount < page) {
        return;
      }

      this.pageNumber = page;
    },
    loadOrderRows: _.debounce(async function (pageNumber) {
      console.log("loadOrderRows");
      let ready = true;
      this.noOrders = false;
      this.isLoading = true;
      if (this.isNotEmpty(pageNumber)) {
        this.pageNumber = 1;
        if (this.pageNumber + pageNumber > 0) {
          this.pageNumber = pageNumber;
        }
      } else {
        this.pageNumber = 1;
      }
      // console.log("loadOrderRows", this.pageNumber);
      const url = this.baseUrl + "/AccountOrders";
      // const { user } = useAuthStore();
      this.orderList = [];
      let returnedData = false;
      let tempQueryStart = this.queryStart();
      if (!this.isNotEmpty(this.userAccount)) {
        ready = false;
        this.isLoading = false;
        this.noOrders = true;
      }
      if (ready) {
        const call = {
          "userId": this.userAccount,
          "Type": this.orderType,
          "Level": this.adminUser.Level
        };
        // console.log("loadOrderRows call:", call);
        let response = await fetch(url, {
          method: 'POST',
          headers: authHeader(url),
          body: JSON.stringify(call)
        });
        returnedData = await response.json();
        // console.log('RETURNED DATA', returnedData);
        if (returnedData.Status === "ok") {
          this.orderList = returnedData.Data;

          if (this.orderList.length > 0) {
            this.noOrders = false;
            this.manageOrderData();
          } else {
            this.noOrders = true;
            this.isLoading = false;
          }
        }
        if (returnedData.Status === "exception") {
          this.orderMessage = returnedData.Message;
          this.isLoading = false;
          // log("Account Orders bad return", returnedData.Message);
        }
      }
    }, 100),
    manageOrderData() {
      // console.log('RESPONSE', this.orderList); 

      // _this = this;
      // set up paging
      this.pageCount = Math.ceil(this.orderList.length / this.rowCount);
      // If a user page remove any orders that are not the users
      if (this.pageType === 'user' && this.orderType !== 'needs_approval') {
        this.orderList = this.orderList.filter(ord => {
          return ord.Status.toLowerCase() !== 'needs_approval';
        });
      }
      // console.log("orderListCount after", this.orderList.length);
      this.sortBy(this.sortKey);
      this.isLoading = false;
    },
    queryStart() {
      let returnValue = 0;
      if (this.pageNumber > 1) {
        returnValue = (this.pageNumber - 1) * this.rowCount
      }
      return returnValue;
    },
    sortBy(key) {
      // log("sortBy", key);
      //log("sort",this.sortDesc);
      // determine default sort direction first
      if (key === this.sortKey) {
        this.sortDesc = this.sortDesc !== true;
      } else {
        this.sortDesc = true;
      }
      // now assign the key
      this.sortKey = key
      //log("sort",this.sortDesc);
      if (key === "actions") {
        return;
      }
      let temp = this.orderList;
      temp = _.sortBy(temp, [this.sortKey]);
      //log("sorted temp: ", temp);
      if (this.sortDesc === true) {
        // log("reverse the sort");
        this.orderList = temp.reverse();
      } else {
        this.orderList = temp;
      }
    },
    showDetails(order, user) {
      // console.log("showDetails", order);
      // console.log("showDetails", user);
      this.selectedOrder = String(order);
      this.selectedUser = user;
      // console.log("showDetails selectedUser", this.selectedUser);
      console.log("UIkit");
      UIkit.offcanvas("#MyOrderDetails").show();
    },
    needsApproval(ordStatus) {
      let returnValue = false;
      if (ordStatus === 'needs_approval') {
        returnValue = true
      }
      return returnValue;
    },
    isVisibleRow(idx) {
      return (this.rowCount * (this.pageNumber - 1) <= idx) && (idx < this.rowCount * this.pageNumber);
    }
  },

  watch: {
    orderType(newVal, oldVal) {
      this.loadOrderRows();
    },
    userAccount(newVal, oldVal) {
      this.loadOrderRows();
    },
  }
}
</script>

<template id="OrderListTemplate">
  <div v-if="isNotEmpty(orderMessage)" class="uk-alert-warning" uk-alert>{{ orderMessage }}</div>

  <div id="TheOrderList" v-if="isNotEmpty(orderList)">
    <div class="uk-child-width-1-2@s" uk-grid>
      <div>
        <h2 v-if="isNotEmpty(userEmail)" class="uk-text-capitalize">{{ tableName }} for {{ userEmail }}</h2>
      </div>
      <div class="uk-form uk-float-right">
        <input v-model="searchText" class="uk-input raised-search-input" type="search" placeholder="Search"/>
      </div>
    </div>
    <div class="uk-overflow-auto">
      <table id="{{tableName}}" class="uk-table uk-table-striped uk-table-hover">
        <thead>
        <tr>
          <th v-for="head in columns" v-on:click="sortBy(head.value)">
            <span class="uk-button uk-button-link">
              {{ head.text }}&nbsp;
              <span v-if="head.value === 'Subtotal'"
                    uk-tooltip="title: Subtotal does not include shipping"
                    uk-icon="info"></span>
              <span v-if="head.value === sortKey && sortDesc === true" uk-icon="triangle-down"></span>
              <span v-if="head.value === sortKey && sortDesc === false" uk-icon="triangle-up"></span>
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-bind:class="{ 'uk-animation-slide-top-small uk-animation-reverse': isLoading, 'uk-animation-slide-top-small': !isLoading }"
            v-for="(o, rowIdx) in filteredOrderList" :idx="rowIdx" v-show="isVisibleRow(rowIdx)">
          <td>
            <div class="uk-margin-bottom">
            <span class="uk-visible@s lk-zoom"
                  v-on:click="showDetails(o.SalesOrder, o.AgentId)">{{ o.SalesOrder }}</span>
              <span class="uk-hidden@s">{{ o.SalesOrder }}</span>
            </div>
            <div v-if="needsApproval(o.Status) && isOfficeManager">
              <button v-on:click="approveOrder(userAccount,o.SalesOrder)" class="uk-button uk-button-default">Release
              </button>
            </div>
          </td>
          <td v-if="isOfficeManager">
            {{ o.OrderedBy }}
          </td>
          <td>{{ convertISOtoReadable(o.DateOrdered) }}</td>
          <td>{{ o.ProductionDate }}</td>
          <td v-if="showPrice">{{ $toCurrency(o.Subtotal) }}</td>
          <td>{{ o.Carrier }}</td>
          <td>
            <div v-if="isNotEmpty(o.TrackingNo)">
              <div v-for="(t,i) in o.TrackingNo">
                <div v-if="isNotEmpty(o.TrackingLink[i])">
                  <a :href="o.TrackingLink[i]" target="_blank">{{ o.TrackingNo[i] }}</a>
                </div>
              </div>
            </div>
            <span v-else>Unavailable</span>
          </td>
          <td>
            <router-link class="uk-button uk-button-default"
                         :to='{name: "orderDetails", params: {orderNumber: o.SalesOrder, userId: adminUser.Id}}'>Details
            </router-link>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th v-for="foot in columns">
            <span class="uk-button uk-button-link">{{ foot.text }}</span>
          </th>
        </tr>
        </tfoot>
      </table>
    </div>
    <div>
      <ul class="uk-pagination">
        <li><a v-on:click="changePage(pageNumber - 1)"><span uk-pagination-previous></span></a></li>
        <li v-for="page in pageCount" :class="{'uk-active': this.pageNumber === page}"><a v-on:click="changePage(page)">{{
            page
          }}</a></li>
        <li><a v-on:click="changePage(pageNumber + 1)"><span uk-pagination-next></span></a></li>
      </ul>
    </div>
  </div>
  <Loading v-if="isLoading"/>
  <div v-if="noOrders" class="uk-alert">No orders to display.</div>
  <!-- Off-Canvas Order Details-->
  <div id="MyOrderDetails" class="uk-width-2-3 uk-box-shadow-medium"
       uk-offcanvas="flip: true; overlay: false; mode: slide;">
    <div class="uk-section uk-section-default" style="height: 90%; overflow: auto;">
      <div class="uk-offcanvas-close lk-pointer" uk-close><span class="uk-text-lead">Close&nbsp;</span>&nbsp;</div>
      <order-preview-app v-if="isNotEmpty(selectedOrder)" :userID="previewUser"
                         :orderNumber="previewOrder"></order-preview-app>
    </div>
  </div>
</template>
