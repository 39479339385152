import {useAuthStore} from '../stores/auth.store.js';
import {createRouter as s} from "vue-router";

export const utilsMixin = {
    methods: {
        isFranchiseEnabled(featureName) {
            let returnValue = false;
            const franchise = process.env.Franchise;
            let features = "";
            let enabledFeatures = [];

            switch (franchise) {
                case 'Decor':
                    features = process.env.Decor;
                    enabledFeatures = features.split(',');
                    break;
                case 'EPD':
                    features = process.env.EPD;
                    enabledFeatures = features.split(',');
                    break;
                case 'NGS':
                    features = process.env.NGS;
                    enabledFeatures = features.split(',');
                    break;
                case 'Wholesale':
                    features = process.env.Wholesale;
                    enabledFeatures = features.split(',');
                    break;
                case 'WebPrint':
                    features = process.env.WebPrint;
                    enabledFeatures = features.split(',');
                    break;
                case '':
                    break;
            }
            if (enabledFeatures.length > 0) {
                // returnValue = enabledFeatures.includes(featureName);
                returnValue = enabledFeatures.map(s => s.trim()).includes(featureName);
                // console.log("FeatureCheck Name: " + featureName + " result: " + returnValue);
            }
            return returnValue;
        },
        facebookURL() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSFacebookURL;
                case 'Decor':
                    return process.env.DecorFacebookURL;
                case 'EPD':
                    return process.env.EPDFacebookURL;
                case 'Wholesale':
                    return process.env.TradesourceFacebookURL;
                    break;
                default:
            }
        },
        instagramURL() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSInstagramURL;
                case 'Decor':
                    return process.env.DecorInstagramURL;
                case 'EPD':
                    return process.env.EPDInstagramURL;
                case 'Wholesale':
                    return process.env.TradesourceInstagramURL;
                    break;
                default:
            }
        },
        linkedinURL() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSLinkedInURL;
                case 'Decor':
                    return process.env.DecorLinkedInURL;
                case 'EPD':
                    return process.env.EPDLinkedInURL;
                case 'Wholesale':
                    return process.env.TradesourceLinkedInURL;
                    break;
                default:
            }
        },
        twitterURL() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSTwitterURL;
                case 'Decor':
                    return process.env.DecorTwitterURL;
                case 'EPD':
                    return process.env.EPDTwitterURL;
                case 'Wholesale':
                    return process.env.TradesourceTwitterURL;
                    break;
                default:
            }
        },
        address1() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSAddress1;
                case 'Decor':
                    return process.env.DecorAddress1;
                case 'Wholesale':
                    return process.env.TradesourceAddress1;
                    break;
                default:
            }
        },
        address2() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSAddress2;
                case 'Decor':
                    return process.env.DecorAddress2;
                case 'EPD':
                    return process.env.EPDAddress2;
                case 'Wholesale':
                    return process.env.TradesourceAddress2;
                    break;
                default:
            }
        },
        address3() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSAddress3;
                case 'Decor':
                    return process.env.DecorAddress3;
                case 'EPD':
                    return process.env.EPDAddress3;
                case 'Wholesale':
                    return process.env.TradesourceAddress3;
                    break;
                default:
            }
        },
        contactPhone() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSPhone;
                case 'Decor':
                    return process.env.DecorPhone;
                case 'EPD':
                    return process.env.EPDPhone;
                case 'Wholesale':
                    return process.env.TradesourcePhone;
                    break;
                default:
            }
        },
        contactEmail() {
            switch (this.franchise) {
                case 'NGS':
                    return process.env.NGSContactEmail;
                case 'Decor':
                    return process.env.DecorContactEmail;
                case 'EPD':
                    return process.env.EPDContactEmail;
                case 'Wholesale':
                    return process.env.TradesourceContactEmail;
                    break;
                default:
            }
        },
        getFileSpecs() {
            // console.log("getFileSpecs", this.franchise);
            const franchise = process.env.Franchise;
            let specSheets = {};
            let franchiseEnv = "";
            switch (this.franchise) {
                case "Wholesale":
                    franchiseEnv = process.env.TradesourceFileSpecifications;
                    break;
                case "EPD":
                    franchiseEnv = process.env.EPDFileSpecifications;
                    break;
                case "NGS":
                    franchiseEnv = process.env.NGSFileSpecifications;
                    break;
            }
            if (this.isFranchiseEnabled('specSheets')) {
                if (franchiseEnv.length > 0) {
                    // Replace single quotes with double quotes for JSON parsing
                    let envValue = franchiseEnv.replace(/'/g, "\"");
                    specSheets = JSON.parse(envValue);
                }
            }
            return specSheets;
        },
    },
    computed: {
        showPrice() {
            // Not computed here because it may need expanded in future to 
            // allow pricing for some items and types and not others
            let returnValue = false;
            if (this.isFranchiseEnabled('portalPricing')) {
                returnValue = true;
            }
            return returnValue;
        },
        isOfficeManager() {
            let returnValue = false;
            if (this.isFranchiseEnabled('officeManager')) {
                let authStore = useAuthStore()
                let user = authStore.user;
                if (user.Level === "2") {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        isTermsOnly() {
            let returnValue = false;
            if (this.isFranchiseEnabled('termsOnly')) {
                returnValue = true;
            }
            return returnValue;
        },
        isHolidayMessage() {
            let returnValue = false;
            let flag = false;
            switch (this.franchise) {
                case 'Wholesale':
                    flag = process.env.WholesaleMessageFlag;
                    break;
                case 'NGS':
                    flag = process.env.NGSMessageFlag;
                    break;
                case 'EPD':
                    flag = process.env.EPDMessageFlag;
                    break;
            }
            // you have to convert the string
            returnValue = flag === 'true';
            console.log("isHolidayMessage", returnValue);
            return returnValue;
        },
        holidayMessage() {
            let returnValue = "";
            switch (this.franchise) {
                case 'Wholesale':
                    returnValue = process.env.WholesaleMessage;
                    break;
                case 'NGS':
                    returnValue = process.env.NGSMessage;
                    break;
                case 'EPD':
                    returnValue = process.env.EPDMessage;
                    break;
            }
            return returnValue;
        },
        orderStatuses() {
            let s = [
                {
                    key: 'ordered',
                    value: 'In Production'
                },
                {
                    key: 'shipped',
                    value: 'Shipped'
                },
                {
                    key: 'invoiced',
                    value: 'Completed Orders'
                }
            ];

            let approve = {
                key: 'needs_approval',
                value: 'Await Release'
            };
            if (this.isOfficeManager) {
                approve = {
                    key: 'needs_approval',
                    value: 'Release to Production'
                };
            }
            // We only show the approve column for Franchises not showing pricing.
            if (this.isFranchiseEnabled('orderApproval')) {
                s.unshift(approve);
            }
            return s;
        }
    }
}