<script setup>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import {utilsMixin} from '../helpers/utils'
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
</script>
<script type="module">
export default {
  mixins: [utilsMixin],
  data() {
    return {
      baseUrl: process.env.APIURL,
      lowenCertifiedURL: process.env.LowenCertifiedURL,
      lowenColorGraphicsURL: process.env.LowenColorGraphicsURL,
      lowenCorpURL: process.env.LowenCorpURL,
      colorMatchServiceSpecifications: process.env.ColorMatchServiceSpecifications,
      measurementInstructionsDoc: process.env.MeasurementInstructionsDoc,
      franchise: process.env.Franchise,
    };
  },
  mounted() {
    console.log("Tradesource Resources mounted");
    UIkit.use(Icons);
  },
  computed: {
    fileSpecs() {
      return this.getFileSpecs();
    },
  },
  methods: {}
};
</script>
<template>
  <section class="uk-section">
    <div class="uk-container uk-container-expand">
      <div class="uk-grid uk-grid-small uk-grid-match" uk-grid>
        <div class="uk-width-1-2@m">
          <h2 class="uk-h3 uk-text-center">Helpful Videos</h2>
          <div class="uk-card uk-card-primary uk-card-hover uk-card-body uk-margin-large-bottom">
            <div class="uk-card-title uk-text-center uk-margin-bottom">Raster to Vector</div>
            <iframe width="960" height="540" class="" src="https://www.youtube.com/embed/uUsY-gDupyY" frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    uk-responsive></iframe>
          </div>

          <div class="uk-card uk-card-primary uk-card-hover uk-card-body  uk-margin-large-bottom">
            <div class="uk-card-title uk-text-center uk-margin-bottom">Checking Your Artwork at 100% Print Size in
              Photoshop
            </div>
            <iframe width="960" height="540" class="embed-responsive-item"
                    src="https://www.youtube.com/embed/fdABjnNFyW8"
                    allowfullscreen uk-responsive></iframe>
          </div>

          <div class="uk-card uk-card-primary uk-card-hover uk-card-body">
            <div class="uk-card-title uk-text-center uk-margin-bottom">Change Your Artwork Size</div>
            <iframe width="960" height="540" class="embed-responsive-item"
                    src="https://www.youtube.com/embed/tLAi4E5SABs"
                    allowfullscreen uk-responsive></iframe>
          </div>

        </div>

        <div class="uk-width-1-2@m">
          <h2 class="uk-h3 uk-text-center">FAQ</h2>

          <div class="uk-flex uk-flex-column">
            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  Where can I find the requirements for images and files?<i class="fas fa-angle-down rotate-icon"></i>
                </h2>
                <h3 class="uk-h3">Download Art File Specifications</h3>
                <p v-for="(url, desc) in fileSpecs">
                  <a :href="url"
                     class="uk-button uk-button-default"
                     target="_blank" uk-icon="download">Download {{ desc }} Art Specs</a>
                </p>
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  How do you provide consistent color quality?
                </h2>
                We have an in house Color specialist which makes it possible for us to deliver consistent color
                quality on every order, every time.
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  What kind of customer support do you provide?
                </h2>
                Our team is an extension of your team providing you with expert advice. We also offer direct contact
                with an artist for easy collaboration. Order and shipping confirmation and tracking is included so
                that
                you can follow your order from start to finish.
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  What materials and warranties do you offer?
                </h2>
                We offer premium 3M and Avery 2 mil cast vinyl films with laminate or clear coat; both of which
                qualify for the respective <a href="/public/docs/3M Commercial Graphics Warranty Brochure.pdf"
                                              target="_blank">3M
                MCS</a> and <a href="/public/docs/ICS Platinum Warranty - Digital - for Lowen -- 09-25-20.pdf"
                               target="_blank">Avery ICS Platinum</a> warranties. We have many other materials available
                on request. Please contact us for more details.
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  How can you offer these low square foot prices?
                </h2>
                Volume material discounts from 3M and Avery. World-class production operation optimized for volume and
                efficiency
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  How can you offer a 24-hour production time?
                </h2>
                We have invested millions to optimize our operation for high-volume, high-speed processing which
                we pass on to you with 24-hour production for most orders.
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  Do you install graphics?
                </h2>
                Yes. The Lowen Installation Alliance is our national network of certified installers we utilize to
                coordinate
                application and removal of your graphics anywhere, anytime, within the United States and Canada.
                Your installations will be managed by a dedicated Installation Specialist with the support of our team
                of experts.
                To learn more go to <a :href="this.lowenCertifiedURL" target="_blank">Lowen Certified</a>
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  What is the difference between laminate and clear coat?<i class="fas fa-angle-down rotate-icon"></i>
                </h2>
                Laminate is an additional film layer applied over the printed ink that provides UV inhibitors and
                protects against abrasion.
                Laminate also comes in a variety of finishes such as gloss, luster, and matte. Clear coat
                is a liquid coating that includes UV inhibitors to protect the printed inks from prematurely fading
                for
                a significantly
                lower cost than film laminate. There are differences in warranties, depending on which option you
                choose.
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  What are Color Matching Services?<i class="fas fa-angle-down rotate-icon"></i>
                </h2>
                Information about our Color Matching Services can be downloaded <a
                  :href="this.colorMatchServiceSpecifications" target="_blank">here</a>
              </div>
            </div>

            <div class="uk-card uk-card-secondary uk-card-hover uk-margin-bottom">
              <div class="uk-card-body">
                <h2 class="uk-card-title">
                  What is the proper way to measure my vehicle?<i class="fas fa-angle-down rotate-icon"></i>
                </h2>
                You can view our Vehicle Measurement instructions <a :href="this.measurementInstructionsDoc"
                                                                     target="_blank">here</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>