<script>
import {utilsMixin} from '../../helpers/utils';
import {lv} from '../../helpers/lowenValidation';
import {useAuthStore} from '../../stores/auth.store.js';
import {authHeader} from '../../helpers/authheader';


export default {
  data() {
    return {
      agentList: {},
      editUser: 0,
      editUserOrders: 0,
      pageCount: 1,
      pageNumber: 1,
      rowCount: 5,
      searchText: "",
      sortKey: 'orderNumber',
      sortDesc: false,
      baseUrl: process.env.APIURL,
    };
  },
  mixins: [utilsMixin, lv],
  props: ['activeOnly'],
  emits: ['showorders', 'editaccount', 'changepassword', 'deleteaccount'],
  mounted() {
    console.log("Mounted ManageUsers");
    this.agentList = this.fetchAgents();
  },
  methods: {
    changePage(page) {
      // console.log('PAGECOUNT', this.pageCount);
      if (page < 1 || this.pageCount < page) {
        return;
      }
      this.pageNumber = page;
    },
    async fetchAgents() {
      /*if (this.activeOnly === true) {
        this.agentList = await this.fetchMyActiveUsers();
      } else {*/
      this.agentList = await this.fetchManagedUsers();
      this.pageCount = Math.ceil(this.agentList.length / this.rowCount);
      // }
    },
    async fetchManagedUsers() {
      // console.log("fetchManagedUsers");
      const url = this.baseUrl + "/Account/GetAgents";
      const { user } = useAuthStore();
      const call = {
        userId: user.Id,
      };
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let agentResponse = await response.json();
      // log("fetchAgentsDetails: ", agentResponse);
      if (agentResponse.Status === "ok") {
        return agentResponse.Data;
      }
      if (agentResponse.Status === "exception") {
        console.log("Could not retrieve Agents:", agentResponse.Message);
      }
    },
    async fetchMyActiveUsers() {
      console.log("fetchMyActiveUsers");
      const url = this.baseUrl + "/Users/GetMyUsers";
      const { user } = useAuthStore();
      const call = {
        "userId": user.Id,
      };
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      // console.log(response);
      let userResponse = await response.json();
      // log("fetchUserDetails: ", userResponse);
      if (userResponse.Status === "ok") {
        console.log("fetchUserDetails: ", userResponse);
        return userResponse.Data;
      }
      if (userResponse.Status === "exception") {
        console.log("Could not retrieve userResponse:", userResponse.Message);
      }
    },
    toggleUserMenu(userId) {
      this.editUser = (this.editUser === userId) ? 0 : userId;
    },
    toggleOrderMenu(userId) {
      this.editUserOrders = (this.editUserOrders === userId) ? 0 : userId;
    },
    isVisibleRow(idx) {
      return (this.rowCount * (this.pageNumber - 1) <= idx) && (idx < this.rowCount * this.pageNumber);
    }
  },
  computed: {
    filteredAgentList() {
      let returnValue = this.agentList;
      if (this.isNotEmpty(this.searchText)) {
        console.log("TAL", this.agentList);
        returnValue = this.agentList.filter(agent => {
          return Object.values(agent).some(value =>
              value.toString().toLowerCase().includes(this.searchText.toLowerCase())
          );
        });
        console.log("RV", returnValue);
      }
      return returnValue;
    }
  },
  watch: {
  }
}

</script>

<template>
  <div class="uk-child-width-1-2" uk-grid>
    <div>
      <h3 class="uk-h4 uk-margin-remove-bottom">Accounts</h3>
    </div>
    <div class="uk-form uk-float-right">
      <input v-model="searchText" class="uk-input raised-search-input" type="search" placeholder="Search"/>
    </div>
  </div>

  <!--  <div v-if="activeOnly === true">
      <h3 class="uk-h4 uk-margin-remove-bottom">Active Users</h3>
      <p class="uk-text-small uk-margin-remove-top">These users are currently logged in.</p>
    </div>-->

  <div class="uk-overflow-auto">
    <table class="uk-table uk-table-small uk-table-striped uk-table-hover uk-margin-remove-top">
      <thead>
      <tr>
        <th><span class="uk-button uk-button-link">Orders</span></th>
        <th><span class="uk-button uk-button-link">Edit</span></th>
        <th><span class="uk-button uk-button-link">Name</span></th>
        <th><span class="uk-button uk-button-link">Email</span></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(user, rowIdx) in filteredAgentList" :idx="rowIdx" v-show="isVisibleRow(rowIdx)">
        <td>
          <div v-on:click="toggleOrderMenu(user.Id)" class="uk-button uk-button-primary">Orders&nbsp;
            <span v-if="user.Id !== editUserOrders" uk-icon="icon: chevron-down"></span>
            <span v-if="user.Id === editUserOrders" uk-icon="icon: chevron-up"></span>
          </div>
          <div :class="{'uk-hidden': user.Id !== editUserOrders}"
               class="uk-background-primary uk-light uk-padding-small uk-padding-remove-right uk-animation-fade">
            <div v-for="status in orderStatuses" class="uk-margin-small-bottom uk-padding-remove">
              <div v-on:click="$emit('showorders', user.Id, user.Email, status.key)"
                   class="uk-link">{{ status.value }}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div v-on:click="toggleUserMenu(user.Id)" class="uk-button uk-button-primary">Edit&nbsp;
            <span v-if="user.Id !== editUser" uk-icon="icon: chevron-down"></span>
            <span v-if="user.Id === editUser" uk-icon="icon: chevron-up"></span>
          </div>
          <div :class="{'uk-hidden': user.Id !== editUser}"
               class="uk-background-primary uk-light uk-padding-small uk-padding-remove-right uk-animation-fade">
            <div class="uk-margin-small-bottom uk-padding-remove">
              <div v-on:click="$emit('editaccount', user.Id,user.Email)" class="uk-button uk-button-link">Edit Account
              </div>
            </div>
            <div class="uk-margin-small-bottom uk-padding-remove">
              <div v-on:click="$emit('changepassword',user.Id,user.Email)" class="uk-button uk-button-link">Change
                Password
              </div>
            </div>
            <div class="uk-margin-small-bottom uk-padding-remove">
              <div v-on:click="$emit('deleteaccount',user.Id,user.Email)" class="uk-button uk-button-link"><span
                  class="uk-text-danger">Remove</span>
              </div>
            </div>
          </div>
        </td>
        <td>{{ user.FirstName }}&nbsp;{{ user.LastName }}</td>
        <td>{{ user.Email }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div>
    <ul class="uk-pagination">
      <li><a v-on:click="changePage(pageNumber - 1)"><span uk-pagination-previous></span></a></li>
      <li v-for="page in pageCount" :class="{'uk-active': this.pageNumber === page}"><a v-on:click="changePage(page)">{{
          page
        }}</a></li>
      <li><a v-on:click="changePage(pageNumber + 1)"><span uk-pagination-next></span></a></li>
    </ul>
  </div>
</template>