﻿<script setup>
import {useAuthStore} from '../../stores/auth.store.js';
import {authHeader} from '../../helpers/authheader';
import Loading from './Loading';
import UIkit from 'uikit';
</script>

<script>
export default {
  props: ['cckey', 'ccList'],
  data() {
    return {
      addPayment: false,
      paymentInfo: {
        userId: 0,
        Name: "",
        Address: "",
        Address2: "",
        City: "",
        State: "Select",
        Zip: "",
        Country: "US",
        Phone: "",
        County: "",
        CardNo: "",
        Type: "",
        CVV: "",
        MonthExpire: "",
        YearExpire: "",
        Email: "",
        CCkey: "",
      },
      countryList: [],
      stateList: [],
      yearList: [],
      confirmDelete: false,
      closeButton: "Cancel",
      editMessage: "",
      monthList: [],
      showMessage: false,
      showModal: true,
      isError: false,
      isLoading: false,
      requiredProps: [
        "Name", "CardNo", "CVV", "YearExpire", "MonthExpire", "Address", "City", "State", "Zip", "Country"
      ],
      baseUrl: process.env.APIURL + "/Account"
    };
  },
  mounted() {
    // log("EditPaymentComponent is mounted");
    var _this = this;
    UIkit.util.on("#EditPaymentModal", "beforeshow", function () {
      // Cleaning up the dialog before showing.
      _this.getStateList();
      _this.getCountryList();
      _this.getMonthList();
      _this.getYearList();
      _this.confirmDelete = false;
      _this.closeButton = "Cancel";
      _this.editMessage = "";
      _this.showMessage = false;
      _this.isError = false;
    });

    UIkit.util.on("#EditPaymentModal", "hidden", function () {
      window.location.reload();
    });
  },
  watch: {
    cckey(newValue) {
      // log("new cckey value", this.cckey);
      if (this.cckey === "addPayment") {
        this.setupAdd();
      } else {
        this.getEditPayment();
      }
    },
    "paymentInfo.CardNo"(newValue, oldValue) {
    // fetch credit cards and compare newValue to the last 4 of these & if there's a match show a warning saying "this looks like a duplicate credit card number, are you sure you want to add this?"
      // log("CardNo newValue", newValue);
      // log("CardNo oldValue", oldValue);
      // log("Current ccList is", this.ccList);
      //break out of method if card number contains asterisks?
        _.forEach(this.ccList, function (prop, key) {
          if (key === newValue) {
            _this.editMessage = "This looks like a duplicate credit card number, are you sure you want to add this?";
          }
      });
       let returnValue = newValue
       if(this.addPayment == false) {
       const currentValue = this.paymentInfo.CardNo;
           if (currentValue.length > 0) {
              //  log("mask the card number");
               returnValue = "**** **** **** " + newValue;
           }
       }
       //this.paymentInfo.CardNo = returnValue; //causes an infinite loop
    },
  },
  methods: {
    getStateList() {
      //log("getStateList");
      const url = this.baseUrl + "/GetStateList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.stateList = jsondata;
        this.loadingCount++;
        //log("stateList", this.stateList);
      });
    },
    getCountryList() {
      //log("getCountryList");
      const url = this.baseUrl + "/GetCountryList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.countryList = jsondata;
        this.loadingCount++;
      });
    },
    getMonthList() {
      //log("getMonthList");
      const url = this.baseUrl + "/GetMonthList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.monthList = jsondata;
        this.loadingCount++;
        //log("monthList", this.monthList);
      });
    },
    getYearList() {
      //log("getYearList");
      const url = this.baseUrl + "/GetYearList";
      fetch(url, {
        method: "GET",
        headers: authHeader(url),
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        this.yearList = jsondata;
        this.loadingCount++;
        //log("yearList", this.yearList);
      });
    },
    async getEditPayment() {
      // log("getEditPayment");
      const accountInfo = false;
      this.isLoading = true;
      const url = this.baseUrl + "/GetEditPayment";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
        cckey: this.cckey
      }
      //log("shipkey", this.shipkey);
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call),
      });
      const paymentInfo = await response.json();
      this.paymentInfo = paymentInfo;
      // log("editPayment INFO", this.paymentInfo);
      this.isLoading = false;
    },
    async managePayment() {
      // log("managePayment ", this.paymentInfo);
      let returnValue = false;
      const url = this.baseUrl + "/ManagePayment";
      const response = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(this.paymentInfo)
      });
      const paymentChange = await response.json();
      // log("managePayment result: ", paymentChange);
      return paymentChange;
    },
    async deletePayment() {
      // log("deletePayment", this.paymentInfo);
      let returnValue = false;
      const url = this.baseUrl + "/DeletePayment";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
        cckey: this.paymentInfo.CCkey
      }
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const paymentChange = await response.json();
      // log("deletePayment result: ", paymentChange);
      if (paymentChange.Status === "ok") {
        UIkit.modal("#EditPaymentModal").hide();
      }
      if (paymentChange.Status === "exception") {
        // log("delete failed", paymentChange.Message);
      }
    },
    async editPayment() {
      // log("editPayment");
      let ready = false;
      let _this = this;
      _this.editMessage = "";
      _.forEach(_this.paymentInfo, function (prop, key) {
        let isReq = _.indexOf(_this.requiredProps, key);
        if (isReq > -1) {
          if (prop === "") {
            _this.editMessage = _this.editMessage + key + " is required.<br>";
          }
        }
      });

      if (_this.editMessage.length > 0) {
        _this.showMessage = true;
        _this.isError = true;
      } else {
        ready = true;
      }
      if (ready) {
        // log("do the thing!");
        const authStore = useAuthStore();
        const user = authStore.user;
        this.paymentInfo.userId = user.Id;
        if (this.addPayment) {
          this.paymentInfo.CCkey = "0";
        }
        const result = await this.managePayment();

        if (result.Status == "ok") {
          this.editMessage = "Payment Information Updated.";
          this.showMessage = true;
          this.closeButton = "Close";
          this.isError = false;
          UIkit.modal("#EditPaymentModal").hide();
        }
        if (result.Status !== "ok") {
          // log("failed payment", result);
          this.editMessage = result.Message
          this.showMessage = true;
          this.isError = true;
        }
      }
    },
    setupAdd() {
      // log("setUpAdd");
      this.paymentInfo = {
        Name: "",
        Address: "",
        Address2: "",
        City: "",
        State: "Select",
        Zip: "",
        Country: "US",
        Phone: "",
        County: "",
        CardNo: "",
        Type: "",
        CVV: "",
        MonthExpire: "",
        YearExpire: "",
        Email: "",
        CCkey: "",
      };
      this.addPayment = true;
    },
  },
};
</script>

<template id="EditPaymentTemplate">
  <div v-if="this.showModal" class="uk-modal-dialog">
    <div class="uk-modal-body">
      <h3 class="uk-modal-title">Edit <span
          v-if="paymentInfo.CardNo.length > 0">{{ paymentInfo.Type }} ({{ paymentInfo.CardNo }})</span></h3>
      <hr/>
      <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}"
           class="uk-alert" v-html="editMessage"></div>

      <Loading v-if="isLoading" />

      <section v-if="!isLoading">
        <form v-on:submit.prevent="editPayment" method="post" class="uk-form-stacked">
          <input type="hidden" name="cckey" v-model.trim="paymentInfo.CCkey" placeholder=""
                 class="uk-input uk-form-blank"/>

          <div class="uk-margin-small">
            <label v-if="paymentInfo.Name" for="Name" class="uk-form-label">Name</label>
            <div class="uk-form-controls">
              <input type="text" name="Name" v-model.trim="paymentInfo.Name" placeholder="Full Name (on card)"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>

          <div class="uk-margin-small">
            <label v-if="paymentInfo.CardNo" for="CardNo" class="uk-form-label">Card Number
              <span v-if="!this.addPayment"><em> You must re-enter card number to make changes.</em></span>
            </label>
            <div class="uk-form-controls">
              <input type="text" name="CardNo" v-model.trim="paymentInfo.CardNo" placeholder="Card Number"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>

          <div class="uk-margin-small" uk-grid>
            <div class="uk-width-1-3">
              <label for="monthExpire" class="uk-form-label">Expire Month</label>
              <div class="uk-form-controls">
                <select v-model="paymentInfo.MonthExpire" name="monthExpire" class="uk-select">
                  <option v-for="(mo, key) in monthList" v-bind:value="mo.key">{{ mo.value }}</option>
                </select>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label for="yearExpire" class="uk-form-label">Expire Year</label>
              <div class="uk-form-controls">
                <select v-model="paymentInfo.YearExpire" name="yearExpire" class="uk-select">
                  <option v-for="(yr, key) in yearList" v-bind:value="yr.key.toString()">{{ yr.value }}</option>
                </select>
              </div>
            </div>
            <div class="uk-width-1-3">
              <label v-if="paymentInfo.CVV" for="CVV" class="uk-form-label">CVV</label>
              <div class="uk-form-controls">
                <input type="text" name="CVV" v-model.trim="paymentInfo.CVV" placeholder="CVV"
                       class="uk-input uk-form-blank"/>
              </div>
            </div>
          </div>

          <div class="uk-margin-small">
            <label v-if="paymentInfo.Address" for="address" class="uk-form-label">Address</label>
            <div class="uk-form-controls">
              <input type="text" name="address" v-model.trim="paymentInfo.Address" placeholder="Address Line 1"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="paymentInfo.Address2" for="address2" class="uk-form-label">Address Line 2</label>
            <div class="uk-form-controls">
              <input type="text" name="address2" v-model.trim="paymentInfo.Address2" placeholder="Address Line 2"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="paymentInfo.City" for="city" class="uk-form-label">City</label>
            <div class="uk-form-controls">
              <input type="text" name="city" v-model.trim="paymentInfo.City" placeholder="City"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label for="state" class="uk-form-label">State</label>
            <div class="uk-form-controls">
              <select v-model="paymentInfo.State" class="uk-select">
                <option v-for="(st, key) in stateList" v-bind:value="st.key">{{ st.value }}</option>
              </select>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="paymentInfo.Zip" for="zipCode" class="uk-form-label">Postal Code</label>
            <div class="uk-form-controls">
              <input type="text" name="zipCode" v-model.trim="paymentInfo.Zip" placeholder="Postal Code"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label for="country" class="uk-form-label">Country</label>
            <div class="uk-form-controls">
              <select v-model="paymentInfo.Country" class="uk-select">
                <option v-for="(co, key) in countryList" v-bind:value="co.key">{{ co.value }}</option>
              </select>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="paymentInfo.Phone" for="phoneNumber" class="uk-form-label">Phone Number</label>
            <div class="uk-form-controls">
              <input type="text" name="phoneNumber" v-model.trim="paymentInfo.Phone" placeholder="Phone Number"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div v-if="confirmDelete" class="uk-alert uk-alert-danger">
            <p>Are you sure you want to delete this payment method?</p>
            <input v-on:click="deletePayment" type="button" name="Delete" value="Yes, Delete!"
                   class="uk-button uk-button-danger uk-margin-right"/>
            <input type="button" name="Delete" value="No" class="uk-modal-close uk-button uk-button-secondary"/>
          </div>
          <div class="uk-modal-footer">
            <div class="uk-margin">
              <input type="submit" name="editPayment" value="Save" class="uk-button uk-button-primary uk-margin-right"/>
              <input type="button" name="Cancel" v-bind:value="closeButton"
                     class="uk-modal-close uk-button uk-button-secondary"/>

              <input v-on:click="confirmDelete = true" type="button" name="Delete" value="Delete"
                     class="uk-button uk-button-danger uk-align-right"/>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>