﻿<script>
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import InviteUser from './Components/InviteUser.vue';
import {utilsMixin} from "../helpers/utils";
import EditAccount from './Components/EditAccount.vue';
import ChangePassword from './Components/ChangePassword.vue';
import OrdersList from './Components/OrdersList.vue';

export default {
  mixins: [utilsMixin],
  data() {
    return {
      force: false,
      account: {},
      selectedType: 'ordered',
      selectedUser: 0,
      baseUrl: process.env.APIURL + "/Account",
      franchise: process.env.Franchise
    };
  },
  components: {
    "change-account-password": ChangePassword,
    "invite-user": InviteUser,
    "edit-user": EditAccount,
    "order-list-app": OrdersList,
  },
  mounted() {
    console.log("AccountApp is mounted");
    const authStore = useAuthStore();
    const user = authStore.user;
    this.selectedUser = user.Id;
    this.fetchUserDetails();
  },
  computed: {},
  methods: {
    selectType(t) {
      this.selectedType = t;
    },
    async fetchUserDetails() {
      // log("fetchUserDetails");
      const url = this.baseUrl + "/GetUserAccount";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id
      }
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let account = await response.json();
      // log("fetchUserDetails: ", account);
      if (account.Status === "ok") {
        this.account = account.Data;
      }
      if (account.Status === "exception") {
        log("Could not retrieve account:", account.Message);
      }
    }
  }
};
</script>

<template>
  <div class="uk-container uk-container-expand uk-margin-large-top">
    <div id="MyAccount" uk-offcanvas>
      <div class="uk-offcanvas-bar">
        <div class="uk-offcanvas-close" type="button" uk-close>Close&nbsp;</div>
        <div class="">
          <h3 class="uk-h3">{{ account.FirstName }} {{ account.LastName }}</h3>
          <ul class="uk-list uk-list-large uk-list-striped">
            <li v-if="account.Email"><strong>E-mail address:</strong> {{ account.Email }}</li>
            <li v-if="account.PhoneNumber"><strong>Phone number:</strong> {{ account.PhoneNumber }}</li>
            <li v-if="account.CompanyName"><strong>Company:</strong> {{ account.CompanyName }}</li>
            <li v-if="account.Address">
              <strong>Address:</strong> {{ account.Address }}
              <br v-if="account.Address2"/>
              {{ account.Address2 }}
              <br/>
              {{ account.City }}, {{ account.State }} {{ account.Zip }}
            </li>
            <li v-if="account.Website"><strong>Website:</strong> {{ account.Website }}</li>
          </ul>
          <div class="uk-margin-bottom uk-child-width-1-2@xl uk-flex" uk-grid>
            <div class="uk-width-1-1" v-if="franchise === 'Wholesale'">
              <button type="button" class="uk-button uk-button-secondary uk-width-1-1"
                      uk-toggle="target: #InviteUserModal" uk-icon="mail">
                Invite User
              </button>
            </div>
            <div>
              <button type="button" class="uk-button uk-button-primary uk-width-1-1"
                      id="ChangePasswordButton" uk-toggle="#ChangeAccountPassword" uk-icon="pencil">
                Change Password
              </button>
            </div>
            <!--              <div v-if="franchise === 'Wholesale'">-->
            <div>
              <button type="button" class="uk-button uk-button-primary uk-width-1-1"
                      uk-toggle="#EditUserModal" uk-icon="pencil">Edit Account
              </button>
            </div>
            <div>
              <router-link v-bind:to="{name: 'shippingAddresses'}" type="button"
                           class="uk-button uk-button-primary uk-width-1-1" uk-icon="file-text">Shipping Addresses
              </router-link>
            </div>
            <div v-if="franchise === 'Wholesale'">
              <router-link v-bind:to="{name: 'managePayments'}" type="button"
                           class="uk-button uk-button-primary uk-width-1-1" uk-icon="file-text">Credit Cards
              </router-link>
            </div>
          </div> <!--end button grid-->
        </div>
      </div>
    </div><!--end user side-->

    <div>
      <div class="uk-h2 uk-link" uk-toggle="#MyAccount">Orders for {{ account.FirstName }} {{ account.LastName }} <span class="uk-icon-button" uk-icon="icon: user; ratio: 1.5"></span>
      </div>
      <ul class="uk-subnav uk-subnav-pill">
        <li v-for="status in orderStatuses" :class="{'uk-active': selectedType === status.key}">
          <a v-on:click="selectType(status.key)" id="{{status.value}}OrdersTab"
             class="uk-button uk-button-primary uk-margin"
             href="#">{{ status.value }}</a>
        </li>

        <li v-if="isOfficeManager">
          <router-link v-bind:to="{name: 'manageAccounts'}" type="button"
                       class="uk-button uk-button-secondary uk-margin" uk-icon="users">Manage Accounts
          </router-link>
        </li>
      </ul>
      <div class="returnedOrders">
        <order-list-app :userAccount="selectedUser" :orderType="selectedType" :pageType="'user'"></order-list-app>
      </div>
    </div>
    <!--    </div> &lt;!&ndash;end grid&ndash;&gt;-->

  </div> <!--end container-->
  <div id="EditUserModal" uk-modal>
    <div class="uk-modal-dialog">
      <div class="uk-modal-body">
        <edit-user :userAccount="selectedUser" :isNew="false"></edit-user>
      </div>
    </div>
  </div>
  <div id="ChangeAccountPassword" uk-modal>
    <div class="uk-modal-dialog">
      <div class="uk-modal-body">
        <change-account-password :forced="force = false" :userAccount="selectedUser"
                                 is="vue:change-account-password"></change-account-password>
      </div>
    </div>
  </div>
  <div id="InviteUserModal" uk-modal>
    <invite-user></invite-user>
  </div>
</template>
