<script setup>
defineProps(['modelValue', 'Shadow'])
defineEmits(['update:modelValue'])
</script>

<style scoped lang="less">
// .search-container {
//    border-radius: 20px;
//    padding-left: 20px;
//    border: 1px solid rgba(0, 0, 0, 0.1);
// }

// .search-button{
//    padding-inline: 10px 15px;
//    line-height: 1;
//    background-color: transparent;
// }

// .search-input {
//    border-radius: 20px 0 0 20px;
//    padding-left: 20px;
//    border: none;
// }
</style>

<template>
  <div class="uk-margin">
    <div class="uk-inline">
      <!-- <span class="uk-form-icon uk-form-icon-flip uk-background-primary" uk-icon="icon: search"></span> -->
      <input :class="{'uk-input search-input uk-width-medium' : {}, 'uk-box-shadow-medium' : Shadow}" type="text" aria-label="Not clickable icon"
             :value="modelValue"
             placeholder="Search"
             @input="$emit('update:modelValue', $event.target.value)">
    </div>
  </div>
  <!--<div class="search-container uk-box-shadow-medium uk-flex uk-flex-center uk-width-medium uk-align-center">
            <input  class="search-input uk-input uk-width-medium"
                :value="modelValue"
                placeholder="Search"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <button class="search-button uk-button uk-background-secondary"><span uk-icon="icon: search"></span></button>
        </div>-->
</template>