﻿<script>
import {useAuthStore} from "../../stores/auth.store";
import {authHeader} from "../../helpers/authheader";
import Loading from "./LoadingAnimation"
import {utilsMixin} from "../../helpers/utils";
import {lv} from "../../helpers/lowenValidation";
import UIkit from 'uikit';
import ManageUsers from "./ManageUsers.vue";

export default {
  mixins: [utilsMixin, lv],
  props: ['userAccount', 'isNew', 'isDelete'],
  emits: ['closedialog'],
  components: {
    "Loading": Loading,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      website: "",
      showMessage: false,
      editMessage: "",
      isError: false,
      isLoading: false,
      adminUser: {},
      baseUrl: process.env.APIURL + "/Account",
      franchise: process.env.Franchise,
    };
  },
  mounted() {
    log("EditAccountComponent is mounted");
    let _this = this;
    const { user } = useAuthStore();
    this.adminUser = user;
    // console.log("userAccount", this.userAccount);
    _this.isLoading = this.isNotEmpty(this.userAccount);
    // console.log("mounted isNew", this.isNew);
    UIkit.util.on("#EditUserModal", "beforeshow", function () {
        _this.clearForm();
      if (!this.isNew) {
        _this.getEditUser();
      }
    });
  },
  methods: {
    async getEditUser() {
      log("getEditUser");
      // console.log("isNew", this.isNew);
      // console.log("userAccount", this.userAccount);
      this.clearForm();
      let ready = false;
      let accountInfo = false;
      this.isLoading = true;
      const url = this.baseUrl + "/GetEditAccount";
      const call = {
        "userId": this.userAccount,
        "Level": this.adminUser.Level
      };
      if (!this.isNew) {
        // console.log("fetch user to edit");
        ready = true;
      }
      if (ready) {
        let response = await fetch(url, {
          method: 'POST',
          headers: authHeader(url),
          body: JSON.stringify(call)
        });
        let accountResponse = await response.json();
        // log("accountResponse", accountResponse);
        if (accountResponse.Status === "ok") {
          let accountInfo = accountResponse.Data;
          // log("getEditUser result: ", accountInfo);
          this.isLoading = false;
          this.firstName = accountInfo.FirstName;
          this.lastName = accountInfo.LastName;
          this.email = accountInfo.Email;
          this.phoneNumber = accountInfo.PhoneNumber;
          this.website = accountInfo.Website;
        }
      }
    },
    postUser() {
      if (this.isDelete) {
        this.removeUser();
      } else if (this.isNew) {
        // console.log('Starlord');
        this.addUser();
      } else {
        this.editUser();
      }
    },
    async addUser() {
      console.log("addUser");
      this.editMessage = "";
      this.showMessage = false;
      this.isError = false;
      let ready = this.checkUser();
      if (ready) {
        // log("do the thing!");
        const url = this.baseUrl + "/AddFranchiseAccount";
        let call = {
          "userId": this.adminUser.Id,
          "FirstName": this.firstName,
          "LastName": this.lastName,
          "Email": this.email,
          "PhoneNumber": this.phoneNumber,
          "Website": this.website,
          "Level": this.adminUser.Level,
          "Franchise": this.franchise
        };
        let response = await fetch(url, {
          method: 'POST',
          headers: authHeader(url),
          body: JSON.stringify(call)
        });
        let result = await response.json();
        console.log("addUser result", result)

        if (result.Status === "ok") {
          this.editMessage = result.Message;
          this.showMessage = true;
          this.isError = false;
        }
        if (result.Status !== "ok") {
          this.editMessage = result.Message
          this.showMessage = true;
          this.isError = true;
        }
      }
    },
    async callEditUser() {
      log("callEditUser");
      let returnValue = false;
      const url = this.baseUrl + "/EditAccount";
      let call = {
        "userId": this.userAccount,
        "FirstName": this.firstName,
        "LastName": this.lastName,
        "Email": this.email,
        "PhoneNumber": this.phoneNumber,
        "Website": this.website,
        "Level": this.adminUser.Level
      };
      let response = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      return await response.json();
    },
    async editUser() {
      log("editUser");
      this.editMessage = "";
      this.showMessage = false;
      this.isError = false;
      let ready = this.checkUser();

      if (ready) {
        // log("do the thing!");
        let result = await this.callEditUser();
        if (result.Status === "ok") {
          this.editMessage = result.Message;
          this.showMessage = true;
        }
        if (result.Status !== "ok") {
          this.editMessage = result.Message
          this.showMessage = true;
          this.isError = true;
        }
      }
    },
    async removeUser() {
      log("removeUser");
      this.editMessage = "";
      this.showMessage = false;
      this.isError = false;
      let ready = this.checkUser();
      if (ready) {
        const url = this.baseUrl + "/RemoveFranchiseAccount";
        let call = {
          "userId": this.userAccount,
          "FirstName": this.firstName,
          "LastName": this.lastName,
          "Email": this.email,
          "PhoneNumber": this.phoneNumber,
          "Website": this.website,
          "Level": this.adminUser.Level,
          "Franchise": this.franchise
        };

        log("removeUser", call);
        let response = await fetch(url, {
          method: 'POST',
          headers: authHeader(url),
          body: JSON.stringify(call)
        });
        let result = await response.json();
        // console.log("removeUser result", result)

        if (result.Status === "ok") {
          this.editMessage = result.Message;
          this.showMessage = true;
        }
        if (result.Status !== "ok") {
          this.editMessage = result.Message
          this.showMessage = true;
          this.isError = true;
        }
      }
    },
    checkUser() {
      console.log("checkUser");
      let ready = false;
      if (this.firstName.length < 1) {
        this.isError = true;
        this.showMessage = true;
        this.editMessage = "First Name is required."
      }
      if (this.lastName.length < 1) {
        this.isError = true;
        this.showMessage = true;
        this.editMessage = this.editMessage + "<br>Last Name is required."
      }
      if (this.email.length < 1) {
        this.isError = true;
        this.showMessage = true;
        this.editMessage = this.editMessage + "<br>Email address is required."
      }
      if (!this.validEmail(this.email)) {
        this.isError = true;
        this.showMessage = true;
        this.editMessage = this.editMessage + "<br>Email address is not correct."
      }
      if (this.phoneNumber.length < 1) {
        this.isError = true;
        this.showMessage = true;
        this.editMessage = this.editMessage + "<br>Phone Number is required."
      }
      if (!this.isError) {
        ready = true;
      }
      return ready;
    },
    clearForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phoneNumber = "";
      this.website = "";
      this.showMessage = false;
      this.editMessage = "";
      this.isError = false;
      this.isLoading = false;
    }
  },
  computed: {
    isDeleteUser() {
      let returnValue = false;
      if (this.isNotEmpty(this.isDelete)) {
        if (this.isDelete === true) {
          returnValue = true;
        }
      }
      return returnValue;
    }
  },
  watch: {
    isNew(newVal, oldVal) {
      this.clearForm();
      if (newVal === false) {
        this.getEditUser();
      }
    },
    userAccount(newVal, oldVal) {
      if (this.isNotEmpty(newVal)) {
        this.getEditUser();
      }
    }
  }
};
</script>
<template id="EditUserTemplate">
  <div class="">
    <div class="">
      <h3 class="uk-h3">Edit User Account</h3>
      <hr/>
      <div v-if="showMessage" v-bind:class="{'uk-alert-warning': isError, 'uk-alert-success': !isError}"
           class="uk-alert" v-html="editMessage" uk-alert></div>
      <Loading v-if="isLoading"></Loading>
      <section v-if="!isLoading">
        <div class="uk-form-stacked">
          <div class="uk-margin-small">
            <label v-if="firstName" for="firstName" class="uk-form-label">First Name</label>
            <div class="uk-form-controls">
              <input type="text" name="firstName" v-model.trim="firstName" placeholder="First Name"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="lastName" for="lastName" class="uk-form-label">Last Name</label>
            <div class="uk-form-controls">
              <input type="text" name="lastName" v-model.trim="lastName" placeholder="Last Name"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <div class="uk-form-controls">
              <label v-if="email" for="email" class="uk-form-label">Email</label>
              <input type="text" name="email" v-model.trim="email" placeholder="Email" class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <label v-if="phoneNumber" for="phoneNumber" class="uk-form-label">Phone Number</label>
            <div class="uk-form-controls">
              <input type="text" name="phoneNumber" v-model.trim="phoneNumber"
                     placeholder="Phone Number" class="uk-input uk-form-blank"/>
            </div>
          </div>
          <div class="uk-margin-small">
            <div class="uk-form-controls">
              <label v-if="website" for="website" class="uk-form-label">Website</label>
              <input type="text" name="website" v-model.trim="website" placeholder="Website"
                     class="uk-input uk-form-blank"/>
            </div>
          </div>

          <div v-if="isDeleteUser" class="uk-alert uk-alert-danger uk-margin-remove-bottom">
            <h3 class="uk-text-warning uk-margin-remove-bottom">Warning:</h3>
            <p class="uk-margin-remove-top">This will remove the user. If they have any active orders, the user will
              remain active until the orders are processed.</p>
          </div>
          <div class="uk-margin">
            <button v-if="!isDeleteUser" v-on:click="postUser" class="uk-button uk-button-primary uk-margin-right">
              Save
            </button>

            <button v-if="isDeleteUser" v-on:click="postUser" class="uk-button uk-button-danger uk-margin-right">
              Delete
            </button>

            <button v-on:click="$emit('closedialog')" class="uk-button uk-button-secondary uk-modal-close">
              <span v-if="!showMessage">Cancel</span>
              <span v-if="showMessage">Close</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>