<script setup>
import {router} from '../helpers/router';
import {utilsMixin} from '../helpers/utils';
import {lv} from '../helpers/lowenValidation';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import ChangePassword from "./Components/ChangePassword.vue";
import ResetPassword from "./Components/ResetPassword.vue";
import Login from "./Components/Login.vue";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
</script>

<script type="module">
export default {
  data() {
    return {
      cartItemCount: "",
      isAuthenticated: false,
      serverError: false,
      userName: "",
      authStore: {},
      baseUrl: process.env.APIURL,
      franchise: process.env.Franchise,
    };
  },
  mixins: [utilsMixin, lv],
  components: {
    "login-app": Login,
    "reset-app": ResetPassword,
    "change-password-app": ChangePassword,
  },
  mounted() {
    log("MenuApp mounted, franchise: ", this.franchise);
    UIkit.use(Icons);
    this.cartItemCount = "";
    this.authStore = useAuthStore();
    // when the route changes, call these...
    this.$watch(() => this.$route, () => {
      this.checkLogin();
    });
    // refreshToken every so often
    setInterval(() => {
      if (this.authStore.user) {
        // log("Token refreshed");
        this.isAuthenticated = this.authStore.refreshToken();
      } else {
        // log("No user to refresh");
      }
      // log("Current user is valid?: ", this.isAuthenticated);
    }, 600000) // 10 minutes
  },
  computed: {
    fileSpecs() {
      return this.getFileSpecs();
    },
    showAccount() {
      let returnValue = false;
      if (this.isAuthenticated) {
        returnValue = true;
      }
      return returnValue
    },
    logoPath() {
      switch (this.franchise) {
        case "Decor":
          return "/public/logos/DecorLogo.png";
        case "EPD":
          return "/public/logos/EPDLogo.png";
        case "Wholesale":
          return "/public/logos/LTS_Logo.png";
        case "WebPrint":
          return "/public/logos/LowenOval_blue.png";
        case "NGS":
          return "/public/logos/ASPIREBlack.png";
      }
    },
    mobileLogoPath() {
      switch (this.franchise) {
        case "Decor":
          return "/public/logos/DecorIcon.png";
        case "EPD":
          return "/public/logos/EPDLogo.png";
        case "Wholesale":
          return "/public/logos/LTS_Logo.png";
        case "WebPrint":
          return "/public/logos/LowenOval_blue.png";
        case "NGS":
          return "/public/logos/ASPIREYellow.png";
      }
    },
    logoName() {
      switch (this.franchise) {
        case "Decor":
          return "Decor Renaissance";
        case "EPD":
          return "EPD";
        case "Wholesale":
          return "Lowen Tradesource";
        case "WebPrint":
          return "Lowen Web Print on Demand";
        case "NGS":
          return "Aspire";
      }
    },
    showCreateAccount() {
      let returnValue = false;
      if (!this.isAuthenticated) {
        if (this.isFranchiseEnabled('apply')) {
          returnValue = true;
        }
      }
      return returnValue
    },
    showLogin() {
      let returnValue = true;
      if (this.isAuthenticated) {
        returnValue = false;
      }
      return returnValue;
    },
    showManageFiles() {
      let returnValue = false;
      if (this.isAuthenticated) {
        if (this.isFranchiseEnabled('files')) {
          returnValue = true;
        }
      }
      return returnValue
    },
    showPortal() {
      let returnValue = false;
      if (this.isAuthenticated) {
        if (this.isFranchiseEnabled('portal')) {
          returnValue = true;
        }
      }
      return returnValue
    },
    showAccessories() {
      let returnValue = false;
      if (this.isAuthenticated) {
        if (this.isFranchiseEnabled('accessories')) {
          returnValue = true;
        }
      }
      return returnValue
    },
    showLibrary() {
      let returnValue = false;
      if (this.isAuthenticated) {
        if (this.isFranchiseEnabled('library')) {
          returnValue = true;
        }
      }
      return returnValue
    },
    showTileMenu() {
      let returnValue = false;
      if (this.isAuthenticated) {
        if (this.isFranchiseEnabled('tileMenu')) {
          returnValue = true;
        }
      }
      return returnValue
    },
    portalName() {
      let returnValue = "Launch Portal";
      if (this.franchise === "NGS") {
        returnValue = "Upload Your Own";
      }
      return returnValue
    },
    showTextures() {
      let returnValue = false;
      if (this.isAuthenticated) {
        if (this.isFranchiseEnabled('textures')) {
          returnValue = true;
        }
      }
      return returnValue
    },
    showPublicLibrary() {
      let returnValue = false;
      if (!this.isAuthenticated) {
        if (this.isFranchiseEnabled('publicLibrary')) {
          returnValue = true;
        }
      }
      // console.log("check public library", returnValue);
      return returnValue
    },
    showWarning() {
      let returnValue = false;
      if (this.serverError) {
        returnValue = true;
      }
      return returnValue
    },
    showSpecDownloads() {
      let returnValue = false;
      if (this.isFranchiseEnabled('specSheets')) {
        if (this.$route.path == "/" || this.$route.path == "/home") {
          returnValue = true;
        }
      }
      return returnValue;
    },
    selectedUser() {
      // console.log("selectedUser");
      let user = useAuthStore().user;
      let returnValue = 0;
      if (this.isNotEmpty(user)) {
        // console.log("selectedUser", user);
        returnValue = user.Id;
      }
      return returnValue;
    }
  },
  methods: {
    checkLogin() {
      const user = this.authStore.user;
      // log("TheMenu checkLogin", user);
      this.isAuthenticated = false;
      // If we have a userId we can getCartItemCounts
      if (user) {
        // log("Menu has user", user);
        this.userName = user.Username;
        this.isAuthenticated = true;
        this.getCartItemCount(user.Id);
      }
    },
    logout() {
      // log("TheMenu logout");
      // const page = this.$route.name;
      this.authStore.logout();
    },
    getCartItemCount(userId) {
      // log("getCartItemCount", userId);
      this.cartItemCount = "";
      const call = {
        userId: userId
      };
      const url = this.baseUrl + "/Store/GetCartTotal";
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      }).then(response => {
        // handle the security redirect if not logged in
        if (response.redirected) {
          let jsondata = {
            "Status": "ok",
            "Message": "Not logged in",
            "Data": {
              cart: {
                "ItemCount": ""
              }
            }
          }
          return jsondata;
        } else {
          return response.json();
        }
      }).then(async (jsondata) => {
        if (jsondata.Status === "ok") {
          const cart = jsondata.Data;
          this.cartItemCount = cart.ItemCount
        }
        // extra layer of security  
        if (jsondata.Status === "unauthorized") {
          log("unauthorized try a refresh");
          const refresh = await this.authStore.refreshToken();
          log("returned from token", refresh);
          if (!refresh) {
            log("refresh failed");
            clearInterval();
            this.authStore.logout();
          } else {
            log("refresh worked");
            this.getCartItemCount(userId);
          }
        }
        // No Status means bad response, go away
        if (_.isNil(jsondata.Status)) {
          log("no CARTITEM response logout");
          this.authStore.logout();
        }
      }).catch(exception => {
        log("Any uplanned exception", exception);
        this.isAuthenticated = false;
        this.serverError = true;
        // disable interval timer for token refresh
        clearInterval();
        this.authStore.error504();
      });
    }
  }
};
</script>

<template>
  <section uk-sticky="animation: uk-animation-scale-down; show-on-up: true" class="uk-padding-small">
    <div class="uk-container uk-container-expand uk-background-default">
      <button class="uk-button uk-button-primary uk-hidden@m" type="button" uk-toggle="target: #offcanvasNav">Menu
      </button>
      <nav class="main-menu uk-navbar-container uk-visible@m" uk-navbar="mode: click">
        <div class="uk-navbar-left">
          <ul class="uk-navbar-nav">
            <li>
              <router-link class="uk-logo uk-visible@m" :to="{name: 'home'}">
                <img class="uk-img" :src="logoPath" :alt="logoName">
              </router-link>
            </li>
          </ul>
        </div>
        <div class="uk-navbar-right">
          <ul class="uk-navbar-nav">
            <!-- v-if="showWarning" -->
            <li v-if="showWarning"
                class="uk-flex uk-flex-middle uk-padding uk-padding-remove-vertical uk-label-warning uk-text-bottom uk-text-center uk-text-break">
              <label>A server error may be preventing login. <br> Refresh the page and try again.</label>
            </li>
            <li v-if="showLogin">
              <a to="#" uk-toggle="target: #LoginModal"><span uk-icon="lock"></span>Login</a>
            </li>
            <li v-if="showCreateAccount">
              <router-link :to="{name: 'apply'}"><span uk-icon="pencil"></span>Create Account</router-link>
            </li>
            <li v-if="showTileMenu">
              <router-link :to="{name: 'tileMenu'}"><span uk-icon="bolt"></span>Material Guide</router-link>
            </li>
            <li v-if="showTextures">
              <router-link :to="{name: 'designer'}"><span uk-icon="icon: file-edit"></span>Pattern Designer
              </router-link>
            </li>
            <li v-if="showPortal">
              <router-link :to="{name: 'portal'}"><span uk-icon="sign-in"></span>{{ portalName }}</router-link>
            </li>
            <li v-if="showAccessories">
              <router-link :to="{name: 'misc'}"><span uk-icon="thumbnails"></span>Accessories</router-link>
            </li>
            <li v-if="showPublicLibrary">
              <router-link :to="{name: 'publicLibrary'}"><span uk-icon="icon: image"></span>Public Library
              </router-link>
            </li>
            <!-- Future Deploy - hide for tradesource release. Remove comments to show when ready to deploy -->
            <li v-if="showLibrary">
              <router-link :to="{name: 'patternLibrary'}"><span uk-icon="icon: image"></span>Pattern Library
              </router-link>
            </li>
            <li v-if="showManageFiles">
              <router-link :to="{name: 'manageFiles'}"><span uk-icon="album"></span><span>Manage Files</span>
              </router-link>
            </li>
            <li v-if="showAccount">
              <a class="uk-parent" href="#"><span uk-icon="user"></span>Account</a>
              <div uk-dropdown="mode: click" uk-toggle>
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li>
                    <router-link :to="{name: 'accountDetails'}">Account Detail</router-link>
                  </li>
                  <li v-if="isOfficeManager">
                    <router-link v-bind:to="{name: 'manageAccounts'}">Manage Accounts</router-link>
                  </li>
                  <li>
                    <a href="#" v-on:click.prevent="logout">Logout</a>
                  </li>
                  <li class="uk-nav-divider"></li>
                  <li>{{ userName }}</li>
                </ul>
              </div>
            </li>
            <li>
              <router-link data-testId="cart-link" :to="{name: 'cart'}" uk-icon="icon: cart; ratio: 1.25"><span
                  class="uk-label">{{ cartItemCount ? cartItemCount : 0 }}</span></router-link>
            </li>
          </ul>
        </div>
      </nav>
      <!-- Add spec sheet download buttons to menu on home page-->
      <div v-if="showSpecDownloads" id="SpecSheetContainer"
           class="uk-panel uk-background-default uk-margin-small-bottom uk-border-top">
        <hr class="uk-divider-icon uk-margin-remove">
        <div class="uk-text-center@m">
          <a v-for="(url, desc) in fileSpecs"
             class="uk-button uk-button-link uk-margin-large-right uk-text-primary uk-text-bold" :href="url"
             target="_blank" uk-icon="download">Download {{ desc }} Art Specs</a>
        </div>
      </div>
    </div>
  </section>
  <!--- Mobile Navigation --->
  <div id="offcanvasNav" uk-offcanvas="mode: slide">
    <div class="uk-offcanvas-bar">
      <a href="#" class="uk-offcanvas-close" uk-close></a>
      <ul class="main-menu uk-nav-primary" uk-nav>
        <li>
          <router-link class="uk-logo uk-hidden@m" :to="{name: 'home'}">
            <img class="uk-img" :src="mobileLogoPath" :alt="logoName">
          </router-link>
        </li>
        <li v-if="showLogin">
          <label v-if="showWarning" class="uk-label-warning">A server error may be preventing login.</label>
          <a to="#" uk-toggle="target: #LoginModal"><span uk-icon="lock"></span>Login</a>
        </li>
        <li v-if="showCreateAccount">
          <router-link :to="{name: 'apply'}"><span uk-icon="pencil"></span>Create Account</router-link>
        </li>
        <li v-if="showTileMenu">
          <router-link :to="{name: 'tileMenu'}"><span uk-icon="bolt"></span>Material Guide</router-link>
        </li>
        <li v-if="showTextures">
          <router-link class="mobileMarginTop" :to="{name: 'designer'}"><span uk-icon="icon: file-edit"></span>Pattern
            Designer
          </router-link>
        </li>
        <li v-if="showPortal">
          <router-link :to="{name: 'portal'}"><span uk-icon="sign-in"></span>{{ portalName }}</router-link>
        </li>
        <li v-if="showPortal">
          <router-link :to="{name: 'patternLibrary'}"><span uk-icon="icon: image"></span>Pattern Library</router-link>
        </li>
        <li v-if="showManageFiles">
          <router-link :to="{name: 'manageFiles'}">
            <span uk-icon="album"></span>
            <span>Manage Files</span>
          </router-link>
        </li>
        <li class="uk-parent" v-if="showAccount">
          <a class="" href="#"><span uk-icon="user"></span>Account<span uk-nav-parent-icon></span></a>
          <ul class="uk-nav-sub">
            <li>
              <router-link :to="{name: 'accountDetails'}">Account Detail</router-link>
            </li>
            <li v-if="isOfficeManager">
              <router-link v-bind:to="{name: 'manageAccounts'}">Manage Accounts</router-link>
            </li>
            <li>
              <a href="#" v-on:click.prevent="logout">Logout</a>
            </li>
            <li class="uk-nav-divider"></li>
            <li>{{ userName }}</li>
          </ul>
        </li>
        <li>
          <router-link :to="{name: 'cart'}" uk-icon="icon: cart; ratio: 1.25"><span class="uk-label">{{
              cartItemCount
            }}</span></router-link>
        </li>
      </ul>
    </div>
  </div>
  <div id="LoginModal" class="uk-flex-top" uk-modal="bg-close: false">
    <login-app is="vue:login-app"></login-app>
  </div>
  <div id="ResetModal" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <reset-app is="vue:reset-app"></reset-app>
    </div>
  </div>
  <div id="ChangePassModal" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog">
      <div class="uk-modal-body">
        <change-password-app v-bind:forced="force = true" v-bind:userAccount="selectedUser"
                             is="vue:change-password-app"></change-password-app>
      </div>
    </div>
  </div>
</template>