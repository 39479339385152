<script setup>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import TileMenu from './Components/TileMenu.vue';
import {utilsMixin} from '../helpers/utils';
</script>
<script type="module">
export default {
  mixins: [utilsMixin],
  data() {
    return {
      baseUrl: process.env.APIURL,
      authStore: useAuthStore(),
      franchise: process.env.Franchise
    };
  },
  mounted() {
    console.log("Tradesource is mounted");
    UIkit.use(Icons);

  },
  computed: {
    showLogin() {
      let returnValue = false;
      if (localStorage.getItem("user") == null) {
        returnValue = true;
      }
      return returnValue;
    },
  },
  methods: {}
};
</script>
<template>
  <!-- Jumbotron -->
  <section id="Jumbotron" class="uk-cover-container uk-height-viewport uk-padding-remove">
    <video src="/public/video/B-Roll_Web.mp4" loop muted uk-video="autoplay: inview"
           uk-cover></video>
    <!--    <img src="/public/images/certifiedImage.jpg" width="1800" height="1200" alt="" uk-cover>-->
    <div class="uk-position-cover uk-overlay uk-overlay-default" uk-grid>
      <div class="uk-width-1-1 uk-flex uk-flex-middle uk-padding-remove">
        <div class="uk-align-center">
          <div class="uk-align-center uk-width-2-5">
            <h2 class="uk-heading-large uk-text-center">Great Ideas Start Here</h2>
            <p class="uk-text-large uk-text-center">Lowen TradeSource provides wholesale graphics production exclusively
              to the trade
              at a price point designed to improve your margins.</p>
            <div v-if="isHolidayMessage" class="uk-text-center uk-margin-bottom">
              <div v-if="isHolidayMessage" class="uk-card uk-card-primary">
                <div class="uk-card-body uk-text-bolder" style="color: #fff;" v-html="holidayMessage"></div>
              </div>
            </div>
            <div v-if="!authStore.user" class="uk-flex">
              <button v-if="showLogin"
                      class="uk-button uk-button-secondary uk-width-1-2 uk-margin-horizontal uk-margin-right"
                      uk-toggle="target: #LoginModal"><span
                  uk-icon="icon: lock"></span>&nbsp;Log In
              </button>
              <button v-if="showLogin" class="uk-button uk-button-primary uk-width-1-2 uk-margin-horizontal"
                      @click="router.push({name: 'apply'})"><span
                  uk-icon="icon: pencil"></span>&nbsp;Create Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Jumbotron -->
  <section id="TileMenuComponent">
    <!-- added wrapper div to create some distance between sections -->
    <div class="tile-menu-component-container uk-padding-large uk-padding-remove-horizontal">
      <TileMenu></TileMenu>
    </div>
  </section>

  <!-- Features -->
  <section id="Features" class="uk-section uk-section-muted uk-padding uk-margin-large-bottom">
    <div uk-grid>
      <div class="uk-width-1-2@m">
        <div class="uk-inline uk-margin-bottom">
          <img src="/public/images/materialsBack.jpg" style="width: 545px; height: 245px"/>
          <div class="uk-overlay uk-overlay-default uk-position-cover uk-dark uk-flex uk-flex-center uk-flex-middle">
            <h2 class="uk-h1 uk-text-bolder">Materials</h2>
          </div>
        </div>
        <div class="uk-text-lead"><p>
          Lowen TradeSource provides wholesale graphics production exclusively to the trade at a price point
          designed to improve your margins. Graphics installers, wrap artists, sign shops, print brokers, design
          firms, ad agencies and others benefit from our volume purchasing power, optimized production efficiencies,
          immense capacity and simplified ordering method.</p>
          <p>We offer 3M and Avery 2 mil cast vinyl films with laminate and clear coat; both of which qualify for the
            respective 3M MCS and Avery ICS Platinum warranties.</p></div>
      </div>

      <div class="uk-width-1-2@m">
        <div class="uk-inline uk-margin-bottom">
          <img src="/public/images/benefitsBack.jpg" style="width: 545px; height: 245px;">
          <div class="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-center uk-flex-middle">
            <h2 class="uk-h1 uk-text-bolder">Benefits</h2>
          </div>
        </div>
        <div class="uk-text-lead">
          <p>Premium materials for the best price. We want you to benefit from our volume
            material discounts and take
            the next step towards higher margins.</p>
          <p>We have optimized our operation for high-volume, high-speed processing which we pass on to you with
            24-hour production for most orders.</p>
          <p>Order confirmation, shipping confirmation and tracking is included so that you can follow your order
            from start to finish.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Features -->
  <hr class="uk-divider-icon">
  <!--  Call to Action-->
  <section id="CalltoAction">
    <div class="uk-container uk-container-expand">
      <div class="uk-child-width-1-2 uk-flex uk-flex-center">
        <div class="uk-card uk-card-primary uk-card-hover">
          <div class="uk-card-body">
            <h3 class="uk-card-title uk-text-center">Let's Get Started</h3>
            <div class="uk-text-center uk-text-lead">
              <router-link :to="{name: 'apply'}" class="uk-text-bolder">Create an Account</router-link>
              or
              <a href="#" uk-toggle="target: #LoginModal">Log In</a>
              to increase your margins.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  End Call to Action-->
  <hr class="uk-divider-icon">
  <!-- Certified -->
  <section id="LowenCertified" class="uk-section">
    <div class="uk-container uk-container-large">
      <div uk-grid>
        <div class="uk-width-1-2@m">
          <h2 class="uk-h1">Lowen Certified</h2>
          <p class=" uk-text-lead uk-text-bold">
            Windows, Walls, Floors, & Fleet Installation Training/Certification
            Purchase Graphic Installation Tools Online</p>
          <p class="uk-text-lead">Lowen Certified is the largest and most experienced 3M Authorized Graphics
            Installation
            Training and Testing Center in the U.S. Installers in the Lowen Certified network are eligible for
            installation projects all over North America.
          </p>
          <div uk-grid>
            <div>
              <a class="uk-button uk-button-primary" href="https://www.lowencertified.com/Pages/default.aspx"
                 target="_blank">Learn More About Lowen Certified</a>
            </div>
            <div>
              <a class="uk-button uk-button-primary" href="https://lowencertified.com/Pages/shoptools.aspx"
                 target="_blank">Shop Our Installation Tools</a>
            </div>
          </div>
        </div>
        <div class="uk-width-1-2@m">
          <img width="660" height="400"
               src="/public/images/certifiedImage.jpg"
               alt="">
        </div>
      </div>
    </div>
  </section>
  <!-- End Certified -->

  <!-- Brands -->
  <div class="uk-container uk-container-large">
    <section class="brand-section uk-section uk-section-default uk-box-shadow-small uk-inline uk-padding">
      <h1 class="uk-text-center uk-text-large">Lowen TradeSource Proudly Recognizes Our Valued Suppliers & Industry
        Partners</h1>

      <div class="brand-grid uk-child-width-1-6@m uk-flex uk-flex-middle" uk-grid>
        <div class="brand">
          <!-- <a target="_blank" href="https://www.3m.com/">-->
          <img src="/public/Images/3MMenuLogo.png"
               alt="3M Logo">
          <!-- </a>-->
        </div>

        <div class="brand">
          <!-- <a target="_blank" href="https://graphics.averydennison.com/en/home.html">-->
          <img src="/public/Images/AveryMenuLogo.png"
               alt="Avery Dennison Logo">
          <!-- </a>-->
        </div>

        <div class="brand">
          <!-- <a target="_blank" href="https://www.clearfocus.com/">-->
          <img src="/public/Images/Clear_Focus_logo.png"
               alt="Clear Focus One Way Vision Films">
          <!-- </a>-->
        </div>

        <div class="brand">
          <a target="_blank" href="https://dreamscapewalls.com/">
            <img class="" src="/public/Images/DS_PPN_Badge.png"
                 alt="DreamScape Print Provider Networks">
          </a>
        </div>

        <div class="brand">
          <!-- <a target="_blank" href="https://www.hp.com">-->
          <img src="/public/Images/HPLogo.png"
               alt="HP Logo">
          <!-- </a>-->
        </div>

        <div class="brand">
          <!-- <a target="_blank" href="https://www.zund.com/en">-->
          <img src="/public/Images/ZundLogo.png"
               alt="Zund Swiss Cutting Systems">
          <!-- </a>-->
        </div>

      </div>
      <!--End Container-->
    </section>
  </div>
  <!-- End Section -->
</template>