<script setup>
import {useAuthStore} from "../../stores/auth.store";
import {authHeader} from "../../helpers/authheader";
</script>
<script>
export default {
  props: ['forced', 'userAccount'],
  data() {
    return {
      OldPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
      showSuccess: false,
      showError: false,
      errorMessage: "",
      baseUrl: process.env.APIURL + "/users",
      franchise: process.env.Franchise
    }
  },
  mounted() {
    log("ChangePasswordComponent mounted");
    // log("ChangePasswordComponent mounted", this.OldPassword);
    // log("ChangePasswordComponent mounted", this.NewPassword);
  },
  methods: {
    async callPasswordChange() {
      const url = this.baseUrl + "/changepassword";
      const {user} = useAuthStore();
      const call = {
        "userId": this.userAccount,
        "franchise": this.franchise,
        "OldPassword": this.OldPassword,
        "NewPassword": this.NewPassword,
        "ConfirmPassword": this.ConfirmPassword,
      };
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call),
      });
      const passwordReset = await response.json();
      return passwordReset;
    },
    async changePassword() {
      log("changePassword");
      let ready = false;
      if (!this.OldPassword === "") {
        ready = true;
      }

      if (this.NewPassword === this.ConfirmPassword) {
        ready = true;
      } else {
        this.showError = true;
        this.errorMessage = "New passwords do not match.";
      }

      if (ready === true) {
        const passChange = await this.callPasswordChange();
        log("passChange: ", passChange);
        if (passChange.Status == "ok") {
          this.showError = false;
          this.showSuccess = true;
        }
        if (passChange.Status == "invalid") {
          this.showError = true;
          this.errorMessage = "New passwords do not match.";
        }
        if (passChange.Status == "exception") {
          this.showError = true;
          this.errorMessage = passChange.Message;
        }
      }
    },
  }
};
</script>

<template id="ChangePasswordTemplate">
    <h3 class="h3">Change Password</h3>
      <p v-if="forced">You must change your password before logging in.</p>
    <hr>
    <div v-if="showError" class="uk-alert uk-alert-warning">
      {{ errorMessage }}
    </div>
    <div v-if="showSuccess" class="uk-alert uk-alert-success">
      Success! You can close this dialog. We recommend you logoff and back in with your new password.
    </div>
    <form>
      <div class="uk-margin">
        <input name="OldPassword" class="uk-form-large uk-form-blank" type="password"
               autocomplete="on" v-model="OldPassword" placeholder="Old Password">
      </div>
      <div class="uk-margin">
        <input name="NewPassword" class="uk-form-large uk-form-blank" type="password"
               autocomplete="on" v-model="NewPassword" placeholder="New Password">
      </div>
      <div class="uk-margin">
        <input name="ConfirmPassword" class="uk-form-large uk-form-blank" type="password"
               autocomplete="on" v-model="ConfirmPassword" placeholder="Confirm Password">
      </div>
      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-margin-right" v-on:click.prevent="changePassword">Change
          Password
        </button>
        <button class="uk-button uk-button-secondary uk-modal-close">Cancel</button>
      </div>
    </form>
</template>
