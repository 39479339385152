<script setup>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
</script>
<script type="module">
export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      tradesourceFileSpecificationsAdhesive: process.env.TradesourceFileSpecificationsAdhesive,
      tradesourceFileSpecificationsACM: process.env.TradesourceFileSpecificationsACM,
      tradesourceFileSpecificationsCorex: process.env.TradesourceFileSpecificationsCorex,
      videoPlaylist: "https://www.youtube.com/playlist?list=PLgRlGeO1NLm3WTjgxXWiWre0cXvMJN5q-"
    };
  },
  mounted() {
    console.log("Tradesource Help");
    console.log("Specs", this.tradesourceFileSpecificationsAdhesive);
    UIkit.use(Icons);
  },
  methods: {}
};
</script>
<template>
  <h1 class="uk-h1 uk-text-center">Help</h1>
  <section class="uk-section">
    <div class="uk-container uk-container-small">
      <div class="uk-text-center">
      <h2 class="uk-h2">Art File Specifications</h2>
      <p><a :href="this.tradesourceFileSpecificationsAdhesive" class="uk-button uk-button-default uk-button-large" target="_blank" uk-icon="download">Download Adhesive Art Specs</a></p>
      <p><a :href="this.tradesourceFileSpecificationsACM" class="uk-button uk-button-default uk-button-large" target="_blank" uk-icon="download">Download Rigid/ACM Art Specs</a></p>
      <p><a :href="this.tradesourceFileSpecificationsCorex" class="uk-button uk-button-default uk-button-large" target="_blank" uk-icon="download">Download Corex Art Specs</a></p>
      <!--      FRN Youtube refuses to connect-->
      <!--      <p><a href="/Home/videos" target="_blank">How-To Videos</a></p>-->
      <p><a :href="this.videoPlaylist" class="uk-button uk-button-secondary" target="_blank" uk-icon="youtube">Video Help Playlist</a></p>
  </div>
      <h3 class="uk-h4 uk-text-center">COLOR</h3>
      <!--      <hr class="uk-divider-icon">-->
      <p>
        All images to be provided in the CMYK color format at full production size. Files should be created with proper
        color profiles on a properly calibrated computer screen for best color results. The TradeSource profile is CMYK
        (Coated Gracol 2006).
        TradeSource is not responsible for any reasonable color shift that occurs in color space conversions.
      </p>
      <p>PMS color matching and customer proof approval are available upon request. When ordering, if customer requests
        a
        specific PMS color from the PMS Solid Coated Plus Library, every reasonable effort will be made to match that
        color. Customer may also provide a physical sample for color match. TradeSource is not liable for color matching
        or proofing files without color match specified in the order process. Reduced image proofs are available up to
        12"
        x 52".</p>

      <p>
        <strong>Customer submitted artwork must be same size as artboard. Failure to have both at same size may result
          is
          graphics being produced at different size than you intended.</strong>
      </p>
      <hr class="uk-divider-icon">
      <h3 class="uk-h4 uk-text-center">FILE TYPE / RESOLUTION</h3>
      <br>
      <p>
        TradeSource accepts AI / PDF / TIFF images as layered/live files. Other file types will be rejected.
        All artwork, designs and images must be provided at minimum of 100ppi at actual size. If
        artwork must be provided at less than actual size, ppi must be increased proportionately.
        TradeSource will reach out to customer if resolution is in question and will make every resonable effort to
        provide best quality. TradeSource is not responsbile for images printed as fuzzy,
        distorted, or pixelated due to customer provided artwork.
      </p>
      <hr class="uk-divider-icon">
      <h3 class="uk-h4 uk-text-center">TILE BREAKS FOR APPLICATION</h3>
      <p>Seamless Tile Breaks: Customer to provide tile breaks for this type of application.</p>
      <p>
        Non-Seamless (wallpaper) Tile Breaks: Customer to provide large dimension artwork as an entire
        piece without tile breaks. TradeSource will make tile breaks for all non-seamless multi-panel
        images. Standard overlap is .25“. If other overlap widths are needed, customer will need to
        split the panels.
      </p>
      <hr class="uk-divider-icon">
      <h3 class="uk-h4 uk-text-center">FONTS</h3>
      <br>
      <p>Create all fonts to outline. If not created to outline, include font file with your graphics upload. If font
        not
        provided, similar font may be substituted. Minimum stroke width for text/artwork is .125". That minimum includes
        serifs and fine art detail.</p>
      <hr class="uk-divider-icon">
      <h3 class="uk-h4 uk-text-center">CUT TO SHAPE / BLEED</h3>
      <br>
      <p>
        Customer to provide vector cad patterns as needed with the pattern clearly defined. Make
        vector cad pattern with the following parameters: .1 stroke, aligned center, spot color named
        CutContourCad in the color pallette. Include cad pattern for square cut graphics as well as
        those that are cut to shape. If customer chooses to pre-gang a variety of small graphics to fit on a sheet, keep
        width within 58.5" (including the perimeter cut) and be sure that art board of cad pattern is identical size to
        art board of corresponding art.
        Perimeter cut of graphic sheet to be made of spot color named <strong>CutContourThru</strong> in the color
        pallet.
        Make bleed at .0625" outside cut lines. Minimum stroke width for artwork is .125"; that minimum includes serifs
        and fine art detail.
      </p>
      <hr class="uk-divider-icon">
      <h3 class="uk-h4 uk-text-center">General</h3>
      <br>
      <p>Do not submit files that are encrypted / locked / password protected. Do not make graphics with Overprint Fill
        option. That will cause items to print incorrectly. Customer is fully responsible for the creation, layout, and
        proofing of all content.</p>
      <h4 class="uk-h4">Please check your artwork for correct:</h4>
      <br/>
      <div uk-grid>
        <div>
          <ul class="uk-list uk-list-hyphen">
            <li>Graphics</li>
            <li>
              Bleed
            </li>
            <li>
              Cad Pattern
            </li>
            <li>
              Crop Marks
            </li>
            <li>
              Fonts
            </li>
          </ul>
        </div>
        <div>
          <ul class="uk-list uk-list-hyphen">
            <li>
              Spelling/Punctuation/Grammar
            </li>
            <li>
              Transparency
            </li>
            <li>
              Overprint
            </li>
            <li>
              Finished Product Size
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>