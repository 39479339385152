﻿<script setup>
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import EditAddress from "./Components/EditAddress.vue";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
</script>

<script type="module">
export default {
  data() {
    return {
      addressList: {},
      shipkey: '',
      baseUrl: process.env.APIURL + "/Account"
    };
  },
  components: {
    "edit-address-app": EditAddress,
  },
  mounted() {
    log("AddressApp is mounted");
    UIkit.use(Icons);
    this.getAddresses();
  },
  computed: {},
  methods: {
    addressType(residential) {
      var returnValue = "Business";
      if (residential) {
        returnValue = "Residence";
      }
      return returnValue;
    },
    async addAddress() {
      log("AddressApp addAddress");
      this.shipkey = "addAddress";
      UIkit.modal("#EditAddressModal").show();
    },
    fullName(first, last) {
      return first + " " + last;
    },
    async getAddresses() {
      log("AddressApp getAddresses")
      const url = this.baseUrl + "/GetShippingAddresses";
      const authStore = useAuthStore();
      const user = authStore.user;
      const call = {
        userId: user.Id,
      }
      const response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      this.addressList = await response.json();
      log("Addresses status: ", this.addressList);
    },
    editAddress(shipKey) {
      log("editAddress", shipKey);
      this.shipkey = shipKey;
      UIkit.modal("#EditAddressModal").show();
    }
  }
};
</script>
<template>
  <div class="uk-container uk-container-expand">
  <section class="uk-section uk-margin-top" uk-grid>
    <div class="uk-width-1-1">
      <router-link v-bind:to="{name: 'accountDetails'}" class="uk-button uk-button-secondary"><span uk-icon="arrow-left"></span> Back to Account</router-link>
    </div>
    <div class="uk-width-4-5@m">
      <h2>Shipping Addresses</h2>
    </div>
    <div class="uk-width-1-5@m">
      <button type="button" class="uk-button uk-button-primary" v-on:click="addAddress">Add Address</button>
    </div>
  </section>
  <section class="uk-section">
    <div v-if="addressList.length > 0" class="uk-flex uk-flex-row uk-flex-middle uk-flex-wrap uk-flex-around">
      <div v-for="(ad, adKey) in addressList"
           class="uk-card uk-card-default uk-card-hover uk-margin uk-flex-none">
        <div class="uk-card-body uk-padding-large">
          <h3 class="uk-card-title" v-html="fullName(ad.FirstName, ad.LastName)"></h3>
          <hr/>
          <div>
            <dl>
              <dt v-if="ad.Email.length > 0">Email</dt>
              <dd>{{ ad.Email }}</dd>
              <dt>Address Type</dt>
              <dd> {{ addressType(ad.Residential) }}</dd>
              <dt v-if="ad.CompanyName.length > 0">Company Name</dt>
              <dd>{{ ad.CompanyName }}</dd>
              <dt>Address</dt>
              <dd>
                {{ ad.Address1 }}<br/>
                {{ ad.Address2 }}<br v-if="ad.Address2.length > 0"/>
                {{ ad.City }}, {{ ad.State }} {{ ad.Zip }}
              </dd>
              <dt v-if="ad.PhoneNumber.length > 0">Phone Number</dt>
              <dd>{{ ad.PhoneNumber }}</dd>
            </dl>
          </div>
          <div class="uk-card-footer">
            <button class="uk-button uk-button-primary" v-on:click="editAddress(ad.ShipKey)">Edit</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  <div id="EditAddressModal" uk-modal="bg-close: false">
    <edit-address-app v-bind:shipkey="shipkey"></edit-address-app>
  </div>
</template>