<script>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store';
import {authHeader} from '../helpers/authheader';
import {utilsMixin} from '../helpers/utils';
import {OrderDetails} from '../helpers/orderDetails';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Loading from "./Components/LoadingAnimation.vue"
import {lv} from "../helpers/lowenValidation";

export default {
  mixins: [utilsMixin, OrderDetails, lv],
  props: ['orderNumber', 'userId'],
  components: {
    "Loading": Loading,
  },
  data() {
    return {
      editMessage: "",
      isError: false,
      showMessage: true,
      fromCheckout: false,
      hasColorService: true,
      hasCreativeService: true,
      prefilldata: "",
      orderItemCount: 0,
      checkout: {},
      orderItems: {},
      serviceItems: {},
      lineItems: {},
      orderTotal: 0,
      isLoading: true,
      reorderItems: [],
      baseUrl: process.env.APIURL + "/Store"
    }
  },
  mounted() {
    log("OrderDetails is mounted");
    UIkit.use(Icons);
    this.getOrderDetails();
  },
  computed: {
    orderItemList: function () {
      let itemList = this.orderItems;
      _.forEach(itemList, function (item, k) {
        if (item.ItemNo === "90OC") {
          item.MaterialText = "$150 Minimum Purchase Charge";
        }
      });
      return itemList;
    },
  },
  methods: {
    addToReorder(value) {
      const current = this.reorderItems;
      const index = _.indexOf(current, value);
      if (index === -1) {
        this.reorderItems.push(value);
      } else {
        // log("pull");
        const pulled = _.pullAt(this.reorderItems, index);
      }
      // log(this.reorderItems);
    },
    addToCartReorder(reorderCart) {
      // console.log("addToCartReorder - reorderCart is", reorderCart);
      // going to need _this;
      let _this = this;
      const url = this.baseUrl + "/AddToCart";
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(reorderCart)
      }).then(responseData => {
        return responseData.json();
      }).then((jsondata) => {
        // log("AddToCartReorder jsonData: ", jsondata);
        let response = jsondata;
        if (response.Status === "ok") {
          router.push({ name: "cart" });
        } else {
          _this.editMessage = "There was a server error adding items to your cart.";
          _this.isError = true;
          _this.showMessage = true;
        }
      });
    },
    getOrderDetails() {
      console.log("getting order details");
      const url = this.baseUrl + "/GetOrderDetails";
      const { user } = useAuthStore();
      let _this = this;
      let call = {
        userId: this.userId,
        orderNumber: this.orderNumber,
        level: user.Level
      };
      fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        let returnedData = jsondata;
        // log("getOrderDetails jsondata,", jsondata);
        if (returnedData.Status === "ok") {
          _this.isLoading = false;
          _this.manageOrderItems(returnedData.Data);
        } else {
          console.log("Could not get order details", returnedData);
          _this.isLoading = false;
          _this.editMessage = returnedData.Message;
        }
      });
    },

    reOrderItems() {
      log("reorderItems");
      const order = this.lineItems;
      const reorderList = this.reorderItems;
      let reorderCart = [];
      _.forEach(reorderList, function (itemKey) {
        const item = order[itemKey];
        // remove base64 image
        item.base64String = "";
        item.CartID = 0;
        // This should be the FullURL.
        // If you do not have it. Do not use the ImageUrl, that is the thumbnail.
        // item.FullUrl = item.ImageUrl;
        // item.Material = item.ItemNo;
        // item.Name = do i need SetName have SetId
        // Colors is blank for somereason.
        // Do I need Pixel Sizes?
        // Do I need Tags
        // Do I need ColorMatching

        reorderCart.push(item);
        // log("item", item);
      });
      this.addToCartReorder(reorderCart);
    },
    manageOrderItems(orderItems) {
      // log("manageOrderItems", orderItems.LineItems);
      this.orderItems = orderItems
      this.checkout = orderItems.Checkout;
      this.prefilldata = orderItems.Prefill;
      this.lineItems = orderItems.LineItems;
      if (!this.isFranchiseEnabled('portalPricing')) {
        this.lineItems = this.lineItems.filter(item => item.ItemNo !== "90OC")
      }
      // log("lineItems", this.lineItems);
      // log("prefilldata", this.prefilldata);
      // log("checkout", this.checkout);
    },
  }
}
</script>

<template id="OrderDetailsTemplate">
  <div class="uk-container uk-margin-top">
    <div class="" uk-grid>
      <div>
        <router-link class="uk-button uk-button-primary" :to="{name: 'accountDetails'}"><span
            uk-icon="icon: chevron-double-left"></span>Account
        </router-link>
      </div>
      <div>
        <h2>Order {{ orderNumber }}</h2>
      </div>
    </div>
    <div uk-grid>
      <section v-if="fromCheckout" class="uk-width-2-3@m">
        <div class="uk-card uk-card-default">
          <div class="uk-card-body">
            <div class="uk-card-title">
              <h3> Order submitted successfully.</h3>
            </div>
          </div>
        </div>
        <!--      
        FRN How to do this in vue?
        <script>
                gtag('event', 'conversion', {
                  'send_to': 'AW-473785335/0af1CLrapYoDEPfH9eEB',
                  'value': @Html.Raw(Model.Total),
                  'currency': 'USD',
                  'transaction_id': '@Html.Raw(Model.OrderNumber)'
                });
              </script>-->

        <div v-if="hasCreativeService" class="uk-card uk-card-primary">
          <div class="uk-card-body">
            <div class="uk-card-title">
              Creative Services
            </div>
            To complete your creative services art request, please click here <a
              href="https://signoffrequest.lowen.com/graphics?prefilldata=prefilldata"
              target="_blank" class="uk-button uk-button-default">→</a>
          </div>
        </div>

        <div v-if="hasColorService" class="uk-card uk-card-secondary">
          <div class="uk-card-body">
            <div class="uk-card-title">Color Matching Samples</div>
            <p class="uk-text-large">Send color samples to:</p>
            Lowen TradeSource<br>
            1111 Airport Road<br>
            Hutchinson, KS 67501<br>
            ATTN: Prepress Dept., Deanne Martin<br>
          </div>
        </div>
      </section>
      <!--    <div uk-grid>-->
      <!--      <div class="uk-flex uk-flex-wrap">-->
      <div v-if="!fromCheckout" class="uk-width-1-1 uk-width-2-3@m">
        <Loading v-if="isLoading"/>
        <div v-if="!isLoading && editMessage" class="uk-alert uk-alert-danger" uk-alert>
          {{ editMessage }};
        </div>
        <table v-if="!isLoading && !editMessage"
               class="uk-table uk-table-middle uk-table-divider uk-table-hover">
          <thead>
          <tr>
            <th>
              <span class="uk-h3">Image</span>
            </th>
            <th>
              <span class="uk-h3">Product</span>
            </th>
            <th>
              <span class="uk-h3">Quantity</span>
            </th>
            <th>
              <span v-if="showPrice" class="uk-h3">Price</span>
            </th>
            <th>
              <button v-on:click="reOrderItems"
                      class="uk-button uk-button-primary uk-text-default uk-margin-right uk-text-nowrap">Reorder
                Selected
              </button>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(c, k) in lineItems" class="">
            <td class="uk-cover-container uk-width-stretch">

              <div v-if="hasUrlType(c) == 'base64'" v-bind:data-src="c.base64String"
                   :class="{'uk-background-secondary': c.ImageUrl.includes('PatnLibrary')}"
                   class="uk-height-small uk-background-cover" uk-img></div>
              <div v-if="hasUrlType(c) == 'public'" v-bind:data-src="c.ImageUrl"
                   class="uk-height-small uk-background-contain" uk-img></div>
              <div v-if="c.ItemNo !== '90OC'"
                   class="uk-position-bottom uk-overlay uk-overlay-default uk-text-small uk-padding-remove-horizontal uk-padding-small uk-text-center">
                {{ c.Filename }}
              </div>
            </td>
            <td>
              <p v-if="c.ItemDesc">{{ c.ItemDesc }}</p>
              <p v-if="c.ItemNo === '90OC'">$150 Minimum Purchase Charge</p>
              <p v-if="c.LaminateType" class="uk-margin-remove-bottom">
                <strong>Finish: </strong>{{ c.LaminateType }}</p>
              <p v-if="ShowFilename(c)" class="uk-margin-remove-bottom"><strong>For
                Filename: </strong>{{ c.Filename }}
              </p>
              <ul v-if="c.Colors.length > 0" class="uk-margin-remove-top uk-list uk-list-collapse">
                <li v-for="color in c.Colors">{{ color }}</li>
              </ul>
              <ul class="uk-list uk-list-collapse">
                <li v-if="ShowSize(c)">{{ c.InchWidth }}"w by {{ c.InchHeight }}"h {{
                    ShowSquareFt(c)
                  }}
                </li>
                <li v-if="ShowRollSize(c)">Length: {{ c.rollSize }}'</li>
                <li v-if="ShowFinish(c)"><strong>Finish: </strong>{{ FinishText(c) }}</li>
                <li v-if="ShowContourCut(c)">{{ ContourCut(c) }}</li>
                <li v-if="ShowOverlap(c)"><strong>Overlap: </strong>{{ c.Overlap }} in</li>
                <li v-if="ShowRigid(c)">
                  <ul class="uk-list uk-list-collapse">
                    <li><strong>Print Sides: </strong>{{ c.sides }}</li>
                    <li v-if="c.round_corners > 0"><strong>Round
                      Corners: </strong>{{ c.round_corners }}"
                    </li>
                    <li v-if="c.hole_placement"><strong>Drill Hole
                      Placement: </strong>{{ c.hole_placement }}
                    </li>
                    <li v-if="c.hole_edge_distance"><strong>Drill Hole Edge
                      Distance: </strong>{{ c.hole_edge_distance }}
                    </li>
                    <li v-if="c.hole_hole_distance"><strong>Drill Hole to Hole
                      Distance: </strong>{{ c.hole_hole_distance }}
                    </li>
                    <li>
                      <span v-if="c.grommets">Item Has Grommets</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
            <td class="uk-text-center">
              {{ c.Quantity }}
            </td>
            <td>
              <strong v-if="showPrice">{{ $toCurrency(c.Price) }}</strong>
            </td>
            <td v-if="c.ItemNo !== '90OC'" class="uk-width-small">
              <label v-bind:for="k">
                <input type="checkbox" class="uk-checkbox" @click="addToReorder(k)" v-bind:id="k"
                       v-bind:name="k"
                       v-bind:value="k"/>&nbsp;Reorder
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!isLoading" class="uk-text-right uk-width-1-1 uk-width-1-3@m">
        <div v-if="showPrice" class="uk-margin-small">
          <div class="uk-card uk-card-secondary">
            <div class="uk-card-body">
              <h3 class="uk-h3 uk-text-left">Subtotal: <span class="uk-align-right">{{
                  $toCurrency(orderItems.Subtotal)
                }}</span></h3>
              <h3 v-if="orderItems.FreightAmount > 0" class="uk-h3 uk-text-left">+ Shipping:
                <span class="uk-align-right">{{ $toCurrency(orderItems.FreightAmount) }}</span></h3>
              <h3 v-if="orderItems.TaxAmount > 0" class="uk-h3 uk-text-left">+ Tax:
                <span class="uk-align-right">{{ $toCurrency(orderItems.TaxAmount) }}</span></h3>
              <hr class="uk-margin-remove">
              <h3 class="uk-h3 uk-text-left uk-margin-medium-top">Total: <span
                  class="uk-align-right uk-text-success uk-margin-remove">{{ $toCurrency(orderItems.Total) }}</span>
              </h3>
            </div>
          </div>
        </div>

        <div v-if="showPrice" class="uk-margin-small">
          <div class="uk-card uk-card-secondary">
            <div class="uk-card-body">
              <div class="uk-card-title uk-text-left">
                Bill To
              </div>
              <ul class="uk-list">
                <li class="uk-text-left">{{ checkout.BillingFirstName }} {{
                    checkout.BillingLastName
                  }}
                </li>
                <li class="uk-text-left">{{ checkout.BillingAddress }}</li>
                <li class="uk-text-left">{{ checkout.BillingCity }} {{ checkout.BillingState }},
                  {{ checkout.BillingZip }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="uk-margin-small">
          <div class="uk-card uk-card-secondary">
            <div class="uk-card-body">
              <div class="uk-card-title uk-text-left">
                Ship To
              </div>
              <ul class="uk-list">
                <li class="uk-text-left">{{ checkout.ShippingFirstName }} {{
                    checkout.ShippingLastName
                  }}
                </li>
                <li class="uk-text-left">{{ checkout.ShippingAddress }}</li>
                <li class="uk-text-left">{{ checkout.ShippingCity }} {{ checkout.ShippingState }},
                  {{ checkout.ShippingZip }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>