<script setup>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import TileMenu from "./Components/TileMenu.vue";
</script>
<script type="module">
export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      authStore: useAuthStore(),
      franchise: process.env.Franchise
    };
  },
  mounted() {
    console.log("Aspire is mounted");
    UIkit.use(Icons);
  },
  computed: {
    showLogin() {
      let returnValue = false;
      if (localStorage.getItem("user") == null) {
        returnValue = true;
      }
      return returnValue;
    },
    isHolidayMessage() {
      let returnValue = false;
      let flag = "";
      switch (this.franchise) {
        case 'Wholesale':
          flag = process.env.WholesaleMessageFlag;
          break;
        case 'NGS':
          flag = process.env.NGSMessageFlag;
          break;
      }
      returnValue = flag === 'true';
      return returnValue;
    },
    holidayMessage() {
      let returnValue = "";
      switch (this.franchise) {
        case 'Wholesale':
          returnValue = process.env.WholesaleMessage;
          break;
        case 'NGS':
          returnValue = process.env.NGSMessage;
          break;
      }
      return returnValue;
    },
  },
  methods: {}
};
</script>
<template>
  <!-- Jumbotron -->
  <section id="Jumbotron" class="uk-cover-container uk-padding-remove" uk-height-viewport>
    <img src="/public/images/woodgrainbg.png" alt="Decor Pattern" uk-cover>
    <div class="uk-position-cover uk-overlay uk-overlay-default" uk-grid>
      <img class="dr-creation" src="/public/images/creationofman.png" alt="Pattern Creation">
      <div class="uk-width-1-1 uk-flex uk-flex-middle uk-padding-remove">
        <div class="uk-align-center uk-width-2-5">
          <h2 class="uk-heading-small uk-text-center uk-text-emphasis">Let there be patterns</h2>
          <p class="uk-text-lead uk-text-emphasis uk-text-center">D&eacute;cor Renaissance creates the wood grain look
            that was once only found in Mother Nature.</p>
          <div v-if="isHolidayMessage" class="uk-margin-bottom">
            <div v-if="isHolidayMessage" class="uk-card uk-card-primary">
              <div class="uk-card-body uk-text-bolder" style="color: #fff;" v-html="holidayMessage"></div>
            </div>
          </div>
          <div v-if="!authStore.user" class="uk-text-center">
            <button v-if="showLogin"
                    class="uk-button uk-button-primary dr-button uk-width-1-2 uk-margin-horizontal uk-margin-right"
                    uk-toggle="target: #LoginModal"><span
                uk-icon="icon: lock"></span>&nbsp;Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Jumbotron -->
<!--  <section id="TileMenuComponent">
    &lt;!&ndash; added wrapper div to create some distance between sections &ndash;&gt;
    <div class="tile-menu-component-container uk-padding-large uk-padding-remove-horizontal">
      <TileMenu></TileMenu>
    </div>
  </section>-->

  <!-- Features -->
  <!--  <section id="Features" class="uk-section uk-section-muted uk-padding uk-margin-large-bottom">
      <div uk-grid>
        <div class="uk-width-1-2@m">
          <div class="uk-inline uk-margin-bottom">
            <img src="/public/images/materialsBack.jpg" style="width: 545px; height: 245px"/>
            <div class="uk-overlay uk-overlay-default uk-position-cover uk-dark uk-flex uk-flex-center uk-flex-middle">
              <h2 class="uk-h1 uk-text-bolder">Materials</h2>
            </div>
          </div>
          <div class="uk-text-lead"><p>
            Lowen TradeSource provides wholesale graphics production exclusively to the trade at a price point
            designed to improve your margins. Graphics installers, wrap artists, sign shops, print brokers, design
            firms, ad agencies and others benefit from our volume purchasing power, optimized production efficiencies,
            immense capacity and simplified ordering method.</p>
            <p>We offer 3M and Avery 2 mil cast vinyl films with laminate and clear coat; both of which qualify for the
              respective 3M MCS and Avery ICS Platinum warranties.</p></div>
        </div>
  
        <div class="uk-width-1-2@m">
          <div class="uk-inline uk-margin-bottom">
            <img src="/public/images/benefitsBack.jpg" style="width: 545px; height: 245px;">
            <div class="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-center uk-flex-middle">
              <h2 class="uk-h1 uk-text-bolder">Benefits</h2>
            </div>
          </div>
          <div class="uk-text-lead">
            <p>Premium materials for the best price. We want you to benefit from our volume
              material discounts and take
              the next step towards higher margins.</p>
            <p>We have optimized our operation for high-volume, high-speed processing which we pass on to you with
              24-hour production for most orders.</p>
            <p>Order confirmation, shipping confirmation and tracking is included so that you can follow your order
              from start to finish.</p>
          </div>
        </div>
      </div>
    </section>-->
  <!-- End Features -->
  <!--  <hr class="uk-divider-icon">-->
  <!-- Certified -->
  <!--  <section id="LowenCertified" class="uk-section">
      <div class="uk-container uk-container-expand">
        <div uk-grid>
          <div class="uk-width-1-2@m">
            <h2 class="uk-h1">Lowen Certified</h2>
            <p class=" uk-text-lead uk-text-bold">
              Windows, Walls, Floors, & Fleet Installation Training/Certification
              Purchase Graphic Installation Tools Online</p>
            <p class="uk-text-lead">Lowen Certified is the largest and most experienced 3M Authorized Graphics
              Installation
              Training and Testing Center in the U.S. Installers in the Lowen Certified network are eligible for
              installation projects all over North America.
            </p>
            <div uk-grid>
              <div>
                <a class="uk-button uk-button-primary" href="https://www.lowencertified.com/Pages/default.aspx"
                   target="_blank">Learn More About Lowen Certified</a>
              </div>
              <div>
                <a class="uk-button uk-button-primary" href="https://lowencertified.com/Pages/shoptools.aspx"
                   target="_blank">Shop Our Installation Tools</a>
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@m">
            <img width="660" height="400"
                 src="/public/images/certifiedImage.jpg"
                 alt="">
          </div>
        </div>
      </div>
    </section>-->
  <!-- End Certified -->

  <!-- Brands -->
  <!--  <section class="uk-section uk-section-primary">
      <div class="uk-container uk-container-expand">
        <h1 class="uk-text-center uk-text-large">Our Valued Suppliers & Industry
          Partners</h1>
  
        <div class="uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-6@l" uk-grid>
          <div>
            <figure class="uk-inline">
              <img src="/public/Images/3MLogo.svg"
                   alt="3M Logo" uk-svg>
            </figure>
          </div>
  
          <div>
            <div class="uk-inline-block">
              <img src="/public/Images/AveryLogo.svg"
                   alt="Avery Dennison Logo" uk-svg>
            </div>
          </div>
  
          <div>
            <figure class="uk-inline">
              <img src="/public/Images/ClearFocus_WhiteLogo.png"
                   alt="Clear Focus One Way Vision Films">
            </figure>
          </div>
  
          <div>
            <figure class="uk-inline">
              <img src="/public/Images/DS_PPN_Badge.png"
                 alt="DreamScape Print Provider Networks">
            </figure>
          </div>
  
          <div>
            <figure class="uk-inline">
              <img src="/public/Images/HPLogo.svg"
                   alt="HP Logo" uk-svg>
            </figure>
          </div>
  
          <div>
            <figure class="uk-inline">
              <img src="/public/Images/ZundLogo.svg"
                   alt="Zund Swiss Cutting Systems" uk-svg>
            </figure>
          </div>
  
        </div>
      </div>
    </section>-->
  <!-- End Container -->
</template>
