<script setup>
import {router} from '../helpers/router';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {utilsMixin} from '../helpers/utils';
</script>
<script type="module">
export default {
  mixins: [utilsMixin],
  data() {
    return {
      baseUrl: process.env.APIURL,
      authStore: useAuthStore(),
      franchise: process.env.Franchise
    };
  },
  mounted() {
    console.log("Aspire is mounted");
    UIkit.use(Icons);
  },
  computed: {
    showLogin() {
      let returnValue = false;
      if (localStorage.getItem("user") == null) {
        returnValue = true;
      }
      return returnValue;
    },
  },
  methods: {}
};
</script>
<template>
  <!-- Jumbotron -->
  <section id="Jumbotron" class="uk-section uk-section-default uk-cover-container uk-height-viewport">
    <!--    <video src="/public/video/B-Roll_Web.mp4" width="1800" height="1200" loop muted uk-video="autoplay: inview" uk-cover></video>-->
    <img src="/public/images/balloonBackground.jpg" width="1800" height="1200" alt="" uk-cover>
    <div class="uk-position-cover uk-overlay uk-overlay-default" uk-grid>
      <div class="uk-width-4-5 uk-width-3-5@l uk-width-2-5@xl uk-flex uk-flex-middle uk-align-center">
        <div class="uk-align-center">
          <div class="uk-align-center uk-padding-small uk-text-center uk-text-left@s uk-width-3-5@s">
            <!--            <h2 class="uk-heading-large">Great Ideas Start Here</h2>-->
            <p class="uk-text-large">The ASPIRE brand is built around the central idea of elevation and inspiration of
              ideas and designs in the architectural and window graphic spaces.</p>

            <div v-if="isHolidayMessage" class="uk-text-center uk-margin-bottom">
              <div v-if="isHolidayMessage" class="uk-card uk-card-primary">
                <div class="uk-card-body uk-text-bolder" style="color: #fff;" v-html="holidayMessage"></div>
              </div>
            </div>
            <!--            if a user is not logged in -->
            <div class="uk-align-center uk-flex uk-flex-center uk-child-width-1-1@m uk-child-width-1-2@l" uk-grid>
              <div class="uk-padding-small">
                <button v-if="showLogin" class="uk-button uk-width-stretch uk-button-primary project-button"
                        uk-toggle="target: #LoginModal"><span
                    uk-icon="icon: lock"></span>&nbsp;Log In
                </button>
              </div>
              <!--            if a user is not logged in -->
              <!--              <div class="uk-padding-small uk-margin-remove">
                              <button v-if="showLogin"  class="uk-button uk-width-stretch uk-button-primary project-button" @click="router.push({name: 'apply'})"><span
                                uk-icon="icon: pencil"></span>&nbsp;Create Account
                              </button>
                            </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Features -->
  <hr class="uk-divider-icon">

  <!-- End Container -->
</template>