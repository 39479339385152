<script>
import {utilsMixin} from '../../helpers/utils';
import {OrderDetails} from '../../helpers/orderDetails';
import {useAuthStore} from '../../stores/auth.store';
import {authHeader} from '../../helpers/authheader';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {useAuthStore} from "../../stores/auth.store";

export default {
  mixins: [utilsMixin, OrderDetails],
  props: {
    orderNumber: String,
    userID: Number
  },
  data() {
    return {
      orderItemCount: 0,
      checkout: {},
      orderItems: {},
      serviceItems: {},
      lineItems: {},
      orderTotal: 0,
      isLoading: true,
      baseUrl: process.env.APIURL + "/Store"
    }
  },
  async mounted() {
    let getDetails = this.getOrderDetails;
    let _this = this;
    // let on = this.orderNumber;
    // console.log("ONBS", on);
    await this.$nextTick(() => getDetails());

    UIkit.util.on('#MyOrderDetails', 'beforeshow', async function () {
      // console.log("ONBS", this.userID);
      await _this.$nextTick(() => getDetails());
    });
  },
  methods: {
    async getOrderDetails() {
      // console.log("getOrderDetails", this.orderNumber);
      // console.log("getOrderDetails", this.userID);
      this.isLoading = true;
      this.orderItems = {};
      this.checkout = {};
      this.lineItems = {};
      const url = this.baseUrl + "/GetOrderDetails";
      const { user } = useAuthStore();
      let call = {
        userId: this.userID,
        orderNumber: this.orderNumber,
        level: user.Level
      };
      fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      }).then(response => {
        return response.json();
      }).then((jsondata) => {
        let returnedData = jsondata;
        // console.log("getOrderDetails jsondata,", jsondata);
        this.isLoading = false;
        this.manageOrderItems(returnedData.Data);
      });
    },
    manageOrderItems(orderItems) {
      // console.log("manageOrderItems", orderItems.LineItems);
      this.orderItems = orderItems
      this.checkout = orderItems.Checkout;
      this.lineItems = orderItems.LineItems;
      if (!this.isFranchiseEnabled('portalPricing')) {
        this.lineItems = this.lineItems.filter(item => item.ItemNo !== "90OC")
      }
    },
  }
}
</script>

<template id="OrderPreviewTemplate" class="uk-animation-toggle">
  <div :class="{'uk-hidden': isLoading}" class="uk-padding uk-animation-fade">
    <div class="uk-text-center">
      <h3>Order {{ orderNumber }}</h3>
    </div>
    <div class="uk-grid-small uk-margin-bottom"
         :class="{'uk-child-width-1-3': !showPrice, 'uk-child-width-1-4': showPrice}" uk-grid>
      <div class="uk-h4">Image</div>
      <div class="uk-h4">Product</div>
      <div class="uk-h4">Quantity</div>
      <div v-if="showPrice" class="uk-h3">Price</div>
    </div>
    <div class="uk-margin-bottom">
      <div v-for="(c, k) in lineItems" :class="{'uk-child-width-1-3': !showPrice, 'uk-child-width-1-4': showPrice}"
           uk-grid>
        <div class="uk-cover-container uk-height-small">
          <div v-if="hasUrlType(c) == 'base64'" v-bind:data-src="c.base64String"
               :class="{'uk-background-secondary': c.ImageUrl.includes('PatnLibrary')}"
               class="uk-height-small uk-background-cover" uk-img></div>
          <div v-if="hasUrlType(c) == 'public'" v-bind:data-src="c.ImageUrl"
               class="uk-height-small uk-background-contain" uk-img></div>
          <div v-if="c.ItemNo !== '90OC'"
               class="uk-position-bottom uk-overlay uk-overlay-default uk-text-small uk-padding-remove-horizontal uk-padding-small uk-text-center">
            {{ c.Filename }}
          </div>
        </div>

        <div>
          <p v-if="c.ItemDesc">{{ c.ItemDesc }}</p>
          <p v-if="c.ItemNo === '90OC'">$150 Minimum Purchase Charge</p>
          <p v-if="c.LaminateType" class="uk-margin-remove-bottom">
            <strong>Finish: </strong>{{ c.LaminateType }}</p>
          <p v-if="ShowFilename(c)" class="uk-margin-remove-bottom"><strong>For
            Filename: </strong>{{ c.Filename }}
          </p>
          <ul v-if="c.Colors.length > 0" class="uk-margin-remove-top uk-list uk-list-collapse">
            <li v-for="color in c.Colors">{{ color }}</li>
          </ul>
          <ul class="uk-list uk-list-collapse">
            <li v-if="ShowSize(c)">{{ c.InchWidth }}"w by {{ c.InchHeight }}"h {{
                ShowSquareFt(c)
              }}
            </li>
            <li v-if="ShowRollSize(c)">Length: {{ c.rollSize }}'</li>
            <li v-if="ShowFinish(c)"><strong>Finish: </strong>{{ FinishText(c) }}</li>
            <li v-if="ShowContourCut(c)">{{ ContourCut(c) }}</li>
            <li v-if="ShowOverlap(c)"><strong>Overlap: </strong>{{ c.Overlap }} in</li>
            <li v-if="ShowRigid(c)">
              <ul class="uk-list uk-list-collapse">
                <li><strong>Print Sides: </strong>{{ c.sides }}</li>
                <li v-if="c.round_corners > 0"><strong>Round
                  Corners: </strong>{{ c.round_corners }}"
                </li>
                <li v-if="c.hole_placement"><strong>Drill Hole
                  Placement: </strong>{{ c.hole_placement }}
                </li>
                <li v-if="c.hole_edge_distance"><strong>Drill Hole Edge
                  Distance: </strong>{{ c.hole_edge_distance }}
                </li>
                <li v-if="c.hole_hole_distance"><strong>Drill Hole to Hole
                  Distance: </strong>{{ c.hole_hole_distance }}
                </li>
                <li>
                  <span v-if="c.grommets">Item Has Grommets</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="uk-text-left">
          {{ c.Quantity }}
        </div>

        <div v-if="showPrice">
          <strong>{{ $toCurrency(c.Price) }}</strong>
        </div>
      </div>
    </div>


    <div class="uk-grid-small uk-grid-match"
         :class="{'uk-child-width-1-2': !showPrice, 'uk-child-width-1-3': showPrice}" uk-grid>
      <div v-if="showPrice">
        <div class="uk-card uk-card-secondary">
          <div class="uk-card-body">
            <h3 class="uk-h4 uk-text-left">Subtotal: <span class="uk-align-right">{{
                $toCurrency(orderItems.Subtotal)
              }}</span></h3>
            <h3 v-if="orderItems.FreightAmount > 0" class="uk-h4 uk-text-left">+ Shipping:
              <span class="uk-align-right">{{ $toCurrency(orderItems.FreightAmount) }}</span></h3>
            <h3 v-if="orderItems.TaxAmount > 0" class="uk-h4 uk-text-left">+ Tax:
              <span class="uk-align-right">{{ $toCurrency(orderItems.TaxAmount) }}</span></h3>
            <hr class="uk-margin-small">
            <h3 class="uk-h4 uk-text-left uk-margin-medium-top">Total: <span
                class="uk-align-right uk-text-success uk-margin-remove">{{ $toCurrency(orderItems.Total) }}</span>
            </h3>
          </div>
        </div>
      </div>

      <div v-if="showPrice">
        <div class="uk-card uk-card-secondary">
          <div class="uk-card-body">
            <div class="uk-card-title uk-text-left">
              Bill To
            </div>
            <ul class="uk-list">
              <li class="uk-text-left">{{ checkout.BillingFirstName }} {{
                  checkout.BillingLastName
                }}
              </li>
              <li class="uk-text-left">{{ checkout.BillingAddress }}</li>
              <li class="uk-text-left">{{ checkout.BillingCity }} {{ checkout.BillingState }},
                {{ checkout.BillingZip }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="uk-card uk-card-secondary">
          <div class="uk-card-body">
            <div class="uk-card-title uk-text-left">
              Ship To
            </div>
            <ul class="uk-list">
              <li class="uk-text-left">{{ checkout.ShippingFirstName }} {{
                  checkout.ShippingLastName
                }}
              </li>
              <li class="uk-text-left">{{ checkout.ShippingAddress }}</li>
              <li class="uk-text-left">{{ checkout.ShippingCity }} {{ checkout.ShippingState }},
                {{ checkout.ShippingZip }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>